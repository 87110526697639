import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Breadcrumb,
  Form,
  Button,
  Table,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import "./LedgerReport.css";
import { FaFileExcel, FaFilePdf, FaPrint, FaRupeeSign } from "react-icons/fa";
import * as XLSX from "xlsx";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import axios from "axios";
import { useSelector } from "react-redux";

const LedgerReport = () => {
  const [item, setItem] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const { walletData } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchFlightBookingData = async () => {
      try {
        const request = { AgentId: walletData.Id };
        const response = await axios.post(
          "https://admin.yatriservice.com/api/transictionLog",
          request
        );
        if (response.data.success) {
          setItem(response.data.data);
          setFilteredItems(response.data.data); // Initialize filteredItems with the fetched data
        } else {
          console.error(
            "Failed to fetch flight booking data:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching flight booking data:", error);
      }
    };

    fetchFlightBookingData();
  }, [walletData.Id]);

  const formatDateTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();
    const formattedTime = `${hours}:${String(minutes).padStart(
      2,
      "0"
    )} ${ampm}`;
    const formattedDate = `${month}-${day}-${year}`;
    return `${formattedTime} ${formattedDate}`;
  };

  const handleSearchByDateRange = (event) => {
    event.preventDefault();
    if (fromDate && toDate) {
      const filteredData = item.filter((ledger) => {
        const ledgerDate = new Date(ledger.created_at);
        return ledgerDate >= fromDate && ledgerDate <= toDate;
      });
      setFilteredItems(filteredData);
    } else {
      setFilteredItems(item);
    }
  };

  return (
    <div className="content-wrapper new_markup">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Transaction Log</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/agent/dashboard" }} >
                   <span style={{color:'#fff'}}> Home</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Transaction Log</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Body>
                    <div className="ledger_report common_report">
                      <div className="ledger_inner">
                     
                        <div className="cus_report_field">
                          <Form onSubmit={handleSearchByDateRange}>
                            <Row>
                              <Col sm={4}>
                                <Form.Group className="form-group">
                                  <Form.Label>Submission Start Date</Form.Label>
                                  <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => setFromDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control commondate"
                                    placeholderText="mm/dd/yyyy"
                                    maxDate={toDate || new Date()}
                                  />
                                </Form.Group>
                              </Col>
                              <Col sm={4}>
                                <Form.Group className="form-group">
                                  <Form.Label>Submission End Date</Form.Label>
                                  <DatePicker
                                    selected={toDate}
                                    onChange={(date) => setToDate(date)}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control commondate"
                                    placeholderText="mm/dd/yyyy"
                                    minDate={fromDate}
                                    maxDate={new Date()}
                                  />
                                </Form.Group>
                              </Col>
                              <Col
                                sm={4}
                                style={{ textAlign: "end", marginTop: "25px" }}
                              >
                                <div className="ledger_btn">
                                  <Button type="submit" className="cus_btn">
                                    Search logs
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                        <Row>
                          <Col sm={12}>
                            <div className="table-responsive">
                              <Table
                                id="ledgerTablee"
                                bordered
                                hover
                                className="domesticdata leger_table dataTable no-footer"
                              >
                                <thead>
                                  <tr>
                                    <th>S No.</th>
                                    <th>Detail</th>
                                    <th className="text-right">Debit</th>
                                    <th className="text-right">Credit</th>
                                    <th className="text-right">Balance</th>
                                    <th className="text-right">Date</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {filteredItems.map((ledger, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{ledger.remark}</td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "600",
                                          color: "green",
                                        }}
                                      >
                                        ₹{ledger.debit}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "600",
                                          color: "green",
                                        }}
                                      >
                                        ₹{ledger.credit}
                                      </td>
                                      <td
                                        style={{
                                          textAlign: "right",
                                          fontWeight: "600",
                                          color: "red",
                                        }}
                                      >
                                        <b>₹{walletData.Wallet}</b>
                                      </td>
                                      <td className="text-right">
                                        {formatDateTime(ledger.created_at)}
                                      </td>
                                    </tr>
                                  ))}{" "}
                                </tbody>
                              </Table>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default LedgerReport;
