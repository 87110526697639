import React, { useEffect, useState } from "react";
import { Table, Thead, Tbody, Tr, Th, Td } from "react-super-responsive-table";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "./BookingTicket.css";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { RxCross2 } from "react-icons/rx";
import { FaRegFilePdf, FaWhatsapp } from "react-icons/fa";
import { FaPerson } from "react-icons/fa6";
import { IoPrintSharp } from "react-icons/io5";
import { MdMarkEmailUnread } from "react-icons/md";
import { LiaSmsSolid } from "react-icons/lia";
import { BsCurrencyDollar } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { cities12 } from "../../Cities";
import { Markup } from "../../redux/services/operations/markup";
import { useDispatch, useSelector } from "react-redux";

const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}, ${dateNum} ${month} ${year}`;
};

const FlightBookingTickets = () => {
  const [markupActive, setMarkupActive] = useState(false);
  const { walletData } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [bookingDetails2, setBookingDetails2] = useState(null);
  const [bookingDetails, setbookingDetails] = useState(null);
  const [bookingDetailsTJ, setBookingDetailsTJ] = useState(null);
  const [refId, setRefId] = useState(null);
  const token = localStorage.getItem("token");
  const [money, setMoney] = useState("");
  const [price, setPrice] = useState(1000);
  const sessionId = localStorage.getItem("sessionId");
  const navigate = useNavigate();
  const [domesticMarkup, setDomesticMarkup] = useState(0);

  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 or 12 to 12
    return `${formattedHours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${period}`;
  };

  useEffect(() => {
    const storedFare = localStorage.getItem("add-dom");

    if (storedFare) {
      let finalPrice =
        storedFare !== undefined && storedFare !== null ? storedFare : 0;
      setDomesticMarkup(parseInt(finalPrice));
    } else {
      setDomesticMarkup(0);
    }
    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
  }, []);

 
  const handleSubmit = (e) => {
    e.preventDefault();
    parseInt(setPrice(price + parseInt(money)));
    setMarkupActive(false);
  };
  useEffect(() => {
    const BookingIdd = sessionStorage.getItem("BookingIdd");
    const AirlinePnrs = sessionStorage.getItem("AirlinePnr");
    const tokens = localStorage.getItem("token");

    const fetchData = async () => {
      const data = {
        APIToken: tokens,
        BookingId: BookingIdd,
        AirlinePnr: AirlinePnrs,
      };
      console.log("DATATATATATA", data);
      const responseP = await axios.post(
        `https://admin.yatriservice.com/api/flightbookingdetails`,
        data
      );
      if (responseP) {
        console.log("responseP", responseP);
        setbookingDetails(responseP.data.data);
        setRefId(responseP.data.RefId);
        // setbookingDetails(data);
      } else {
        // navigate("/agent/404");
        throw new Error("Failed to fetch parto data");
      }
    };

    fetchData();
  }, []);

  const downloadPdf = () => {
    const capture = document.querySelector(".ticketdata");
    html2canvas(capture).then((canvas) => {
      const imgData = canvas.toDataURL("");
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      const imageWidth = canvas.width;
      const imageHeight = canvas.height;
      const ratio = Math.min(
        componentWidth / imageWidth,
        componentHeight / imageHeight
      );
      const imageX = componentWidth - imageWidth * ratio;
      const imageY = 10;
      doc.addImage(
        imgData,
        "PNG",
        imageX,
        imageY,
        imageWidth * ratio,
        imageHeight * ratio
      );
      doc.save("bookingDetail.pdf");
    });
  };
  const [active, setActive] = useState(false);
  const [hidePerson, setHidePerson] = useState(false);
  const [hidePrice, setHidePrice] = useState(false);
  // const totalBaseFare = bookingDetails.Contracts.reduce((acc, current) => acc + current.AirlineFare.BaseFare, 0);

  const sendWhatsAppMessage = async () => {};
  console.log("bookingdeatailfcbfcgfdg", bookingDetails);
  const { adminDm, adminIm, agentDm, agentIm } = useSelector(
    (state) => state.markUp
  );
  console.log("Afabfhaefw", adminDm, adminIm, agentDm, agentIm);

  return (
    <div style={{ fontSize: "larger" }}>
      {bookingDetails && (
        <div
          className="BookingConfirmBookingHead"
          style={{ minWidth: "1280px" }}
        >
          <div className="flightBookingHeaderDiv">
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHidePerson(!hidePerson)}
            >
              <FaPerson />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                Hide person
              </span>
            </div>
            <div className="flightBookingUpperIcon" onClick={downloadPdf}>
              <IoPrintSharp />
              <span className="hotelBookingHideDetails"> Print</span>
            </div>
            <div className="flightBookingUpperIcon" onClick={downloadPdf}>
              <FaRegFilePdf />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                {" "}
                Save Pdf
              </span>
            </div>
            <div className="flightBookingUpperIcon">
              <MdMarkEmailUnread />
              <span className="hotelBookingHideDetails"> Email </span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={sendWhatsAppMessage}
            >
              <FaWhatsapp />
              <span className="hotelBookingHideDetails"> Whatsapp</span>
            </div>
            <div className="flightBookingUpperIcon">
              <LiaSmsSolid />
              <span className="hotelBookingHideDetails"> Sms</span>
            </div>
            <div
              className="flightBookingUpperIcon"
              onClick={() => setHidePrice(!hidePrice)}
            >
              <BsCurrencyDollar />
              <span className="hotelBookingHideDetails hotelBookingBigTag">
                {" "}
                Hide Price
              </span>
            </div>
            <div className="flightBookingUpperIcon">
              <BsCurrencyDollar />
              <span
                className="hotelBookingHideDetails"
                onClick={() => setMarkupActive(!markupActive)}
              >
                {" "}
                Markup
              </span>
            </div>
            <div
              style={{
                padding: "3px 6px",
                backgroundColor: "#db0e0edb",
                fontSize: "20px",
                color: "white",
              }}
            >
              <RxCross2 />
            </div>
          </div>
          {markupActive ? (
            <div className="hotelBookingMarkupMain">
              <div className="hotelBookingMarkup">
                <p className="hotelBookingMarkuppara markup-margins">
                  {" "}
                  Additional Transaction fees / discount
                </p>
                <p
                  className="markup-margins"
                  style={{
                    color: "#2d3290",
                    fontWeight: "600",
                    fontSize: "16px",
                  }}
                >
                  Existing Amount: Rs.
                </p>
                <p
                  className="markup-margins"
                  style={{ fontWeight: "500", fontSize: "16px" }}
                >
                  Reset Amount
                </p>
                <form onSubmit={handleSubmit}>
                  <input
                    type="text"
                    placeholder="Enter Amount"
                    value={money}
                    onChange={(e) => setMoney(e.target.value)}
                    style={{ width: "100%", padding: "5px 10px" }}
                  />
                  <div className="hotelBookingCloseSub">
                    <button
                      className="hotelBookingMarkupClose"
                      onClick={() => setMarkupActive(false)}
                    >
                      close
                    </button>
                    <button className="hotelBookingMarkupSub" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          ) : (
            ""
          )}
          <section className="container-fluid ticketdata">
            <div className="container">
              <div className="">
                <div className="top-part">
                  <div className="logo">
                    <img
                      src="/Images/yatri-service.png"
                      alt="Logo"
                      width={120}
                      height={50}
                    />
                  </div>
                  <div className="ticket-details">
                    <div className="details">
                      <h4>Ticket-Confirmed</h4>
                      <p>
                        <span>Refrence Id: </span>
                        {refId} <br />
                        {/* <span>Booking ID:</span> {bookingDetails.BookingId} */}
                      </p>
                      <p>
                        {/* <span>Booking Date:</span>{" "} */}
                        {/* {bookingDetails.TravelItinerary.ItineraryInfo
                          .CustomerInfoes[0].ETicketNumbers[0] &&
                          bookingDetails.TravelItinerary.ItineraryInfo
                            .CustomerInfoes[0].ETicketNumbers[0].DateOfIssue !==
                            "undefined" &&
                          formatTime(
                            bookingDetails.TravelItinerary.ItineraryInfo
                              .CustomerInfoes[0].ETicketNumbers[0].DateOfIssue
                          )} */}
                      </p>
                      <p>
                        <span>Airline PNR: </span>
                        {bookingDetails.AirlinePnr}
                      </p>
                    </div>
                  </div>
                </div>
                {hidePerson ? (
                  <div></div>
                ) : (
                  <div className="passenger-details">
                    <h4>
                      {" "}
                      Passenger
                      {/* - {passengerDetails.adults} Adult {passengerDetails.children} Child  */}
                    </h4>
                    <table className="flight-ticket_table table table-bordered text-center">
                      <thead>
                        <tr>
                          <th scope="col">Passenger</th>
                          <th scope="col">Airline</th>
                          <th scope="col">Status</th>
                          <th scope="col">Sector</th>
                          <th scope="col">Airline PNR</th>
                          <th scope="col">Ticket Number</th>
                          <th scope="col">Seat No.</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingDetails &&
                          bookingDetails.Flightpassenger.map((item) => (
                            <tr>
                              <td>
                                <p>
                                  {item.FirstName} {item.LastName}
                                </p>
                              </td>
                              <td>
                                {/* {bookingDetails.Contracts[0].AirSegments.map(
                                (item) => ( */}{" "}
                                {
                                  bookingDetails.Contracts[0].AirSegments[0]
                                    .AirlineCode
                                }
                                {/* )
                              )} */}
                              </td>

                              <td>
                                Success
                                {/* {passenger.Ticket && passenger.Ticket.Status} */}
                              </td>
                              <td>
                                {bookingDetails.Contracts.map((item) => (
                                  <p>
                                    {item.AirSegments.map((item) => (
                                      <p>
                                        {item.Origen} - {item.Destination},
                                      </p>
                                    ))}
                                  </p>
                                ))}
                              </td>
                              <td>{bookingDetails.AirlinePnr}</td>
                              <td>{item.TicketNumber}</td>
                              <td>{item.SeatCode}</td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                )}
                <div className="flight-details">
                  <h4> Flight Details </h4>
                  <table className="flight-ticket_table table text-center table-bordered">
                    <thead className="table-primary">
                      <tr>
                        <th scope="col" colSpan="2">
                          Airline Details
                        </th>
                        <th scope="col">Departure</th>
                        <th scope="col">Arrival</th>
                        <th scope="col">Duration</th>
                      </tr>
                    </thead>

                    {bookingDetails &&
                      bookingDetails.Contracts.map((data) => (
                        <tbody>
                          {data.AirSegments.map((item) => (
                            <tr style={{ background: "#6e25c129" }}>
                              <td>
                                <i
                                  className="fa-solid fa-plane-circle-check fa-fw"
                                  style={{ color: "#8b3eea" }}
                                ></i>
                              </td>
                              <td>
                                <p>
                                  <span style={{ fontWeight: 600 }}>
                                    {/* {detail.fD.aI.name} */}
                                  </span>
                                  <br />
                                  {item.AirlineCode} {item.FlightNumber} <br />
                                  Cabin Class: (
                                  {item.TypeOfClass === 0 && "Economy"}
                                  {item.TypeOfClass === 1 && "First"}
                                  {item.TypeOfClass === 2 && "Business"}
                                  {item.TypeOfClass === 3 &&
                                    "Premium_Economy"}) <br />
                                  <span
                                    style={{
                                      fontSize: "13px",
                                      color: "#8b3eea",
                                      fontWeight: "600",
                                    }}
                                  >
                                    ({item.SegmentType})
                                  </span>
                                </p>
                              </td>
                              <td>
                                <span>
                                  {/* {item.SourceAirport.city_name}  */}(
                                  {item.Origen})
                                </span>
                                <br />
                                <p style={{ marginBottom: "0px" }}>
                                  <span style={{ fontWeight: 600 }}>
                                    {item.DepartureTime === null ||
                                    item.DepartureTime === ""
                                      ? item.DepartureTime
                                      : convertToAmPm(item.DepartureTime)}
                                    <br />
                                    {item.DepartureDateTime}
                                    {/* {formatTime(item.DepartureDateTime)} &nbsp;
                                {new Date(
                                  item.DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })} */}
                                  </span>
                                </p>
                              </td>
                              <td>
                                <span>
                                  {/* {item.DestinationAirport.city_name} ( */}
                                  {item.Destination}){" "}
                                </span>
                                <br />
                                <p style={{ marginBottom: "0px" }}>
                                  <span style={{ fontWeight: 600 }}>
                                    {item.ArrivalTime === null ||
                                    item.ArrivalTime === ""
                                      ? item.ArrivalTime
                                      : convertToAmPm(item.ArrivalTime)}
                                    <br />
                                    {item.ArrivalDateTime}
                                    {/* {formatTime(item.ArrivalDateTime)} &nbsp;
                                {new Date(
                                  item.ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })} */}
                                  </span>
                                  {/* {detail.aa.name} */}
                                </p>
                              </td>
                              <td>{item.Duration}</td>
                            </tr>
                          ))}
                        </tbody>
                      ))}
                  </table>
                </div>

                <div className="baggage-flight">
                  <h4> Baggage Info </h4>
                  <table className="flight-ticket_table table text-center table-bordered">
                    <thead>
                      <tr>
                        <th scope="col" rowSpan="2">
                          Airline
                        </th>
                        <th scope="col" rowSpan="2">
                          Sector
                        </th>
                        <th scope="col" colSpan="2">
                          Baggage
                        </th>
                      </tr>
                      <tr>
                        <th scope="col">Check-In</th>
                        <th scope="col">Cabin</th>
                      </tr>
                    </thead>

                    {bookingDetails &&
                      bookingDetails.Contracts.map((data) => (
                        <tbody>
                          {data.AirSegments.map((item) => (
                            <tr>
                              <td>
                                {item.AirlineCode}
                                <span
                                  style={{
                                    fontSize: "12px",
                                    marginLeft: "4px",
                                    color: "#8b3eea",
                                    fontWeight: "600",
                                  }}
                                >
                                  ({item.SegmentType})
                                </span>
                              </td>
                              <td>
                                ( {item.Origen}-{item.Destination})
                              </td>
                              <td>{item.BaggageAllowed.CheckInBaggage}</td>
                              <td>{item.BaggageAllowed.HandBaggage}</td>
                            </tr>
                          ))}
                        </tbody>
                      ))}
                  </table>
                </div>

                {!hidePrice && (
                  <div className="flight-details">
                    <h4> Fare Info </h4>
                    <table className="flight-ticket_table table text-center table-bordered">
                      <thead className=" table-primary">
                        <tr style={{ backgroundColor: "#6e25c129 !important" }}>
                          <th scope="col"> Base Fare</th>
                          <th scope="col">Taxes & Surcharges/Other Charges</th>
                          <th scope="col">Discount</th>
                          <th scope="col">Service Fee</th>
                          <th scope="col">Excess Baggage</th>
                          <th scope="col">Meal</th>
                          <th scope="col">Seat Charges</th>
                          {/* <th scope="col">Other Charges</th> */}
                          <th scope="col">Total Fare</th>
                        </tr>
                      </thead>
                      <tbody>
                        {bookingDetails && (
                          <tr>
                            <td>
                              {" "}
                              ₹{/* {overallBaseFare}  */}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .BaseFare}
                              {/* {
                                  bookingDetails.TravelItinerary
                                    .ItineraryInfo.ItineraryPricing.BaseFare
                                } */}
                            </td>
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .TaxFare +
                                  adminIm +
                                  adminIm +
                                  agentIm +
                                  agentIm +
                                  (money && parseInt(money))}
                              {/* {
                                  bookingDetails.TravelItinerary
                                    .ItineraryInfo.ItineraryPricing.TotalTax +
                                    bookingDetails.TravelItinerary
                                      .ItineraryInfo.ItineraryPricing.ServiceTax
                                } */}
                            </td>
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .Discount}
                            </td>
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .ServiceCharge}
                            </td>
                            <td>₹ 0</td>
                            <td>₹ 0</td>
                            <td>₹ 0</td>
                            {/* <td>
                              ₹ {!bookingDetails.Contracts[1] && markup + (money && parseInt(money))}
                              {bookingDetails.Contracts[1] &&
                                markup + markup + (money && parseInt(money))}{" "}
                            </td> */}
                            <td>
                              ₹{" "}
                              {bookingDetails.Contracts[0] &&
                                bookingDetails.Contracts[0].AirlineFare
                                  .GrossFare +
                                  adminIm +
                                  agentIm +  adminIm +
                                  agentIm +
                                  (money && parseInt(money))}
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                )}

                <div className="personal-details">
                  <h4> Company Contact Details </h4>
                  <table className="flight-ticket_table table table-bordered">
                    <tbody>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Name</td>
                        <td>Yatri Service </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Address</td>
                        <td>Delhi, India-41</td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Contact</td>
                        <td>+91-9721647550 </td>
                      </tr>
                      <tr>
                        <td style={{ fontWeight: 700 }}>Email</td>
                        <td>Support@yatriservice.com</td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div className="information-details">
                  <h5>Passport/Visa/Health</h5>
                  <ul
                    style={{
                      listStyleType: "disc",
                      paddingInlineStart: "20px",
                    }}
                  >
                    <li>
                      {" "}
                      Please ensure that you have all the required travel
                      documents for your entire journey i.e., valid passport &
                      necessary visas, and that you have had the recommended
                      inoculations for your destination(s).
                    </li>
                    <li>
                      {" "}
                      All passengers, including children and infants, have to
                      present their valid ID proof at the time of check-in.
                    </li>
                    <li>
                      {" "}
                      We recommend you check-in at least 3 hours prior to
                      departure of your domestic flight and 4 hours prior to
                      your international flight
                    </li>
                    <li>
                      Carriage and other facilities provided by the carrier are
                      subject to their Terms and Condition. We are not liable
                      for missing any facility of the carrier.
                    </li>
                    <li>
                      Recheck your baggage with your respective airline before
                      traveling for a hassle-free travel experience.
                    </li>
                    <li>
                      Group Booking Rules will be applicable if passengers are 9
                      or more in numbers.
                    </li>
                    <li>
                      Company is not responsible for any delay or cancellation
                      of flights from airline's end.
                    </li>
                    <li>
                      Unaccompanied Child: Children below the age of 12 will not
                      be accepted for carriage unless they are accompanied by a
                      person of at least 18 years of age. Such child/children
                      must be seated next to the accompanying adult. The
                      accompanying adult is solely responsible for the
                      well-being of the child/children traveling together with
                      him/her. This also includes ensuring that seats are booked
                      to ensure child/children and an accompanying adult are
                      seated together.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </section>
        </div>
      )}
    </div>
  );
};

export default FlightBookingTickets;
