import React from "react";
import { Col, Row } from "react-bootstrap";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import { IoRadioButtonOffSharp, IoRadioButtonOnOutline } from "react-icons/io5";
import { MdOutlineFlight } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { Link } from "react-router-dom";
import { DayPickerRangeController } from "react-dates";
import { FaChevronDown } from "react-icons/fa";
import "./FlightFormStyle.css";

const FlightForm = ({
  dataSearch,
  handleSubmit,
  tripsActive,
  active,
  active2,
  active3,
  handleSearchFlight,
  handleSearchFlightRound,
  handleSearchFlightMultiPle,
  setTripsActive,
  searchInput,
  searchInput2,
  SetClickDestination,
  SetClickDestination2,
  isItemSelected,
  isItemSelected2,
  handleInputChange,
  handleInputChange2,
  filteredCities,
  filteredCities2,
  clickDestination,
  clickDestination2,
  handleCitySelect,
  handleCitySelect2,
  startDate,
  setStartDate,
  endDate,
  toggleCalendar,
  rooms,
  setTravellerActive,
  travellerActive,
  updateRoom,
  selectedOption,
  handleOptionChange,
  calVisible,
  setCalVisible,
  handleDatesChange,
  focusedInput,
  setFocusedInput,
  renderDayContents,
  numberOfMonths,
}) => {
  return (
    <section className="pr animated search-page" id="flightSRP">
      <section className="modify-search hide-mobile">
        {/* ngIf: !isreschd */}
        <div className="wr-hr-center wr-width" >
          {dataSearch && (
            <div className="new-theme">
              {/* ngIf: !multicitySummary */}
              <form
                className="grid ng-pristine ng-valid ng-valid-required"
                onSubmit={handleSubmit}
              >
                {tripsActive ? (
                  <ul className="trip-type fs-14 pt-15 animated">
                    <li
                      onClick={handleSearchFlight}
                      className={`sfp-trip ${active ? "active" : ""}`}
                    >
                      <div className="trip-type-input-holder cursor-pointer">
                        <span className="fs-14 pl-5">
                          {" "}
                          {active ? (
                            <IoRadioButtonOnOutline
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />
                          ) : (
                            <IoRadioButtonOffSharp
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />
                          )}
                          One Way
                        </span>
                      </div>
                    </li>
                    <li
                      onClick={handleSearchFlightRound}
                      className={`sfp-trip ${active2 ? "active" : ""}`}
                    >
                      <div className="trip-type-input-holder cursor-pointer">
                        <span className="fs-14 pl-5">
                          {active2 ? (
                            <IoRadioButtonOnOutline
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />
                          ) : (
                            <IoRadioButtonOffSharp
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />
                          )}
                          Round Trip
                        </span>
                      </div>
                    </li>
                    {/* <li
                      onClick={handleSearchFlightMultiPle}
                      className={`sfp-trip ${active3 ? "active" : ""}`}
                    >
                      <div className="trip-type-input-holder cursor-pointer">
                        <span className="fs-14 pl-5">
                          {active3 ? (
                            <IoRadioButtonOnOutline
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />
                          ) : (
                            <IoRadioButtonOffSharp
                              style={{
                                marginTop: "-2px",
                                marginRight: "3px",
                              }}
                            />
                          )}
                          Multicity
                        </span>
                      </div>
                    </li> */}
                    <li
                      className="pull-right"
                      onClick={() => setTripsActive(false)}
                    >
                      <RxCross2 className="fs-22 i-b pull-right cursor-pointer ytfi-cancel" />
                    </li>
                  </ul>
                ) : (
                  ""
                )}
                {/* ngRepeat: details in orgDestModel track by $index */}
                <ul className="col-10 flight-details animated">
                  {/* ngIf: timeIndex==1 */}
                  <img src="/Images/travel.png" alt="" width={40} />
                  <li className="sel-icon fs-22 ytfi-trailing-plane v-aligm-m" />

                  <li className="sel-origin pr v-aligm-m">
                    <label className="fs-10 light-label">From</label>
                    <div
                      className="input-holder pb-2 bdr-btm"
                      style={{ position: "relative" }}
                    >
                      <input
                        type="text"
                        name="from"
                        // placeholder={dataSearch.Segments[0].Origin}
                        className="fs-16 bold ng-touched ng-dirty ng-valid-parse ng-invalid ng-invalid-required ellipsis full-width ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
                        // value={destinationCity}
                        value={searchInput}
                        onClick={() => setTripsActive(true)}
                        // readOnly

                        onChange={(e) => handleInputChange(e.target.value)}
                        autoComplete="off"
                      />
                      <div style={{ position: "relative" }}>
                        <div
                          className="cityselect"
                          style={{
                            position: "absolute",
                            color: "black",
                            backgroundColor: "#8B3EEA",
                            padding: "10px",
                            border: "1px solid #8B3EEA",
                            display:
                              searchInput && clickDestination
                                ? "block"
                                : "none",
                            width: "113%",
                            zIndex: 9,
                            // top: "-18px",
                            // right: "-13px",
                            maxHeight: 300,
                            // overflow: "auto",
                            overflowX: "hidden",
                            overflowY: "auto",
                            scrollbarWidth: "thin",
                          }}
                        >
                          {filteredCities.map((city, index) => (
                            <div
                              key={index}
                              style={{
                                borderBottom: "1px solid grey",
                                paddingBottom: 5,
                                marginBottom: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => handleCitySelect(city)}
                            >
                              <Row>
                                <Col md={1} style={{ alignItems: "center" }}>
                                  <MdOutlineFlight
                                    size={22}
                                    style={{
                                      textAlign: "center",

                                      height: "100%",
                                      color: "#fff",
                                    }}
                                  />
                                </Col>
                                <Col md={10}>
                                  <Row>
                                    <div
                                      className="flightFromName"
                                      style={{
                                        color: "#fff",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {city["CITYNAME"]} ({city["CITYCODE"]})
                                    </div>
                                  </Row>
                                  <Row style={{ color: "grey" }}>
                                    <Col md={10} style={{ paddingRight: 0 }}>
                                      <div
                                        className="flightFromNameInner"
                                        style={{
                                          fontSize: 10,
                                          fontWeight: 600,
                                          color: "#fff",
                                        }}
                                      >
                                        {city["AIRPORTNAME"]}
                                      </div>
                                    </Col>
                                    <Col md={2} style={{ paddingLeft: 0 }}>
                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "700",
                                          fontSize: 10,
                                          color: "#fff",
                                        }}
                                      >
                                        {city["COUNTRYCODE"]}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>

                    <FaArrowRightArrowLeft
                      className="ytfi-arrow1 abs rotate-anim cursor-pointer"
                      size={16}
                    />
                  </li>

                  <li className="sel-dest pr v-aligm-m">
                    <label className="fs-10 light-label">To</label>
                    <div className="input-holder  bdr-btm pb-2">
                      <input
                        type="text"
                        name="to"
                        // placeholder={dataSearch.Segments[0].Destination}
                        className="fs-16 bold ng-touched ng-dirty ng-invalid ng-invalid-required ellipsis full-width ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
                        value={searchInput2}
                        onClick={() => setTripsActive(true)}
                        onChange={(e) => handleInputChange2(e.target.value)}
                        autoComplete="off"
                      />
                      <div style={{ position: "relative" }}>
                        <div
                          className="cityselect"
                          style={{
                            position: "absolute",
                            color: "black",
                            backgroundColor: "#8B3EEA",
                            padding: "10px",
                            border: "1px solid #8B3EEA",
                            display:
                              searchInput2 && clickDestination2
                                ? "block"
                                : "none",
                            width: "113%",
                            zIndex: 9,
                            maxHeight: 300,
                            overflow: "hidden",
                          }}
                        >
                          {filteredCities2.map((city, index) => (
                            <div
                              key={index}
                              style={{
                                borderBottom: "1px solid grey",
                                paddingBottom: 5,
                                marginBottom: 5,
                                cursor: "pointer",
                              }}
                              onClick={() => handleCitySelect2(city)}
                            >
                              <Row>
                                <Col md={1} style={{ alignItems: "center" }}>
                                  <MdOutlineFlight
                                    size={22}
                                    style={{
                                      textAlign: "center",
                                      height: "100%",
                                      color: "#fff",
                                    }}
                                  />
                                </Col>
                                <Col md={10}>
                                  <Row>
                                    <div
                                      className="flightFromName"
                                      style={{
                                        color: "#fff",
                                        fontWeight: 600,
                                      }}
                                    >
                                      {city["CITYNAME"]} ({city["CITYCODE"]})
                                    </div>
                                  </Row>
                                  <Row style={{ color: "grey" }}>
                                    <Col md={10} style={{ paddingRight: 0 }}>
                                      <div
                                        className="flightFromNameInner"
                                        style={{
                                          fontSize: 10,
                                          fontWeight: 600,
                                          color: "#fff",
                                        }}
                                      >
                                        {city["AIRPORTNAME"]}
                                      </div>
                                    </Col>
                                    <Col md={2} style={{ paddingLeft: 0 }}>
                                      <div
                                        style={{
                                          textAlign: "center",
                                          fontWeight: "700",
                                          fontSize: 10,
                                          color: "#fff",
                                        }}
                                      >
                                        {city["COUNTRYCODE"]}
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </li>
                  {/* date */}
                  <li className="sel-date pr bdr-btm v-aligm-m">
                    <div className="input-holder pb-2 depart">
                      <input
                        type="text"
                        name="departureDate"
                        id="departureDateInput"
                        className="hide fs-16 bold ellipsis full-width cursor-pointer ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
                        placeholder="Departure Date"
                        value={startDate ? startDate.format("MM/DD/YYYY") : ""}
                        readOnly
                        onClick={toggleCalendar}
                        autoComplete="off"
                        // onClick={() => setTripsActive(true)}
                      />
                      <label className="fs-10" htmlFor="flight_depart_date_0">
                        <span className="light-label">Date</span>
                        <input
                          type="text"
                          name="departureDate"
                          id="departureDateInput"
                          className="fs-16 bold ellipsis full-width cursor-pointer"
                          placeholder="Departure Date"
                          value={
                            startDate ? startDate.format("MM/DD/YYYY") : ""
                          }
                          readOnly
                          onClick={toggleCalendar}
                          autoComplete="off"
                          // onClick={() => setTripsActive(true)}
                        />
                      </label>
                    </div>
                    <span className="hyphen" ng-if="modifyData.type != 'M'">
                      -{" "}
                    </span>
                    <div className="input-holder pb-2 return">
                      <input
                        type="text"
                        id="returnDateInput"
                        name="returnDate"
                        className="hide fs-16 bold ellipsis full-width cursor-pointer ng-pristine ng-untouched ng-valid ng-not-empty ng-valid-required"
                        placeholder="Enter Return Date"
                        value={
                          endDate && active2 ? endDate.format("MM/DD/YYYY") : ""
                        }
                        readOnly
                        onClick={() => {
                          handleSearchFlightRound();
                          toggleCalendar("endDate");
                        }}
                        autoComplete="off"
                        // onClick={() => setTripsActive(true)}
                      />
                      <label className="fs-10">
                        <span className="light-label">Return</span>
                        <input
                          type="text"
                          id="returnDateInput"
                          name="returnDate"
                          className="fs-16 bold ellipsis full-width cursor-pointer"
                          placeholder="Enter Return Date"
                          value={
                            endDate && active2
                              ? endDate.format("MM/DD/YYYY")
                              : ""
                          }
                          readOnly
                          onClick={() => {
                            handleSearchFlightRound();
                            toggleCalendar("endDate");
                          }}
                          autoComplete="off"
                          // onClick={() => setTripsActive(true)}
                        />
                      </label>
                    </div>
                    <RxCross2
                      className="fs-18 cursor-pointer abs ytfi-cancel"
                      size={14}
                      style={{ marginTop: "-5px" }}
                    />
                    &nbsp;
                  </li>

                  <li className="sel-class pr v-aligm-m">
                    <div className="">
                      <span className="light-label fs-10">
                        Traveller(s), Class
                      </span>
                      <div
                        className="bdr-btm drop-down bold fs-16 cursor-pointer full-width"
                        // ng-click="showDropDown($event,true)"
                      >
                        <input
                          type="text"
                          placeholder={`${
                            rooms[0].adults +
                            rooms[0].children +
                            rooms[0].infants
                          } Travellers, ${
                            selectedOption === 0 ? "Economy" : ""
                          } ${selectedOption === 1 ? "First" : ""} ${
                            selectedOption === 4 ? " Premium Economy" : ""
                          }  ${selectedOption === 3 ? "Business" : ""}`}
                          className="ellipsis fs-14 i-b"
                          // style={{ fontSize: 12 }}
                          onClick={() => setTravellerActive(!travellerActive)}
                          autoComplete="off"
                        />

                        <FaChevronDown className="cursor-pointer fs-8 v-aligm-m rotate-anim i-b ytfi-angle-down" />

                        {travellerActive === true ? (
                          <div className="traveller-table abs ">
                            <div className="tab-container">
                              <div className="triangle abs" />
                              <ul>
                                <li className="noOf fs-14 pt-20 pb-16">
                                  <span className="bold">
                                    {rooms[0].adults}{" "}
                                  </span>
                                  <span className="fs-14 bold">Adult</span>
                                  <div className="spinner">
                                    <Link
                                      type="button"
                                      id="Adults_room_1_1_minus"
                                      className="minus"
                                      onClick={() =>
                                        updateRoom(
                                          0,
                                          "adults",
                                          Math.max(rooms[0].adults - 1, 0)
                                        )
                                      }
                                    >
                                      -
                                    </Link>
                                    <Link
                                      type="button"
                                      id="Adults_room_1_1_plus"
                                      className="plus"
                                      onClick={() =>
                                        updateRoom(
                                          0,
                                          "adults",
                                          Math.min(rooms[0].adults + 1, 6)
                                        )
                                      }
                                    >
                                      +
                                    </Link>
                                  </div>
                                </li>
                                <li className="noOf fs-14 pt-20 pb-16">
                                  <span className="bold">
                                    {" "}
                                    {rooms[0].children}{" "}
                                  </span>
                                  <span className="fs-14 bold">Child</span>
                                  <span className="cat-info fs-12 ml-5">
                                    2-12 years
                                  </span>
                                  <div className="spinner">
                                    <Link
                                      type="button"
                                      id="Children_room_1_1_minus"
                                      className="minus"
                                      onClick={() =>
                                        updateRoom(
                                          0,
                                          "children",
                                          Math.max(rooms[0].children - 1, 0)
                                        )
                                      }
                                    >
                                      -
                                    </Link>
                                    <Link
                                      type="button"
                                      id="Children_room_1_1_plus"
                                      className="plus"
                                      onClick={() =>
                                        updateRoom(
                                          0,
                                          "children",
                                          Math.min(rooms[0].children + 1, 6)
                                        )
                                      }
                                    >
                                      +
                                    </Link>
                                  </div>
                                </li>
                                <li className="noOf fs-14 pt-20 pb-16">
                                  <span className="bold">
                                    {" "}
                                    {rooms[0].infants}{" "}
                                  </span>
                                  <span className="fs-14 bold">
                                    Infant{/* ngIf: modifyData.INF>1 */}
                                  </span>
                                  <span className="cat-info fs-12 ml-5">
                                    Below 2 years
                                  </span>
                                  <div className="spinner">
                                    <Link
                                      type="button"
                                      id="Children_room_1_1_minus"
                                      className="minus "
                                      onClick={() =>
                                        updateRoom(
                                          0,
                                          "infants",
                                          Math.max(rooms[0].infants - 1, 0)
                                        )
                                      }
                                    >
                                      -
                                    </Link>
                                    <Link
                                      type="button"
                                      id="Children_room_1_1"
                                      className="plus"
                                      onClick={() =>
                                        updateRoom(
                                          0,
                                          "infants",
                                          Math.min(
                                            rooms[0].infants + 1,
                                            rooms[0].adults
                                          )
                                        )
                                      }
                                    >
                                      +
                                    </Link>
                                  </div>
                                </li>
                              </ul>
                              <div></div>
                              <div className="class-type">
                                <div className="custom-radio">
                                  <label className="mt-8">
                                    <input
                                      type="radio"
                                      value="Economy"
                                      className="ng-valid ng-dirty ng-touched full-width abs ng-pristine ng-untouched ng-not-empty"
                                      checked={selectedOption === 0}
                                      onClick={() => handleOptionChange(0)}
                                    />
                                    <span className="radio-cs" />
                                    <span className="fs-14 text">Economy</span>
                                  </label>
                                </div>
                                <div
                                  className="custom-radio"
                                  ng-repeat="key in className"
                                >
                                  <label className="mt-8">
                                    <input
                                      type="radio"
                                      value="first"
                                      className="ng-valid ng-dirty ng-touched full-width abs ng-pristine ng-untouched ng-not-empty"
                                      checked={selectedOption === 1}
                                      onClick={() => handleOptionChange(1)}
                                    />
                                    <span className="radio-cs" />
                                    <span className="fs-14 text">First</span>
                                  </label>
                                </div>
                                {/* end ngRepeat: key in className */}
                                <div
                                  className="custom-radio"
                                  ng-repeat="key in className"
                                >
                                  <label className="mt-8">
                                    <input
                                      type="radio"
                                      value="Premium Economy"
                                      className="ng-valid ng-dirty ng-touched full-width abs ng-pristine ng-untouched ng-not-empty"
                                      checked={selectedOption === 4}
                                      onClick={() => handleOptionChange(4)}
                                    />
                                    <span className="radio-cs" />
                                    <span className="fs-14 text">
                                      Premium Economy
                                    </span>
                                  </label>
                                </div>
                                {/* end ngRepeat: key in className */}
                                <div
                                  className="custom-radio"
                                  ng-repeat="key in className"
                                >
                                  <label className="mt-8">
                                    <input
                                      type="radio"
                                      value="Business"
                                      className="ng-valid ng-dirty ng-touched full-width abs ng-pristine ng-untouched ng-not-empty"
                                      checked={selectedOption === 3}
                                      onClick={() => handleOptionChange(3)}
                                    />
                                    <span className="radio-cs" />
                                    <span className="fs-14 text">Business</span>
                                  </label>
                                </div>
                                {/* end ngRepeat: key in className */}
                              </div>
                              <div className="cat-sel cursor-pointer tr">
                                <input
                                  type="button"
                                  className="fs-14 btn cursor-pointer mb-10 bold"
                                  value="Done"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    setTravellerActive(false);
                                  }}
                                />
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </li>
                </ul>
                <div className="sel-submit col-2 text-right submit-search-btn">
                  <label>Search</label>
                  <div className="input-holder pb-2">
                    <button
                      className="fs-14 btn-submit cursor-pointer bold"
                      // onClick={() => setTravellerActive(false)}
                    >
                      Search
                    </button>
                  </div>
                </div>
                <div className="col-10 pb-14 mt-15 add-city animated ng-hide">
                  <button className="fs-14 secondary-button button cursor-pointer bold">
                    + Add City
                  </button>
                </div>
              </form>
              <div
                style={{
                  position: "absolute",
                  zIndex: "2000",
                  width: "100%",
                  top: "64px",
                  display: "flex",
                  justifyContent: "center",
                  // top: 50
                }}
              >
                {active2
                  ? calVisible && (
                      <DayPickerRangeController
                        startDate={startDate}
                        endDate={endDate}
                        onDatesChange={handleDatesChange}
                        focusedInput={focusedInput}
                        onFocusChange={(focused) => setFocusedInput(focused)}
                        renderDayContents={renderDayContents}
                        numberOfMonths={numberOfMonths}
                      />
                    )
                  : calVisible && (
                      <DayPickerRangeController
                        startDate={startDate}
                        endDate={null}
                        onDatesChange={({ startDate }) => {
                          setStartDate(startDate);
                          setCalVisible(false);
                        }}
                        focusedInput={focusedInput}
                        onFocusChange={(focused) => {
                          if (focused) setFocusedInput("startDate");
                        }}
                        renderDayContents={renderDayContents}
                        numberOfMonths={numberOfMonths}
                      />
                    )}
              </div>
              {/* end ngIf: !multicitySummary */}
              {/* ngIf: multicitySummary */}
            </div>
          )}
        </div>
        {/* end ngIf: !isreschd */}
      </section>
    </section>
  );
};

export default FlightForm;
