import React, { useEffect, useState } from "react";
import "./Dashboard.css";
import { Container, Row, Col, Dropdown, Button, Form } from "react-bootstrap";
import { MdFlightTakeoff } from "react-icons/md";
import { Link } from "react-router-dom";
// import { cities2 } from "../../Cities";
import { useNavigate } from "react-router-dom";
import { MdOutlineFlight } from "react-icons/md";
import FlightDeal from "../../Components/Home/HomePages/FlightDeal";
import axios from "axios";
import { DayPickerRangeController } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { Markup } from "../../redux/services/operations/markup";
// import { getCityData } from "../../redux/services/operations/auth";

export const fares = [
  { date: "2024-05-01", fare: "$200" },
  { date: "2024-05-02", fare: "$220" },
  { date: "2024-05-03", fare: "$240" },
  { date: "2024-05-04", fare: "$230" },
  { date: "2024-05-05", fare: "$250" },
  { date: "2024-05-06", fare: "$260" },
  { date: "2024-05-07", fare: "$270" },
  { date: "2024-05-08", fare: "$280" },
  { date: "2024-05-09", fare: "$290" },
  { date: "2024-05-10", fare: "$300" },
  { date: "2024-05-11", fare: "$310" },
  { date: "2024-05-12", fare: "$320" },
  { date: "2024-05-13", fare: "$330" },
  { date: "2024-05-14", fare: "$340" },
  { date: "2024-05-15", fare: "$350" },
  { date: "2024-05-16", fare: "$360" },
  { date: "2024-05-17", fare: "$370" },
  { date: "2024-05-18", fare: "$380" },
  { date: "2024-05-19", fare: "$390" },
  { date: "2024-05-20", fare: "$400" },
  { date: "2024-05-21", fare: "$410" },
  { date: "2024-05-22", fare: "$420" },
  { date: "2024-05-23", fare: "$430" },
  { date: "2024-05-24", fare: "$440" },
  { date: "2024-05-25", fare: "$450" },
  { date: "2024-05-26", fare: "$460" },
  { date: "2024-05-27", fare: "$470" },
  { date: "2024-05-28", fare: "$480" },
  { date: "2024-05-29", fare: "$490" },
  { date: "2024-05-30", fare: "$500" },
  { date: "2024-05-31", fare: "$510" },

  { date: "2024-04-01", fare: "$520" },
  { date: "2024-04-02", fare: "$530" },
  { date: "2024-04-03", fare: "$540" },
  { date: "2024-04-04", fare: "$550" },
  { date: "2024-04-05", fare: "$560" },
  { date: "2024-04-06", fare: "$570" },
  { date: "2024-04-07", fare: "$580" },
  { date: "2024-04-08", fare: "$590" },
  { date: "2024-04-09", fare: "$600" },
  { date: "2024-04-10", fare: "$610" },
  { date: "2024-04-11", fare: "$620" },
  { date: "2024-04-12", fare: "$630" },
  { date: "2024-04-13", fare: "$640" },
  { date: "2024-04-14", fare: "$650" },
  { date: "2024-04-15", fare: "$660" },
  { date: "2024-04-16", fare: "$670" },
  { date: "2024-04-17", fare: "$680" },
  { date: "2024-04-18", fare: "$690" },
  { date: "2024-04-19", fare: "$700" },
  { date: "2024-04-20", fare: "$710" },
  { date: "2024-04-21", fare: "$720" },
  { date: "2024-04-22", fare: "$730" },
  { date: "2024-04-23", fare: "$740" },
  { date: "2024-04-24", fare: "$750" },
  { date: "2024-04-25", fare: "$760" },
  { date: "2024-04-26", fare: "$770" },
  { date: "2024-04-27", fare: "$780" },
  { date: "2024-04-28", fare: "$790" },
  { date: "2024-04-29", fare: "$800" },
  { date: "2024-04-30", fare: "$810" },
];

export const extractBracketValue = (str) => {
  const regex = /\(([^)]+)\)/;
  const match = regex.exec(str);
  return match ? match[1] : null;
};

const HomeDash = () => {
  const { walletData } = useSelector((state) => state.auth);
  const [datass, setDatass] = useState("");
  useEffect(() => {
    const dataa = localStorage.getItem("walletData");
    if (dataa) {
      setDatass(walletData.Id);
    }
  }, []);
  const formatTime = (arrTime) => {
    const date = new Date(arrTime);
    const days = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = days[date.getDay()];

    return `${day}`;
  };
  const [travellerActive, setTravellerActive] = useState(false);
  const [selectedClass, setSelectedClass] = useState("Economy"); // Initial selected class

  // Function to handle class selection
  const handleClassSelect = (className) => {
    setSelectedClass(className);
    setTravellerActive(false);
  };
  console.log("Select classsssssssssssssssss", selectedClass);

  const [roundTrip, setRoundTrip] = useState(false);
  const handleActive = () => {
    setTravellerActive(true);
    console.log("clickeddddddddddd");
  };

  const [passengerCount, setPassengerCount] = useState(0);
  const [cabinclassName, setCabinclassName] = useState("Business");

  const handlePassengerChange = (count) => {
    setPassengerCount(count);
  };

  const handleCabinChange = (cabin) => {
    setCabinclassName(cabin);
  };
  const handletravellerClose = () => {
    setTravellerActive(!travellerActive);
  };
  const [rooms, setRooms] = useState([{ adults: 1, children: 0, infants: 0 }]);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [labelClicked, setLabelClicked] = useState(false);

  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };
  const [date, setDate] = useState("2024-05-28");
  const handleDateChange = (event) => {
    setDate(event.target.value);
  };
  console.log("Datafafnafasfn", date);
  const formatDate = (dateString) => {
    const [year, month, day] = dateString.split("-");
    return `${month}/${day}/${year}`;
  };

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    setBreakpoints();
    return () => window.removeEventListener("resize", handleResize);
  });

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992;

    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30);
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60);
    } else {
      setDateRangeConfig(2, 40, 50);
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize) => {
    setNumberOfMonths(numberOfMonths);
  };

  const [numberOfMonths, setNumberOfMonths] = useState(2);

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [calVisible, setCalVisible] = useState(false);

  const isSameDay = (date1, date2) => date1.isSame(date2, "day");

  const renderDayContents = (day) => {
    // const fare = fares.find((item) => isSameDay(day, item.date));
    const isStartDate = startDate && isSameDay(day, startDate);
    const isEndDate = endDate && isSameDay(day, endDate);
    const isInRange =
      startDate && endDate && day.isBetween(startDate, endDate, "day", "[]");

    let classNames = ["DayPicker-Day"];
    if (isStartDate) classNames.push("DayPicker-Day--start");
    if (isEndDate) classNames.push("DayPicker-Day--end");
    if (isInRange) classNames.push("DayPicker-Day--range");

    return (
      <div className={classNames.join(" ")}>
        <span style={{ fontWeight: "600", fontSize: "13px" }}>
          {day.format("D")}
        </span>
        <br />
      </div>
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFocusedInput(null);
      setCalVisible(false);
    } else if (startDate) {
      setFocusedInput("endDate");
    }
  };

  const toggleCalendar = (input) => {
    setCalVisible(!calVisible);
    if (!calVisible) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        setCalVisible(!calVisible);
        if (!calVisible) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };

  const handleChange = () => {};

  const navigate = useNavigate();

  // const handleSubmit = async (event) => {
  //   event.preventDefault();

  //   const dataaaa = {
  //     AgentId: walletData && (walletData.Id || datass),
  //   };
  //  await axios.post(
  //     `https://admin.yatriservice.com/api/markup`,
  //     { dataaaa }
  //   );

  //   const cabinMapping = {
  //     Economy: 0,
  //     First: 1,
  //     Business: 3,
  //     Premium_Economy: 4,
  //   };

  //   const tripTypeMapping = {
  //     OneWay: 0,
  //     RoundTrip: 1,
  //     MultiCity: 2,
  //   };

  //   const selectedClass5 = cabinMapping[selectedClass];
  //   const tripType = tripTypeMapping[active ? "OneWay" : "RoundTrip"];

  //   const SearchData = {
  //     AdultCount: rooms[0].adults,
  //     ChildCount: rooms[0].children,
  //     InfantCount: rooms[0].infants,
  //     JourneyType: tripType,
  //     Segments: [
  //       {
  //         Origin: extractBracketValue(searchInput),
  //         Destination: extractBracketValue(searchInput2),
  //         FlightCabinClass: selectedClass5,
  //         PreferredDepartureTime:
  //           startDate && startDate.startOf("day").format("YYYY-MM-DD"),
  //         PreferredArrivalTime: endDate
  //           ? endDate && endDate.startOf("day").format("YYYY-MM-DD")
  //           : startDate && startDate.startOf("day").format("YYYY-MM-DD"),
  //       },
  //     ],
  //   };
  //   if (
  //     SearchData.Segments[0].Destination === null ||
  //     SearchData.Segments[0].Origin === null ||
  //     SearchData.Segments[0].PreferredDepartureTime === null
  //   ) {
  //     alert("Please Fill all the fields.");
  //   } else {
  //     if (active) {
  //       navigate(
  //         `/agent/flightList/${encodeURIComponent(
  //           `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
  //         )}`
  //       );
  //     }

  //     if (active2) {
  //       if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
  //         navigate(
  //           `/agent/international-round/${encodeURIComponent(
  //             `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
  //           )}`
  //         );
  //       } else {
  //         navigate(
  //           `/agent/round/${encodeURIComponent(
  //             `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
  //           )}`
  //         );
  //       }
  //     }
  //   }
  // };
  const dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();

    const dataaaa = {
      AgentId: walletData.Id || datass,
    };
    console.log("Dataaaaaaaaa", dataaaa);
    dispatch(Markup(dataaaa));
    try {
      const cabinMapping = {
        Economy: 0,
        First: 1,
        Business: 3,
        Premium_Economy: 4,
      };

      const tripTypeMapping = {
        OneWay: 0,
        RoundTrip: 1,
        MultiCity: 2,
      };

      const selectedClass5 = cabinMapping[selectedClass];
      const tripType = tripTypeMapping[active ? "OneWay" : "RoundTrip"];

      const SearchData = {
        AdultCount: rooms[0].adults,
        ChildCount: rooms[0].children,
        InfantCount: rooms[0].infants,
        JourneyType: tripType,
        Segments: [
          {
            Origin: extractBracketValue(searchInput),
            Destination: extractBracketValue(searchInput2),
            FlightCabinClass: selectedClass5,
            PreferredDepartureTime:
              startDate && startDate.startOf("day").format("YYYY-MM-DD"),
            PreferredArrivalTime: endDate
              ? endDate.startOf("day").format("YYYY-MM-DD")
              : startDate && startDate.startOf("day").format("YYYY-MM-DD"),
          },
        ],
      };

      if (
        SearchData.Segments[0].Destination === null ||
        SearchData.Segments[0].Origin === null ||
        SearchData.Segments[0].PreferredDepartureTime === null
      ) {
        alert("Please Fill all the fields.");
      } else {
        if (active) {
          navigate(
            `/agent/flightList/${encodeURIComponent(
              `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
            )}`
          );
        }

        if (active2) {
          if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
            navigate(
              `/agent/international-round/${encodeURIComponent(
                `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
              )}`
            );
          } else {
            navigate(
              `/agent/round/${encodeURIComponent(
                `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
              )}`
            );
          }
        }
      }
    } catch (error) {
      console.error(
        "Error during API call:",
        error.response ? error.response.data : error.message
      );
      alert("An error occurred while submitting your data.");
    }
  };

  const [clickDestination, SetClickDestination] = useState(false);

  const [clickDestination2, SetClickDestination2] = useState(false);

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: "Flights",
          text: `Check out this page: ${window.location.href}`,
          url: window.location.href,
        });
      } catch (error) {
        console.error("Error sharing:", error.message);
      }
    } else {
      alert("Web Share API not supported in this browser.");
    }
  };

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState({
    code: "af",
    name: "Afghanistan",
  });
  // const nationalityOptions = [
  //   { code: "in", name: "India" },
  //   // Add more countries as needed
  // ];

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };
  const [searchInput, setSearchInput] = useState("");
  const [searchInput2, setSearchInput2] = useState("");
  const [cities2, setCities2] = useState([]);
  const [cities22, setCities22] = useState([]);
  const fetchDatas = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        console.log("RESULTS", results);
        setCities2(results);
      });
  };

  const handleInputChange = (value) => {
    // value.toLowerCase();
    setSearchInput(value.toLowerCase());
    fetchDatas(value);
    SetClickDestination(true);
  };
  const fetchDatass = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        // console.log("RESULTS",results)
        setCities22(results);
      });
  };
  const handleInputChange2 = (value) => {
    setSearchInput2(value.toLowerCase());
    fetchDatass(value);
    SetClickDestination2(true);
  };
  const [destinationCity, setDestinationCity] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [isItemSelected, setIsItemSelected] = useState(false);
  const [isItemSelected2, setIsItemSelected2] = useState(false);
  const [destination1, setDestination1] = useState("");
  const [destination2, setDestination2] = useState("");
  const [country, setCountry] = useState("");

  const handleCitySelect = (city) => {
    setDestinationCity(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode(city["AIRPORTCODE"]);
    SetClickDestination(false); // Close the city suggestion div
    setSearchInput(`${city["CITYNAME"]} (${city["CITYCODE"]})`); // Clear the search input after selecting a city
    setCountry(`${city["CITYCODE"]} (${city["AIRPORTNAME"]})`);
    setIsItemSelected(true);
    setDestination1(city);
  };
  const [country1, setCountry1] = useState("");
  const [destinationCity2, setDestinationCity2] = useState("");
  const [selectedCityCode2, setSelectedCityCode2] = useState("");
  const handleCitySelect2 = (city) => {
    setDestinationCity2(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode2(city["AIRPORTCODE"]);
    SetClickDestination2(false); // Close the city suggestion div
    setSearchInput2(`${city["CITYNAME"]} (${city["CITYCODE"]})`); // Clear the search input after selecting a city
    setCountry1(`${city["CITYCODE"]} (${city["AIRPORTNAME"]})`);
    setIsItemSelected2(true);
    setDestination2(city);
  };

  // console.log(
  //   selectedCityCode,
  //   destinationCity,
  //   selectedCityCode2,
  //   destinationCity2
  // );

  // const handleInputChange = (event) => {
  //   const userInput = event.target.value.toLowerCase();
  //   setSearchInput(userInput);
  //   setIsItemSelected(false);
  // };

  // const handleInputChange2 = (event) => {
  //   const userInput = event.target.value.toLowerCase();
  //   setSearchInput2(userInput);
  //   setIsItemSelected2(false);
  // };

  const filteredCities =
    cities2 &&
    cities2.filter(
      (city) =>
        city["AIRPORTNAME"].toLowerCase().includes(searchInput) ||
        city["AIRPORTCODE"].toLowerCase().includes(searchInput) ||
        city["CITYCODE"].toLowerCase().includes(searchInput) ||
        city["COUNTRYNAME"].toLowerCase().includes(searchInput) ||
        city["COUNTRYCODE"].toLowerCase().includes(searchInput) ||
        city["CITYNAME"].toLowerCase().includes(searchInput)
    );

  const filteredCities2 =
    cities2 &&
    cities2.filter(
      (city) =>
        city["AIRPORTNAME"].toLowerCase().includes(searchInput2) ||
        city["AIRPORTCODE"].toLowerCase().includes(searchInput2) ||
        city["CITYCODE"].toLowerCase().includes(searchInput2) ||
        city["COUNTRYNAME"].toLowerCase().includes(searchInput2) ||
        city["COUNTRYCODE"].toLowerCase().includes(searchInput2) ||
        city["CITYNAME"].toLowerCase().includes(searchInput2)
    );

  // const [cityData, setCityData] = useState([]);
  // const [searchTerm, setSearchTerm] = useState("");
  // const [searchData, setSearchData] = useState(null);
  // const [loading, setLoading] = useState(false);
  // useEffect(() => {
  //   const cityDataFetch = async () => {
  //     setLoading(true);
  //     const city_cate = await getCityData();
  //     if (city_cate.length > 0) {
  //       setCityData(city_cate);
  //     }
  //     setLoading(false);
  //   };
  //   cityDataFetch();
  // }, []);
  // console.log("cityyyyyyyyyyyyyyyyyyyddddaaaaaaaatttttttaaaaaaaa", cityData);
  // const handleSearchChange = (e) => {
  //   const term = e.target.value;
  //   setSearchTerm(term);

  //   const filterProduct =
  //     cityData &&
  //     cityData.filter(
  //       (cityname) =>
  //         cityname.City_Name.toLowerCase().includes(term.toLowerCase()) ||
  //         cityname.City_Code.toLowerCase().includes(term.toLowerCase()) ||
  //         cityname.Country_Name.toLowerCase().includes(term.toLowerCase())
  //     );
  //   setSearchData(filterProduct);
  // };
  // console.log("serachData", searchData);
  console.log("TRAVEKKKKK ", travellerActive);
  return (
    <div>
      <section id="home_seven_banner">
        <Container>
          <Row>
            <Col lg={10} className="offset-lg-1">
              <div className="banner_seven_text">
                <h1>Embark on Your Next Adventure with Ease</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section id="theme_search_form">
        <Container>
          <div className="theme_search_form_area">
            <Row>
              <Col lg={12}>
                <div className="theme_search_form_tabbtn">
                  <ul className="nav nav-tabs">
                    <li
                      className="nav-item"
                      role="presentation"
                      // onClick={() => setRoundTrip(false)}
                    >
                      <button
                        className={`${active ? "active" : ""} nav-link`}
                        id="oneway-tab"
                        onClick={handleSearchFlight}
                      >
                        One Way
                      </button>
                    </li>
                    <li
                      className="nav-item"
                      role="presentation"
                      onClick={handleSearchFlightRound}
                      // onClick={() => setRoundTrip(true)}
                    >
                      <button
                        className={`${active2 ? "active" : ""} nav-link`}
                        id="roundtrip-tab"
                      >
                        Roundtrip
                      </button>
                    </li>
                    {/* <li className="nav-item" role="presentation">
                      <button className="nav-link" id="multi_city-tab">
                        Multi city
                      </button>
                    </li> */}
                  </ul>
                </div>
              </Col>
            </Row>
            <Row className="tab-content" id="myTabContent">
              <Col className="tab-pane fade show active" id="flights" lg={12}>
                <div className="tab-content" id="myTabContent1">
                  <div className="tab-pane fade show active" id="oneway_flight">
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="oneway_search_form">
                          <Form onSubmit={handleSubmit}>
                            <div className="row">
                              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="flight_Search_boxed">
                                  <p>From</p>
                                  <input
                                    type="text"
                                    name="from"
                                    defaultValue="New York"
                                    placeholder="Enter Destination City"
                                    value={searchInput}
                                    // onFocus={() => SetClickDestination(true)}
                                    // onBlur={() => {
                                    //   if (isItemSelected)
                                    //     SetClickDestination(false);
                                    // }}
                                    onChange={(e) =>
                                      handleInputChange(e.target.value)
                                    }
                                    autoComplete="off"
                                    // value={searchTerm}
                                    // onChange={handleSearchChange}
                                    // autoComplete="off"
                                  />
                                  <span>
                                    {country === ""
                                      ? "City Code : Airport Name"
                                      : country}
                                  </span>
                                  <div className="plan_icon_posation">
                                    <i className="fas fa-plane-departure" />
                                  </div>
                                  {/* <div style={{ position: "relative" }}>
                                    {searchData && searchData.length !== 0 ? (
                                      <div
                                        className="location_search selhide"
                                     
                                        style={{ display: "block" }}
                                      >
                                        <div className="inner_loc_search">
                                          <div className="top_city">
                                            <span>Top Cities</span>
                                          </div>
                                        </div>
                                        {searchData &&
                                          searchData.map((city, index) => (
                                            <ul className="is_search_from_val">
                                              <li className="">
                                                <div className="fli_name">
                                                  <i className="fa fa-plane" />{" "}
                                                  {city.City_Name} (
                                                  {city.City_Code})
                                                </div>
                                                <div className="airport_name">
                                                  {city.Airport_Name}
                                                  <span>
                                                    {city.Country_Name}
                                                  </span>
                                                </div>
                                              </li>
                                            </ul>
                                          ))}
                                      </div>
                                    ) : null}
                                  </div> */}
                                  <div style={{ position: "relative" }}>
                                    <div
                                      className="cityselect"
                                      style={{
                                        position: "absolute",
                                        color: "black",
                                        backgroundColor: "#8B3EEA",
                                        padding: "10px",
                                        border: "1px solid #8B3EEA",
                                        // display:'block',
                                        display:
                                          searchInput && clickDestination
                                            ? "block"
                                            : "none",
                                        width: "113%",
                                        zIndex: 9,
                                        top: "-18px",
                                        right: "-13px",
                                        maxHeight: 300,
                                        // overflow: "auto",
                                        overflowX: "hidden",
                                        overflowY: "auto",
                                        scrollbarWidth: "thin",
                                      }}
                                    >
                                      {cities2.map((city, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            borderBottom: "1px solid grey",
                                            paddingBottom: 5,
                                            marginBottom: 5,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => handleCitySelect(city)}
                                        >
                                          <Row>
                                            <Col
                                              md={1}
                                              style={{ alignItems: "center" }}
                                            >
                                              <MdOutlineFlight
                                                size={22}
                                                style={{
                                                  textAlign: "center",

                                                  height: "100%",
                                                  color: "#fff",
                                                }}
                                              />
                                            </Col>
                                            <Col md={10}>
                                              <Row>
                                                <div
                                                  className="flightFromName"
                                                  style={{
                                                    color: "#fff",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {city["CITYNAME"]} (
                                                  {city["CITYCODE"]})
                                                </div>
                                              </Row>
                                              <Row style={{ color: "grey" }}>
                                                <Col
                                                  md={10}
                                                  style={{ paddingRight: 0 }}
                                                >
                                                  <div
                                                    className="flightFromNameInner"
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 600,
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    {city["AIRPORTNAME"]}
                                                  </div>
                                                </Col>
                                                <Col
                                                  md={2}
                                                  style={{ paddingLeft: 0 }}
                                                >
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "700",
                                                      fontSize: 10,
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    {city["COUNTRYCODE"]}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 col-sm-12 col-12">
                                <div className="flight_Search_boxed">
                                  <p>To</p>
                                  <input
                                    type="text"
                                    defaultValue="London "
                                    placeholder="Enter Destination City"
                                    value={searchInput2}
                                    // onFocus={() => SetClickDestination2(true)}
                                    // onBlur={() => {
                                    //   if (isItemSelected2)
                                    //     SetClickDestination2(false);
                                    // }}
                                    onChange={(e) =>
                                      handleInputChange2(e.target.value)
                                    }
                                    autoComplete="off"
                                  />
                                  <span>
                                    {country1 === ""
                                      ? "City Code : Airport Name"
                                      : country1}{" "}
                                  </span>
                                  <div className="plan_icon_posation">
                                    <i className="fas fa-plane-arrival" />
                                  </div>
                                  <div style={{ position: "relative" }}>
                                    <div
                                      className="cityselect"
                                      style={{
                                        position: "absolute",
                                        color: "black",
                                        backgroundColor: "#8B3EEA",
                                        padding: "10px",
                                        border: "1px solid #8B3EEA",
                                        display:
                                          searchInput2 && clickDestination2
                                            ? "block"
                                            : "none",
                                        width: "113%",
                                        zIndex: 9,
                                        top: "-18px",
                                        right: "-13px",
                                        maxHeight: 300,
                                        overflow: "hidden",
                                      }}
                                    >
                                      {cities22.map((city, index) => (
                                        <div
                                          key={index}
                                          style={{
                                            borderBottom: "1px solid grey",
                                            paddingBottom: 5,
                                            marginBottom: 5,
                                            cursor: "pointer",
                                          }}
                                          onClick={() =>
                                            handleCitySelect2(city)
                                          }
                                        >
                                          <Row>
                                            <Col
                                              md={1}
                                              style={{ alignItems: "center" }}
                                            >
                                              <MdOutlineFlight
                                                size={22}
                                                style={{
                                                  textAlign: "center",
                                                  height: "100%",
                                                  color: "#fff",
                                                }}
                                              />
                                            </Col>
                                            <Col md={10}>
                                              <Row>
                                                <div
                                                  className="flightFromName"
                                                  style={{
                                                    color: "#fff",
                                                    fontWeight: 600,
                                                  }}
                                                >
                                                  {city["CITYNAME"]} (
                                                  {city["CITYCODE"]})
                                                </div>
                                              </Row>
                                              <Row style={{ color: "grey" }}>
                                                <Col
                                                  md={10}
                                                  style={{ paddingRight: 0 }}
                                                >
                                                  <div
                                                    className="flightFromNameInner"
                                                    style={{
                                                      fontSize: 10,
                                                      fontWeight: 600,
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    {city["AIRPORTNAME"]}
                                                  </div>
                                                </Col>
                                                <Col
                                                  md={2}
                                                  style={{ paddingLeft: 0 }}
                                                >
                                                  <div
                                                    style={{
                                                      textAlign: "center",
                                                      fontWeight: "700",
                                                      fontSize: 10,
                                                      color: "#fff",
                                                    }}
                                                  >
                                                    {city["COUNTRYCODE"]}
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  <div className="range_plan">
                                    <i className="fas fa-exchange-alt" />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-4  col-md-6 col-sm-12 col-12">
                                <div className="form_search_date">
                                  <div className="flight_Search_boxed date_flex_area">
                                    <div className="Journey_date">
                                      <p>Journey date</p>
                                      <input
                                        type="text"
                                        name="departureDate"
                                        id="departureDateInput"
                                        className="dateInput flightBookingFromInput"
                                        placeholder="Enter Departure Date"
                                        onChange={handleChange}
                                        value={
                                          startDate
                                            ? startDate.format("MM/DD/YYYY")
                                            : ""
                                        }
                                        readOnly
                                        onClick={toggleCalendar}
                                        autoComplete="off"
                                      />
                                      <span>
                                        {startDate
                                          ? formatTime(startDate)
                                          : "Departure Date"}
                                      </span>
                                      <div
                                        style={{
                                          position: "absolute",
                                          zIndex: "2000",
                                        }}
                                      >
                                        {active2
                                          ? calVisible && (
                                              <DayPickerRangeController
                                                startDate={startDate}
                                                endDate={endDate}
                                                onDatesChange={
                                                  handleDatesChange
                                                }
                                                isOutsideRange={(day) =>
                                                  day.isBefore(moment(), "day")
                                                }
                                                focusedInput={focusedInput}
                                                onFocusChange={(focused) =>
                                                  setFocusedInput(focused)
                                                }
                                                renderDayContents={
                                                  renderDayContents
                                                }
                                                numberOfMonths={numberOfMonths}
                                              />
                                            )
                                          : calVisible && (
                                              <DayPickerRangeController
                                                startDate={startDate}
                                                endDate={null}
                                                onDatesChange={({
                                                  startDate,
                                                }) => {
                                                  setStartDate(startDate);
                                                  setCalVisible(false);
                                                }}
                                                isOutsideRange={(day) =>
                                                  day.isBefore(moment(), "day")
                                                }
                                                focusedInput={focusedInput}
                                                onFocusChange={(focused) => {
                                                  if (focused)
                                                    setFocusedInput(
                                                      "startDate"
                                                    );
                                                }}
                                                renderDayContents={
                                                  renderDayContents
                                                }
                                                numberOfMonths={numberOfMonths}
                                              />
                                            )}
                                      </div>
                                    </div>
                                    {active2 ? (
                                      <div className="Journey_date">
                                        <p>Return date</p>
                                        <input
                                          type="text"
                                          id="returnDateInput"
                                          name="returnDate"
                                          className="dateInput flightBookingFromInput"
                                          placeholder="Enter Return Date"
                                          onChange={handleChange}
                                          value={
                                            endDate
                                              ? endDate.format("MM/DD/YYYY")
                                              : ""
                                          }
                                          readOnly
                                          onClick={() => {
                                            handleSearchFlightRound();
                                            toggleCalendar("endDate");
                                          }}
                                          autoComplete="off"
                                        />
                                        <span>
                                          {endDate
                                            ? formatTime(endDate)
                                            : "Arrival Date"}
                                        </span>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-2  col-md-6 col-sm-12 col-12">
                                <div className="flight_Search_boxed dropdown_passenger_area">
                                  <p onClick={() => handleActive()}>
                                    Passenger{" "}
                                  </p>
                                  <Dropdown
                                    className="dropdown"
                                    onClick={() => handleActive()}
                                  >
                                    <Dropdown.Toggle className="dropdown-toggle final-count">
                                      {`${
                                        rooms[0].adults +
                                        rooms[0].children +
                                        rooms[0].infants
                                      } Passengers`}
                                    </Dropdown.Toggle>

                                    {/* {travellerActive ? ( */}
                                    {travellerActive ? (
                                      <Dropdown.Menu className="dropdown-menu dropdown_passenger_info">
                                        <div className="traveller-calulate-persons">
                                          <div className="passengers">
                                            <h6>Passengers</h6>
                                            <div className="passengers-types">
                                              <div className="passengers-type">
                                                <div className="text">
                                                  <span className="count pcount">
                                                    {rooms[0].adults}
                                                  </span>
                                                  <div className="type-label">
                                                    <p>Adult</p>
                                                    <span>12+ yrs</span>
                                                  </div>
                                                </div>
                                                <div className="button-set">
                                                  <button
                                                    type="button"
                                                    className="btn-add"
                                                    onClick={() =>
                                                      updateRoom(
                                                        0,
                                                        "adults",
                                                        Math.min(
                                                          rooms[0].adults + 1,
                                                          6
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-plus" />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn-subtract"
                                                    onClick={() =>
                                                      updateRoom(
                                                        0,
                                                        "adults",
                                                        Math.max(
                                                          rooms[0].adults - 1,
                                                          0
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-minus" />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="passengers-type">
                                                <div className="text">
                                                  <span className="count ccount">
                                                    {rooms[0].children}{" "}
                                                  </span>
                                                  <div className="type-label">
                                                    <p className="fz14 mb-xs-0">
                                                      Children
                                                    </p>
                                                    <span>
                                                      2 - Less than 12 yrs
                                                    </span>
                                                  </div>
                                                </div>
                                                <div className="button-set">
                                                  <button
                                                    type="button"
                                                    className="btn-add-c"
                                                    onClick={() =>
                                                      updateRoom(
                                                        0,
                                                        "children",
                                                        Math.min(
                                                          rooms[0].children + 1,
                                                          6
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-plus" />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn-subtract-c"
                                                    onClick={() =>
                                                      updateRoom(
                                                        0,
                                                        "children",
                                                        Math.max(
                                                          rooms[0].children - 1,
                                                          0
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-minus" />
                                                  </button>
                                                </div>
                                              </div>
                                              <div className="passengers-type">
                                                <div className="text">
                                                  <span className="count incount">
                                                    {rooms[0].infants}{" "}
                                                  </span>
                                                  <div className="type-label">
                                                    <p className="fz14 mb-xs-0">
                                                      Infant
                                                    </p>
                                                    <span>Less than 2 yrs</span>
                                                  </div>
                                                </div>
                                                <div className="button-set">
                                                  <button
                                                    type="button"
                                                    className="btn-add-in"
                                                    onClick={() =>
                                                      updateRoom(
                                                        0,
                                                        "infants",
                                                        Math.min(
                                                          rooms[0].infants + 1,
                                                          rooms[0].adults
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-plus" />
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="btn-subtract-in"
                                                    onClick={() =>
                                                      updateRoom(
                                                        0,
                                                        "infants",
                                                        Math.max(
                                                          rooms[0].infants - 1,
                                                          0
                                                        )
                                                      )
                                                    }
                                                  >
                                                    <i className="fas fa-minus" />
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="cabin-selection">
                                            <h6>Cabin class</h6>
                                            <div className="cabin-list">
                                              <button
                                                type="button"
                                                // className="label-select-btn"
                                                onClick={() =>
                                                  handleClassSelect("Economy")
                                                }
                                                className={
                                                  selectedClass === "Economy"
                                                    ? "label-select-btn active"
                                                    : "label-select-btn"
                                                }
                                              >
                                                <span className="muiButton-label">
                                                  Economy
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleClassSelect("Business")
                                                }
                                                // className="label-select-btn active"
                                                className={
                                                  selectedClass === "Business"
                                                    ? "label-select-btn active"
                                                    : "label-select-btn"
                                                }
                                              >
                                                <span className="muiButton-label">
                                                  Business
                                                </span>
                                              </button>
                                              <button
                                                type="button"
                                                onClick={() =>
                                                  handleClassSelect("First")
                                                }
                                                className={
                                                  selectedClass === "First"
                                                    ? "label-select-btn active"
                                                    : "label-select-btn"
                                                }
                                              >
                                                <span className="muiButton-label">
                                                  First class{" "}
                                                </span>
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        {/* <div
                                          className="top_form_search_button"
                                          style={{ textAlign: "end" }}
                                          onClick={handletravellerClose}
                                        >
                                          <button
                                            type="button"
                                            className="btn btn_theme btn_md"
                                            style={{
                                              backgroundColor: "#8B3EEA",
                                              color: "#fff",
                                              p0adding: "5px 10px",
                                            }}
                                          >
                                            Done
                                          </button>
                                        </div> */}
                                      </Dropdown.Menu>
                                    ) : null}
                                    {/* ) : ( */}
                                    {/* "" */}
                                    {/* )} */}
                                  </Dropdown>
                                  <span>{selectedClass}</span>
                                </div>
                              </div>
                              <div className="top_form_search_button">
                                {/* <Link to="/agent/flightlists"> */}
                                <button
                                  type="submit"
                                  className="btn btn_theme btn_md"
                                >
                                  Search Flight
                                </button>
                                {/* </Link> */}
                              </div>
                            </div>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
      <FlightDeal />
    </div>
  );
};

export default HomeDash;
