import React, { useState, useEffect } from 'react';

function generateRandomId(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const specialCharacters = '!@#$%^&*';
  const charactersLength = characters.length;
  const specialLength = specialCharacters.length;
  let result = 'Tdsj';

  // Add mandatory sequence '!@#$%^&*'
  result += specialCharacters.charAt(Math.floor(Math.random() * specialLength));
  result += specialCharacters.charAt(Math.floor(Math.random() * specialLength));
  result += specialCharacters.charAt(Math.floor(Math.random() * specialLength));

  // Add random characters
  for (let i = 0; i < length - 3; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  // Shuffle the result to randomize the order
  result = shuffleString(result);

  return result;
}

// Function to shuffle a string (Fisher-Yates shuffle)
function shuffleString(str) {
  let array = str.split('');
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array.join('');
}

function RandomIdGenerator({ onIdGenerated }) {
  const [randomId, setRandomId] = useState('');

  useEffect(() => {
    generateNewRandomId();
  }, []);

  const generateNewRandomId = () => {
    const newRandomId = generateRandomId(10); // Adj  ust the length as needed
    setRandomId(newRandomId);
    if (typeof onIdGenerated === 'function') {
      onIdGenerated(newRandomId);
    }
  };

  return (
    <div>
      <div>Random ID: {randomId}</div>
    </div>
  );
}

export default RandomIdGenerator;
