import React, { useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { FaAngleDown, FaSyncAlt } from "react-icons/fa";
import { HiMiniMinusSmall } from "react-icons/hi2";
import { Link, useNavigate } from "react-router-dom";
import FlightDetailSide from "../../../FlightDetails/FlightDetailSide";
import FlightPayDetail from "../../../FlightDetails/FlightPayDetail";

const PriceDetail = ({
  flight,
  setShowdetail,
  showdetail,
  handleChangeCurrency,
  walletData,
  setOpenPayBtn,
  adminIm,
  agentIm,
  walletDatas,
  openPayBtn,
  internationalMarkup,
  passengerSeatPreferences,
  passengerMealPreferences,
  passengerBaggagePreferences,
  validatePassengerData,
}) => {
  //   let totalMealCharges = passengerMealPreferences.reduce((total, meal) => {
  //     if (meal && meal.Price) {
  //       total += meal.Price;
  //     }
  //     return total;
  //   }, 0);
  //   let totalMealWithCharges =
  //     flight && flight.AirlineFare.TotalMealCharges + totalMealCharges;

  //   let totalSeatCharges = passengerSeatPreferences.reduce((total, meal) => {
  //     if (meal && meal.Price) {
  //       total += meal.Price;
  //     }
  //     return total;
  //   }, 0);
  //   let totalSeatWithCharges =
  //     flight && flight.Fare.TotalSeatCharges + totalSeatCharges;

  //   let totalBaggageCharges = passengerBaggagePreferences.reduce(
  //     (total, meal) => {
  //       if (meal && meal.Price) {
  //         total += meal.Price;
  //       }
  //       return total;
  //     },
  //     0
  //   );
  //   let totalBaggageWithCharges =
  //     flight && flight.Fare.TotalBaggageCharges + totalBaggageCharges;

  //   const handleFareRecheck = () => {
  //     console.log("fare recheck clicked");
  //   };

  const navigate = useNavigate();
  const [showData, setShowData] = useState(false);
  return (
    <Col md={3}>
      <Row>
        {/* <Col md={12} style={{}}>
            <p
              style={{
                marginBottom: "10px",
                float: "right",
                display: "flex",
                alignItems: "center",
                boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px",
                padding: "5px",
              }}
              onClick={() => handleFareRecheck()}
            >
              {" "}
              Fare Recheck <FaSyncAlt style={{ marginLeft: "5px" }} />
            </p>
          </Col> */}
        <Col md={12}>
          {flight ? (
            <div className="flightBookingsecDiv">
              <Row className="flightBookingSecDivRow3">
                <Col xs={12} md={12}>
                  <span
                    className="lbl"
                    onClick={() => setShowdetail(!showdetail)}
                  >
                    Base Fare (
                    {flight[0].PexFareDetails.reduce(
                      (total, item) => total + item.TotPax,
                      0
                    )}{" "}
                    Travellers)
                  </span>{" "}
                  <FaAngleDown onClick={() => setShowdetail(!showdetail)} />{" "}
                  <span className="val">
                    {/* {flight.Fare.Currency}  */}₹
                    {flight[0].AirlineFare.BaseFare}
                  </span>
                </Col>
              </Row>
              {/* {showdetail && (
                  <Row
                    className="flightBookingSecDivRow3"
                    style={{ width: "90%", marginInline: "auto" }}
                  >
                    <Col xs={12} md={12}>
                      <span className="lbl">
                        {data.PexFareDetails[0] && data.PexFareDetails[0].TotPax} x Adult
                      </span>{" "}
                      <span className="val">
                      ₹
                        {data.PexFareDetail[0].BaseFare}
                      </span>
                    </Col>
                    {data.PexFareDetail[1] && (
                      <Col xs={12} md={12}>
                        <span className="lbl">
                          {data.PexFareDetail[1].TotPax} x Children
                        </span>{" "}
                        <span className="val">
                         ₹
                          {data.PexFareDetail[1].BaseFare}
                        </span>
                      </Col>
                    )}
                    {data.PexFareDetails[2] && (
                      <Col xs={12} md={12}>
                        <span className="lbl">
                          {data.PexFareDetails[2].TotPax} x Infants
                        </span>{" "}
                        <span className="val">
                       ₹
                          {data.PexFareDetails[2].BaseFare}
                        </span>
                      </Col>
                    )}
                  </Row>
                )} */}
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Taxes & Surcharges</span> -{" "}
                  <span className="val">₹{flight[0].AirlineFare.TaxFare}</span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Discount</span> -{" "}
                  <span className="val">₹{flight[0].AirlineFare.Discount}</span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Service Fee</span> -{" "}
                  <span className="val">
                    ₹{flight[0].AirlineFare.ServiceCharge}
                  </span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Excess Baggage (0KG )</span> -{" "}
                  <span className="val">₹{0}</span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Meal (0)</span> -{" "}
                  <span className="val">₹{0}</span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Seat Charges</span> -{" "}
                  <span className="val">₹{0}</span>
                </Col>
              </Row>
              <Row
                style={{
                  borderBottom: "1px solid #e4e4e4",
                  paddingBlock: "8px",
                }}
              >
                <Col xs={12} md={12}>
                  <span className="lbl">Other Charges</span> -{" "}
                  <span className="val">₹{agentIm + adminIm + agentIm + adminIm}</span>
                </Col>
              </Row>
              <Row
                className="flightBookingSecDivRow2"
                style={{ paddingBlock: "8px" }}
              >
                <Col xs={12} md={12} style={{ fontSize: "12px" }}>
                  <span className="lbl">Total Fare</span> -{" "}
                  <span className="val">
                    ₹{flight[0].AirlineFare.GrossFare + agentIm + adminIm + agentIm + adminIm}
                  </span>
                </Col>
              </Row>
              <Row className="flightBookingsecDivRow">
                <Col xs={12} md={12}>
                  <span className="lbl">You Pay:</span> -{" "}
                  <span className="val">
                    ₹{flight[0].AirlineFare.GrossFare + agentIm + adminIm + agentIm + adminIm}
                  </span>
                </Col>
              </Row>
            </div>
          ) : (
            <FlightDetailSide />
          )}
        </Col>
        {/* <Col md={12} className="resp-mt-20">
            <Card
              className=" "
              style={{ marginBottom: "5px", marginTop: "10px" }}
            >
              <Card.Body style={{ padding: "0px" }}>
                <h2
                  className="boxheading walletMainDiv"
                  style={{
                    background: "#f4f4f4",
                    color: "black",
                    paddingInline: "15px",
                    textAlign: "center",
                    marginBottom: "0px",
                  }}
                >
                  Fare has been reduced, Wanna Search Again?
                </h2>
                <div className="dashinnerboxs">
                  <div
                 
                    onClick={() => {
                     
                      navigate(-1);
                    }}
                  >
                    {" "}
                    <button className="ewalletbtn">Search Again</button>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col> */}
      </Row>
      {flight ? (
        <div>
          <Row style={{ marginTop: "15px" }}>
            {walletDatas &&
           walletDatas || `${walletData.CreditLimit}` >
              flight[0].AirlineFare.GrossFare ? (
              //   totalBaggageWithCharges +
              //   totalMealWithCharges +
              //   totalSeatWithCharges
              <Col md={12} className="resp-mt-20">
                <Card className="dashboardbox " style={{ marginBottom: "5px" }}>
                  <Card.Body style={{ padding: "0px" }}>
                  <div style={{ display: "flex", width: "100%" }}>
                      <h2
                        className="boxheading walletMainDiv"
                        style={{
                          background: "#f4f4f4",
                          color: "black",
                          paddingInline: "15px",
                        }}
                      >
                        Pay using eWallet
                      </h2>

                      <h2
                        style={{
                          color: "black",
                          paddingInline: "10px",
                          paddingTop: "10px",
                          fontSize: "10px",
                          textAlign: "end",
                          cursor: "pointer",
                          width:'35%'
                        }}
                        onClick={() => setShowData(!showData)}
                      >
                        NetFare
                      </h2>
                    </div>
                    <div className="dashinnerboxs">
                      <div
                        className="ewalletMainDiv"
                        style={{ borderBottom: "1px solid #e4e4e4" }}
                      >
                        <p>Current Balance </p>
                        <p>₹{walletDatas}</p>
                      </div>
                      <div
                        className="ewalletMainDiv"
                        style={{ borderBottom: "1px solid #e4e4e4" }}
                      >
                        <p>Flight Amount </p>
                        <p>
                          ₹
                          {/* {
                              data.AirlineFare.NetFare -
                                data.AirlineFare.Discount +
                                data.AirlineFare.ServiceCharge 
                              
                            } */}
                          {flight[0].AirlineFare.GrossFare + agentIm + adminIm + agentIm + adminIm}
                        </p>
                      </div>
                      {showData && (
                        <div
                          className="ewalletMainDiv"
                          style={{ borderBottom: "1px solid #e4e4e4" }}
                        >
                          <p> Amount to Deduct</p>
                          <p>- ₹  {flight[0].AirlineFare.NetFare + agentIm + adminIm + agentIm + adminIm}</p>
                        </div>
                      )}
                      {/* <div
                        className="ewalletMainDiv"
                        style={{ borderBottom: "1px solid #e4e4e4" }}
                      >
                        <p>Amount to deduct </p>
                        <p>
                          <HiMiniMinusSmall />₹
                        
                          {flight[0].AirlineFare.NetFare + agentIm + adminIm + agentIm + adminIm}
                        </p>
                      </div>
                       */}
                      <div
                        // style={{ width: "100%" }}
                        onClick={() => {
                          if (validatePassengerData()) {
                            setOpenPayBtn(true);
                            // setPaymentGateway(true);
                          }
                        }}
                      >
                        {" "}
                        <button className="ewalletbtn">Pay Now</button>
                      </div>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            ) : (
              <Col md={12} className="resp-mt-20">
                <Card className="dashboardbox " style={{ marginBottom: "5px" }}>
                  <Card.Body style={{ padding: "0px" }}>
                    <h2 className="boxheading walletMainDiv">
                      Pay using eWallet
                    </h2>
                    <div className="dashinnerboxs">
                      <div className="ewalletMainDiv">
                        <p>Current Balance </p>
                        <div style={{ width: "45px" }}>
                          <p>
                            ₹{walletData && walletData.Wallet}
                            <div
                              style={{
                                fontSize: "11px",
                                marginLeft: "-74px",
                                fontWeight: "500",
                                color: "red",
                              }}
                            >
                              (Insufficient Balance)
                            </div>
                          </p>
                        </div>
                      </div>
                      <div className="ewalletMainDiv">
                        <p>Flight Amount </p>
                        <p>
                          ₹
                          {
                            flight[0].AirlineFare.GrossFare -
                              flight[0].AirlineFare.Discount +
                              flight[0].AirlineFare.ServiceCharge
                            //   +
                            //   totalBaggageWithCharges +
                            //   totalMealWithCharges +
                            //   totalSeatWithCharges
                          }
                        </p>
                      </div>
                      <Link style={{ width: "100%" }} to="/agent/wallet">
                        <button className="ewalletbtn">Top Up Request</button>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            )}
          </Row>
        </div>
      ) : (
        <FlightPayDetail />
      )}
    </Col>
  );
};
export default PriceDetail;
