import React, { useEffect, useState } from "react";
// import styles from "./FlightBookingDetail.module.css";
// import { Container, Col, Row } from "react-bootstrap";
import "./FlightDetail.css";
// import FlightDetailSkeleton from "./FlightDetailSkeleton";
// import FlightDetailSide from "./FlightDetailSide";

// import FlightPayModal from "./FlightPayModal";
// import { MdFlightLand } from "react-icons/md";
import { FaAngleDown, FaRegThumbsUp } from "react-icons/fa";
import { Link, useLocation, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Row,
  Col,
  Form,
  InputGroup,
  FormControl,
  Nav,
  Modal,
  Tab,
  Card,
} from "react-bootstrap";
import { HiPlusSmall } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { HiMiniMinusSmall } from "react-icons/hi2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import DepatureDetail from "./DepartureDetail";
import FlightDetailSkeleton from "./FlightDetailSkeleton";
import GoodToKnow from "./GoodToKnow";
import FareRule from "./FareRule";
import { cities12 } from "../../Cities";
import ChargesOneWay from "./ChargesOneWay";
import TravellerInformation from "./TravellerInformation";
import BDSend from "./BDSend";
import FlightPayModal from "./FlightPayModal";
import ChargesRound from "./ChargesRound";
import SSRContent from "./SSRContent";
import { Markup } from "../../redux/services/operations/markup";
// import GoodToKnow from "./GoodToKnow";
// import FlightPayDetail from "./FlightPayDetail";
// import FareRule from "./FareRule";
// import SSRContent from "./SSRContent";
// import ChargesOneWay from "./ChargesOneWay";
// import BDSend from "./BDSend";
// import TravellerInformation from "./TravellerInformation";
// import EmiModal from "./EmiModal";

const FlightDetail = () => {
  const [walletDatas, setWalletDatas] = useState(null);
  useEffect(() => {
    const data = localStorage.getItem("walletBalance");
    if (data) {
      setWalletDatas(data);
    }
  }, []);
  const [openPayBtn, setOpenPayBtn] = useState(false);
  // const { index } = useParams();
  const decodedIndex = decodeURIComponent(useParams().index);
  const decodedIndex2 = decodeURIComponent(useParams().index2);
  const [flight, setFlight] = useState(null);
  const [fareRule, setFareRule] = useState(null);
  const [ssrResponse, setSsrResponse] = useState(null);
  const [flight2, setFlight2] = useState(null);
  const [fareRule2, setFareRule2] = useState(null);
  const [ssrResponse2, setSsrResponse2] = useState(null);
  const [emiBtn, setEmiBtn] = useState(false);
  const search = useSelector((state) => state.flight.search);
  // const { traceId } = useSelector((state) => state.flight);
  // const { token } = useSelector((state) => state.auth);
  const [passengerBaggagePreferences, setPassengerBaggagePreferences] =
    useState([]);
  const [passengerSeatPreferences, setPassengerSeatPreferences] = useState([]);
  const [passengerMealPreferences, setPassengerMealPreferences] = useState([]);
  const [token, setToken] = useState();
  const [bookingKey, setBookingKey] = useState();
  const [fareActive, setFareActive] = useState(true);

  const parseFareRule = (fareRuleDetail) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = fareRuleDetail;
    const listItems = Array.from(tempDiv.querySelectorAll("li")).map((li) =>
      li.textContent.trim()
    );

    return listItems;
  };
  const storedBookingKey = String(sessionStorage.getItem("bookingId"));
  const storedToken = String(localStorage.getItem("token"));
  const [tokenss, setTokenss] = useState(storedToken);
  const [bookingKeyss, setBookingKeyss] = useState(storedBookingKey);
  const dispatch = useDispatch();
  useEffect(() => {
    const storedBookingKey = String(sessionStorage.getItem("bookingId"));
    const storedToken = String(localStorage.getItem("token"));

    if (storedBookingKey && storedToken) {
      setBookingKey(storedBookingKey);
      setToken(storedToken);
      // console.log("token", storedToken);
      // console.log("traceId", storedTraceId);
    } else {
      setBookingKey(undefined);
      setToken(undefined);
    }
    console.log("TOKENNNN", token);
    console.log("bookinggggg", bookingKey);
    console.log("DECODE!", decodedIndex);
    console.log("DECODE@", decodedIndex2);

    const fetchData = async () => {
      try {
        const response1 = await axios.post(
          "https://admin.yatriservice.com/api/flightfarerule",
          {
            APIToken: token || tokenss,
            BookingKey: bookingKey || bookingKeyss,
            ContractId: decodedIndex,
          }
        );
        //   console.log("FareRule1 and FareQuote1 Request", {
        //     EndUserIp: "192.168.11.58",
        //     TokenId: token,
        //     TraceId: traceId,
        //     ResultIndex: decodedIndex,
        //   });
        //   let response12;
        // console.log("decodedIndex2", decodedIndex2);
        // console.log("Type of decodedIndex2:", typeof decodedIndex2);

        // if (decodedIndex2 !== "" && decodedIndex2 !== "undefined") {
        // console.log("decodedIndex2 before api call", decodedIndex2);
        // console.log(
        //   "Type of decodedIndex2 before api call:",
        //   typeof decodedIndex2
        // );

        // response12 = await axios.post(
        //   "https://admin.yatriservice.com/api/flight-fare-rule",
        //   {
        //     EndUserIp: "192.168.11.58",
        //     TokenId: token,
        //     TraceId: traceId,
        //     ResultIndex: decodedIndex2,
        //   }
        // );
        // console.log("FareRule2 and FareQuote2 Request", {
        //   EndUserIp: "192.168.11.58",
        //   TokenId: token,
        //   TraceId: traceId,
        //   ResultIndex: decodedIndex2,
        // });
        // console.log("decodedIndex2 after api call", decodedIndex2);
        // console.log(
        //   "Type of decodedIndex2 after api call:",
        //   typeof decodedIndex2
        // );
        // }
        setFareActive(true);
        const response2 = await axios.post(
          "https://admin.yatriservice.com/api/flightsell",
          {
            APIToken: token || tokenss,
            BookingKey: bookingKey || bookingKeyss,
            ContractId:
              decodedIndex2 !== "" && decodedIndex2 !== "undefined"
                ? `${parseInt(decodedIndex)}^${parseInt(decodedIndex2)}`
                : decodedIndex,
          }
        );
        if (response2.data.success) {
          setFareActive(false);
        }
        // let response22;
        // if (decodedIndex2 !== "" && decodedIndex2 !== "undefined") {
        //   response22 = await axios.post(
        //     "https://admin.yatriservice.com/api/flightsell",
        //     {
        //       APIToken: token,
        //       BookingKey: bookingKey,
        //       ContractId: `${parseInt(decodedIndex2)}^${parseInt(decodedIndex)}`,
        //     }

        //   );

        // }

        if (response2.data.success) {
          const originDestinationOptions =
            response2.data.data.Sell &&
            response2.data.data.Sell.Contracts[0].AirSegments;

          originDestinationOptions &&
            originDestinationOptions.forEach((segment) => {
              const departureCode = segment.Origen;
              const arrivalCode = segment.Destination;

              const departureCity = cities12.find(
                (city) => city.AIRPORTCODE === departureCode
              );
              const arrivalCity = cities12.find(
                (city) => city.AIRPORTCODE === arrivalCode
              );

              segment.DepartureCity = departureCity;
              segment.ArrivalCity = arrivalCity;
            });
          if (decodedIndex2 !== "" && decodedIndex2 !== "undefined") {
            const originDestinationOptions2 =
              response2.data.data.Sell &&
              response2.data.data.Sell.Contracts[1].AirSegments;

            originDestinationOptions2 &&
              originDestinationOptions2.forEach((segment) => {
                const departureCode = segment.Origen;
                const arrivalCode = segment.Destination;

                const departureCity = cities12.find(
                  (city) => city.AIRPORTCODE === departureCode
                );
                const arrivalCity = cities12.find(
                  (city) => city.AIRPORTCODE === arrivalCode
                );

                segment.DepartureCity = departureCity;
                segment.ArrivalCity = arrivalCity;
              });
          }
          setFlight(response2.data.data.Sell);
        }
        console.log("response1", response1);
        console.log("response2", response2);
        // console.log("response12", response12);

        setFlight(response2.data.data.Sell.Contracts[0]);
        setFlight2(response2.data.data.Sell.Contracts[1]);

        setFareRule(
          response1.data.data.FareRule
          // parseFareRule(response1.data.data.FareRules[0].FareRuleDetail)
        );
        // console.log("RESPONSE22", response22);
        // response22 && setFlight2(response22.data.data.Results);

        // response12 &&
        //   setFareRule2(
        //     response12.data.data.FareRules[0] &&
        //       parseFareRule(response12.data.data.FareRules[0].FareRuleDetail)
        //   );

        const newPassengers =
          response2.data.data.Sell &&
          response2.data.data.Sell.Contracts[0].PexFareDetails.flatMap(
            ({ PaxType, TotPax }) => {
              const typeLabel =
                PaxType === 1 ? "Adult" : PaxType === 2 ? "Child" : "Infant";
              return Array.from({ length: TotPax }, (_, index) => ({
                id: index + 1,
                type: typeLabel,
                name: `Passenger ${index + 1}`,
              }));
            }
          );

        setPassengers(newPassengers);
        console.log("newPassenger", newPassengers);
        // if (
        //   !response1.data.success ||
        //   !response2.data.success ||
        //   (decodedIndex2 !== "" &&
        //     decodedIndex2 !== "undefined" &&
        //     (!response12.data.success || !response22.data.success))
        // ) {
        //   navigate("/agent/404");
        // }
        // fetchSSR();
      } catch (error) {
        console.error("Error fetching flight data:", error);
      }
    };

    // const fetchSSR = async () => {
    //   try {
    //     let response3;
    //     response3 = await axios.post(
    //       "https://admin.yatriservice.com/api/flightSsr-lcc",
    //       {
    //         EndUserIp: "192.168.11.58",
    //         TokenId: token,
    //         TraceId: traceId,
    //         ResultIndex: decodedIndex,
    //       }
    //     );

    //     let response32;
    //     if (decodedIndex2 !== "" && decodedIndex2 !== "undefined") {
    //       response32 = await axios.post(
    //         "https://admin.yatriservice.com/api/flightSsr-lcc",
    //         {
    //           EndUserIp: "192.168.11.58",
    //           TokenId: token,
    //           TraceId: traceId,
    //           ResultIndex: decodedIndex2,
    //         }
    //       );
    //     }

    //     console.log("response3", response3);
    //     console.log("response32", response32);

    //     if (response3 && response3.data.success) {
    //       setSsrResponse(response3.data.data);
    //     }

    //     if (response32 && response32.data.success) {
    //       setSsrResponse2(response32.data.data);
    //     }

    //   } catch (error) {
    //     console.error("Error fetching flight data:", error);

    //   }
    // };
    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
    fetchData();
  }, [decodedIndex, token, bookingKey, decodedIndex2]);

  const [domesticMarkup, setDomesticMarkup] = useState(0);
  const InternationalMarkup = parseInt(localStorage.getItem("add-int"));
  useEffect(() => {
    const storedFare = localStorage.getItem("add-dom");

    if (storedFare) {
      let finalPrice =
        storedFare !== undefined && storedFare !== null ? storedFare : 0;
      setDomesticMarkup(parseInt(finalPrice));
    } else {
      setDomesticMarkup(0);
    }
  }, [search]);

  console.log("FLEIWGIBFI", flight);
  console.log("FLEIWGIBFI", flight2);
  console.log("FARE RULE", fareRule);
  const [promoCode, setPromoCode] = useState("");
  const [appliedCode, setAppliedCode] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const handleApply = () => {
    // Apply logic here
    setAppliedCode(promoCode);
    setSuccessMessage("Promo code applied successfully!");
    setPromoCode("");
  };

  const handleClear = () => {
    // Clear logic here
    setAppliedCode("");
    setSuccessMessage("");
  };

  const navigate = useNavigate();
  const handlePayment = () => {
    navigate("/agent/flight-ticket");
  };

  const formatTime = (arrTime) => {
    const date = new Date(arrTime);
    const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const day = days[date.getDay()];
    const dateNum = String(date.getDate()).padStart(2, "0");
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${dateNum} ${month} ${year}`;
  };

  const [showdetail, setShowdetail] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [passengers, setPassengers] = useState([]);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const [formData, setFormData] = useState([]);
  const [childData, setChildData] = useState([]);
  const [infant, setInfant] = useState([]);

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");
    const list = [...formData];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setFormData(list);
    console.log("adultdata", formData);
  };

  const handleInputChange1 = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");

    const list = [...childData];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setChildData(list);
  };
  const handleInputChange2 = (index, e) => {
    const { name, value } = e.target;
    const cleanedName = name.replace(/\d+/g, "");

    const list = [...infant];
    if (!list[index]) {
      list[index] = {};
    }
    list[index][cleanedName] = value;
    setInfant(list);
  };
  const isValidExpiryDate = (index, date) => {
    const flightArrTime = !flight2
      ? new Date(
          flight.AirSegments[flight.AirSegments.length - 1].ArrivalDateTime
        )
      : new Date(
          flight2.AirSegments[flight2.AirSegments.length - 1].ArrivalDateTime
        );

    const expiryDate = new Date(date);

    const sixMonthsAfterArrTime = new Date(
      flightArrTime.setMonth(flightArrTime.getMonth() + 6)
    );

    return expiryDate >= sixMonthsAfterArrTime;
  };

  const handleExpiryDateChange = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange(index, e);
    }
  };

  const handleExpiryDateChange1 = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange1(index, e);
    }
  };

  const handleExpiryDateChange2 = (index, e) => {
    const { value } = e.target;
    if (!isValidExpiryDate(index, value)) {
      alert("The expiry date must be at least 6 months after the flight date.");
    } else {
      handleInputChange2(index, e);
    }
  };

  const isValidDateOfBirth = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 12;
  };

  const isValidDateOfBirth1 = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age >= 2 && age < 12;
  };

  const isValidDateOfBirth2 = (date) => {
    const currentDate = new Date();
    const dob = new Date(date);
    let age = currentDate.getFullYear() - dob.getFullYear();
    const monthDiff = currentDate.getMonth() - dob.getMonth();

    if (
      monthDiff < 0 ||
      (monthDiff === 0 && currentDate.getDate() < dob.getDate())
    ) {
      age--;
    }

    return age < 2;
  };

  const handleDOBChange = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth(value)) {
      alert("The age must be greater than 12 years.");
    } else {
      handleInputChange(index, e);
    }
  };

  const handleDOBChange1 = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth1(value)) {
      alert("The age must be between 2 to 12 years.");
    } else {
      handleInputChange1(index, e);
    }
  };

  const handleDOBChange2 = (index, e) => {
    const { value } = e.target;
    if (!isValidDateOfBirth2(value)) {
      alert("The age must be less than 2 years.");
    } else {
      handleInputChange2(index, e);
    }
  };
  // console.log(formData);
  // console.log(childData);
  // console.log(infant);
  const [isinternational, setIsInternational] = useState(false);
  useEffect(() => {
    if (flight) {
      // Extract the origin and destination airport codes
      const originAirportCode = flight.AirSegments[0].Origen;
      const destinationAirportCode =
        flight.AirSegments[flight.AirSegments.length - 1].Destination;

      // Find the corresponding city objects from cities12 array
      const originCity = cities12.find(
        (city) => city.AIRPORTCODE === originAirportCode
      );
      const destinationCity = cities12.find(
        (city) => city.AIRPORTCODE === destinationAirportCode
      );

      // Check if both city objects are found and compare their COUNTRYCODE
      if (
        originCity &&
        destinationCity &&
        originCity.COUNTRYCODE !== destinationCity.COUNTRYCODE
      ) {
        setIsInternational(true);
      }
    }
  }, [flight]);
  const { adminDm, adminIm, agentDm, agentIm } = useSelector(
    (state) => state.markUp
  );

  const markup = isinternational ? adminIm : adminDm;
  const agentmarkup = isinternational ? agentIm : agentDm;

  const handleTicketBook = async () => {
    function generateUUID() {
      const timestamp = new Date().getTime().toString(16); // Convert timestamp to hexadecimal
      const randomChars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let randomString = "";

      for (let i = 0; i < 8; i++) {
        randomString += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }

      return `${timestamp}-${randomString}`;
    }

    let isFirstAdult = true;

    const adultPassengers = formData.map((data, index) => {
      const isLeadPax = isFirstAdult;
      isFirstAdult = false;
      return {
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 1,
        DateOfBirth: data.date,
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || null,
        PassportExpiry: data.expirydate,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare: flight.PexFareDetails[0].BaseFare * 1.0 + 0.0,
          TaxFare: flight.PexFareDetails[0].TaxFare * 1.0,

          YQTax: flight.PexFareDetails[0].YQTax * 1.0,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengers = (passenger) => {
      if (flight.PexFareDetails[0].TotPax > 1) {
        const divisor = flight.PexFareDetails[0].TotPax;
        const adjustedPaxFare = {
          BaseFare: flight.PexFareDetails[0].BaseFare / divisor,
          TaxFare: flight.PexFareDetails[0].TaxFare / divisor,

          YQTax: flight.PexFareDetails[0].YQTax / divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };

    let isChildAdult = true;
    const childPassengers = childData.map((data) => {
      const isLeadPax = isChildAdult;
      isChildAdult = false;
      return {
        Title:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 2,
        DateOfBirth: data.date,
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || null,
        PassportExpiry: data.expirydate || null,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare: flight.PexFareDetails[1].BaseFare * 1.0,
          TaxFare: flight.PexFareDetails[1].TaxFare * 1.0,

          YQTax: flight.PexFareDetails[1].YQTax * 1.0,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengersChild = (passenger) => {
      if (flight.PexFareDetails[1].TotPax > 1) {
        const divisor = flight.PexFareDetails[1].TotPax;
        const adjustedPaxFare = {
          BaseFare: flight.PexFareDetails[1].BaseFare / divisor,
          TaxFare: flight.PexFareDetails[1].TaxFare / divisor,

          YQTax: flight.PexFareDetails[1].YQTax / divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };

    let isInfantAdult = true;
    const infantPassengers = infant.map((data) => {
      const isLeadPax = isInfantAdult;
      isInfantAdult = false;
      return {
        Title:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 3,
        DateOfBirth: data.date,
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport,
        PassportExpiry: data.expirydate,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare: flight.PexFareDetails[2].BaseFare * 1.0,
          TaxFare: flight.PexFareDetails[2].TaxFare * 1.0,

          YQTax: flight.PexFareDetails[2].YQTax * 1.0,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengersInfant = (passenger) => {
      if (flight.PexFareDetails[2].TotPax > 1) {
        const divisor = flight.PexFareDetails[2].TotPax;
        const adjustedPaxFare = {
          BaseFare: flight.PexFareDetails[2].BaseFare / divisor,
          TaxFare: flight.PexFareDetails[2].TaxFare / divisor,

          YQTax: flight.PexFareDetails[2].YQTax / divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };

    let preferenceIndex = 0; // Initialize a counter for the preference index

    const allPassengers = [
      ...adultPassengers.map((passenger) => {
        const adjustedPassenger = adjustPaxFareForMultiplePassengers(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),
      // ...adultPassengers.map((passenger) => {
      //   const mealPreference = passengerMealPreferences[preferenceIndex]
      //     ? [passengerMealPreferences[preferenceIndex]]
      //     : null;
      //   const baggagePreference = passengerBaggagePreferences[preferenceIndex]
      //     ? [passengerBaggagePreferences[preferenceIndex]]
      //     : null;
      //   const seatPreference = passengerSeatPreferences[preferenceIndex]
      //     ? [passengerSeatPreferences[preferenceIndex]]
      //     : null;
      //   preferenceIndex++;
      //   return {

      //   };
      // }),
      ...childPassengers.map((passenger) => {
        const adjustedPassenger =
          adjustPaxFareForMultiplePassengersChild(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),

      ...infantPassengers.map((passenger) => {
        const adjustedPassenger =
          adjustPaxFareForMultiplePassengersInfant(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),
    ];
    const deductData = {
      AgentEmail: walletData && walletData.Email,
      FlightAmount:
        decodedIndex2 !== "" && decodedIndex2 !== "undefined"
          ? `${
              flight.AirlineFare.NetFare +
              flight2.AirlineFare.NetFare +
              markup +
              markup +
              agentmarkup +
              agentmarkup
            }`
          : `${flight.AirlineFare.NetFare + markup + agentmarkup}`,
    };
    const requestData = {
      // PreferredCurrency: null,
      // ResultIndex: decodedIndex,
      APIToken: token,
      BookingKey: bookingKey,
      AgentId: walletData && walletData.Id,
      ContractId:
        decodedIndex2 !== "" && decodedIndex2 !== "undefined"
          ? `${parseInt(decodedIndex2)}^${parseInt(decodedIndex)}`
          : decodedIndex,
      Flightpassenger: allPassengers,
      APIGst: null,
      // EndUserIp: "192.168.11.58",

      // TraceId: traceId,
      // GSTCompanyAddress:
      //   "A2, Palam-Dabri Road, Mahavir Enclave, Dwarka, New Delhi, India",
      // GSTCompanyContactNumber: "9015858565",
      // GSTCompanyName: "eWeblink Technology LLP",
      // GSTNumber: "07AAFFE6846H1Z9",
      // GSTCompanyEmail: "info@eweblink.net",
      BookingId: generateUUID(),
      IsHoldRequest: false,
      AccountNo: "27",
    };
    console.log("requestData", requestData);

    // Make the API call
    const apiUrl = "https://admin.yatriservice.com/api/flightbooking";
    const deductUrl =
      "https://admin.yatriservice.com/api/deduct-wallet-balance";
    // flight &&
    //   flight.IsLCC &&

    // const data = await axios.post(apiUrl, requestData);
    // if (data.data.success === "true") {
    //   console.log("LCC Flight ticket booked successfully:", data);

    //   const respons = await axios.post(deductUrl, deductData);
    //   if (respons) {
    //     console.log("DEDUCT AMT DATA", respons.data.data.WalletBalalnce);
    //     localStorage.setItem("walletBalance", respons.data.data.WalletBalalnce);
    //   }
    //   sessionStorage.setItem(
    //     "BookingIdd",
    //     parseInt(data.data.data.flightbook.BookingId)
    //   );
    //   sessionStorage.setItem(
    //     "AirlinePnr",
    //     data.data.data.flightbook.AirlinePnr
    //   );
    //   window.location.assign("/agent/flight-ticket");
    // } else {
    //   console.log("LCC ERROR FOUND IN BOOKING",data);
    // }

    try {
      // Make the flight booking API call
      const data = await axios.post(apiUrl, requestData);

      // Handle the booking response
      if (data.data.success === true) {
        console.log("LCC Flight ticket booked successfully:", data);

        // Deduct amount from wallet
        const deductData = {
          AgentEmail: walletData && walletData.Email,
          FlightAmount:
            decodedIndex2 !== "" && decodedIndex2 !== "undefined"
              ? `${
                  flight.AirlineFare.NetFare +
                  flight2.AirlineFare.NetFare +
                  markup +
                  markup
                }`
              : `${flight.AirlineFare.NetFare + markup}`,
        };
        try {
          const response = await axios.post(deductUrl, deductData);
          console.log("DEDUCT AMT DATA", response.data.data.WalletBalalnce);

          // Update wallet balance in local storage
          localStorage.setItem(
            "walletBalance",
            response.data.data.WalletBalalnce
          );
        } catch (deductError) {
          // Handle errors that occur during the wallet deduction process
          console.error(
            "An error occurred while deducting from the wallet:",
            deductError
          );
          // Optionally, you might want to handle rollback or notify the user
        }
        // const respons = await axios.post(deductUrl, deductData);
        // if (respons) {
        //   console.log("DEDUCT AMT DATA", respons);
        //   localStorage.setItem("walletBalance", respons.data.data.WalletBalance);
        // }

        // Save booking information to session storage
        sessionStorage.setItem(
          "BookingIdd",
          parseInt(data.data.data.flightbook.BookingId)
        );
        sessionStorage.setItem(
          "AirlinePnr",
          data.data.data.flightbook.AirlinePnr
        );

        // Redirect to flight ticket page
        window.location.assign("/agent/flight-ticket");
      } else {
        // Log error details if booking fails
        console.log("LCC ERROR FOUND IN BOOKING", data.data.success);
      }
    } catch (error) {
      // Handle unexpected errors
      console.error("An error occurred during the API call:", error);
    }
  };
  const handleTicketBookRound = async () => {
    if (formData === null || formData === "" || formData.length === 0) {
      alert("Please fill the traveller information");
      return; // Exit the function early
    }
    function generateUUIDs() {
      const timestamp = new Date().getTime().toString(16); // Convert timestamp to hexadecimal
      const randomChars =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*";
      let randomString = "";

      for (let i = 0; i < 8; i++) {
        randomString += randomChars.charAt(
          Math.floor(Math.random() * randomChars.length)
        );
      }

      return `${timestamp}-${randomString}`;
    }

    let isFirstAdult = true;

    const adultPassengers = formData.map((data, index) => {
      const isLeadPax = isFirstAdult;
      isFirstAdult = false;
      return {
        Title: data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 1,
        DateOfBirth: "09-07-2006",
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || null,
        PassportExpiry: data.expirydate || null,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare:
            flight.PexFareDetails[0].BaseFare +
            flight2.PexFareDetails[0].BaseFare +
            0.0,
          TaxFare:
            flight.PexFareDetails[0].TaxFare +
            flight2.PexFareDetails[0].TaxFare,

          YQTax:
            flight.PexFareDetails[0].YQTax + flight2.PexFareDetails[0].YQTax,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });

    const adjustPaxFareForMultiplePassengers = (passenger) => {
      if (flight.PexFareDetails[0].TotPax > 1) {
        const divisor = flight.PexFareDetails[0].TotPax;
        const adjustedPaxFare = {
          BaseFare:
            (flight.PexFareDetails[0].BaseFare +
              flight2.PexFareDetails[0].BaseFare) /
            divisor,
          TaxFare:
            (flight.PexFareDetails[0].TaxFare +
              flight2.PexFareDetails[0].TaxFare) /
            divisor,

          YQTax:
            (flight.PexFareDetails[0].YQTax + flight2.PexFareDetails[0].YQTax) /
            divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };
    let isChildAdult = true;
    const childPassengers = childData.map((data) => {
      const isLeadPax = isChildAdult;
      isChildAdult = false;
      return {
        Title:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 2,
        DateOfBirth: "8-07-2016",
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || null,
        PassportExpiry: data.expirydate || null,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare:
            flight.PexFareDetails[1].BaseFare +
            flight2.PexFareDetails[1].BaseFare +
            0.0,
          TaxFare:
            flight.PexFareDetails[1].TaxFare +
            flight2.PexFareDetails[1].TaxFare,

          YQTax:
            flight.PexFareDetails[1].YQTax + flight2.PexFareDetails[1].YQTax,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengersChild = (passenger) => {
      if (flight.PexFareDetails[1].TotPax > 1) {
        const divisor = flight.PexFareDetails[1].TotPax;
        const adjustedPaxFare = {
          BaseFare:
            (flight.PexFareDetails[1].BaseFare +
              flight2.PexFareDetails[1].BaseFare) /
            divisor,
          TaxFare:
            (flight.PexFareDetails[1].TaxFare +
              flight2.PexFareDetails[1].TaxFare) /
            divisor,

          YQTax:
            (flight.PexFareDetails[1].YQTax + flight2.PexFareDetails[1].YQTax) /
            divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };
    let isInfantAdult = true;
    const infantPassengers = infant.map((data) => {
      const isLeadPax = isInfantAdult;
      isInfantAdult = false;
      return {
        Title:
          data.title === "Mr" || data.title === "Mrs" ? "Mstr" : data.title,
        FirstName: data.firstName,
        LastName: data.lastName,
        PaxType: 3,
        DateOfBirth: "17-05-2002",
        Gender: data.title.toLowerCase() === "mr" ? "M" : "F",
        PassportNo: data.passport || null,
        PassportExpiry: data.expirydate || null,
        ContactNo: walletDataa.Phone,
        Email: walletDataa.Email,
        SeatCode: "",
        BaggageCode: "",
        MealCode: "",
        PaxFare: {
          BaseFare:
            flight.PexFareDetails[2].BaseFare +
            flight2.PexFareDetails[2].BaseFare +
            0.0,
          TaxFare:
            flight.PexFareDetails[2].TaxFare +
            flight2.PexFareDetails[2].TaxFare,

          YQTax:
            flight.PexFareDetails[2].YQTax + flight2.PexFareDetails[2].YQTax,
          PriceBaggage: 0.0 * 1.0,
          PriceMeal: 0.0 * 1.0,
          PriceSeat: 0.0 * 1.0,
        },
        IsLeadPax: isLeadPax,
        TicketNumber: null,
      };
    });
    const adjustPaxFareForMultiplePassengersInfant = (passenger) => {
      if (flight.PexFareDetails[2].TotPax > 1) {
        const divisor = flight.PexFareDetails[2].TotPax;
        const adjustedPaxFare = {
          BaseFare:
            (flight.PexFareDetails[2].BaseFare +
              flight2.PexFareDetails[2].BaseFare) /
            divisor,
          TaxFare:
            (flight.PexFareDetails[2].TaxFare +
              flight2.PexFareDetails[2].TaxFare) /
            divisor,

          YQTax:
            (flight.PexFareDetails[2].YQTax + flight2.PexFareDetails[2].YQTax) /
            divisor,
          PriceBaggage: 0.0,
          PriceMeal: 0.0,
          PriceSeat: 0.0,
        };

        return {
          ...passenger,
          PaxFare: adjustedPaxFare,
        };
      } else {
        return passenger;
      }
    };
    let preferenceIndex = 0; // Initialize a counter for the preference index

    const allPassengers = [
      ...adultPassengers.map((passenger) => {
        const adjustedPassenger = adjustPaxFareForMultiplePassengers(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),
      // ...adultPassengers.map((passenger) => {
      //   const mealPreference = passengerMealPreferences[preferenceIndex]
      //     ? [passengerMealPreferences[preferenceIndex]]
      //     : null;
      //   const baggagePreference = passengerBaggagePreferences[preferenceIndex]
      //     ? [passengerBaggagePreferences[preferenceIndex]]
      //     : null;
      //   const seatPreference = passengerSeatPreferences[preferenceIndex]
      //     ? [passengerSeatPreferences[preferenceIndex]]
      //     : null;
      //   preferenceIndex++;
      //   return {
      //     ...passenger,
      //   };
      // }),
      ...childPassengers.map((passenger) => {
        const adjustedPassenger =
          adjustPaxFareForMultiplePassengersChild(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),
      ...infantPassengers.map((passenger) => {
        const adjustedPassenger =
          adjustPaxFareForMultiplePassengersInfant(passenger);
        return {
          ...passenger,
          PaxFare: adjustedPassenger.PaxFare, // Update PaxFare with adjusted values
        };
      }),
    ];

    const requestData = {
      // PreferredCurrency: null,
      // ResultIndex: decodedIndex,
      APIToken: token,
      BookingKey: bookingKey,
      AgentId: walletData && walletData.Id,
      ContractId:
        decodedIndex2 !== "" && decodedIndex2 !== "undefined"
          ? `${parseInt(decodedIndex)}^${parseInt(decodedIndex2)}`
          : decodedIndex,
      Flightpassenger: allPassengers,
      BookingId: generateUUIDs(),
      APIGst: null,
      IsHoldRequest: false,
      AccountNo: "27",
    };
    // const deductData = {
    //   AgentEmail: walletData && walletData.Email,
    //   FlightAmount:
    //     decodedIndex2 !== "" && decodedIndex2 !== "undefined"
    //       ? `${
    //           flight.AirlineFare.NetFare +
    //           flight2.AirlineFare.NetFare +
    //           markup +
    //           markup 
    //         }`
    //       : `${flight.AirlineFare.NetFare + markup }`,
    // };
    console.log("requestData", requestData);

    // Make the API call
    const apiUrl = "https://admin.yatriservice.com/api/flightbooking";
    const deductUrl =
      "https://admin.yatriservice.com/api/deduct-wallet-balance";
    // flight &&
    //   flight.IsLCC &&

    // const data = await axios.post(apiUrl, requestData);
    // if (data.data.success === "true") {
    //   console.log("LCC Flight ticket booked successfully:", data);

    //   const respons = await axios.post(deductUrl, deductData);
    //   if (respons) {
    //     console.log("DEDUCT AMT DATA", respons.data.data.WalletBalalnce);
    //     localStorage.setItem("walletBalance", respons.data.data.WalletBalalnce);
    //   }
    //   sessionStorage.setItem(
    //     "BookingIdd",
    //     parseInt(data.data.data.flightbook.BookingId)
    //   );
    //   sessionStorage.setItem(
    //     "AirlinePnr",
    //     data.data.data.flightbook.AirlinePnr
    //   );
    //   window.location.assign("/agent/flight-ticket");
    // } else {
    //   console.log("LCC ERROR FOUND IN BOOKING",data);
    // }
    try {
      // Post booking data to the API
      const bookingResponse = await axios.post(apiUrl, requestData);
    
      // Check if the booking was successful
      if (bookingResponse.data.success === true) {  // Assume success is a boolean
        console.log("LCC Flight ticket booked successfully:", bookingResponse);
    
        // Prepare deduction data
        const deductData = {
          AgentEmail: walletData && walletData.Email,
          FlightAmount: decodedIndex2 !== "" && decodedIndex2 !== "undefined"
            ? `${flight.AirlineFare.NetFare + flight2.AirlineFare.NetFare + markup + markup}`
            : `${flight.AirlineFare.NetFare + markup}`,
        };
    
        // Post deduction data to the API
        const deductionResponse = await axios.post(deductUrl, deductData);
    
        if (deductionResponse && deductionResponse.data && deductionResponse.data.data) {
          console.log("DEDUCT AMT DATA", deductionResponse.data.data.WalletBalalnce);
    
          // Store wallet balance in local storage
          localStorage.setItem("walletBalance", deductionResponse.data.data.WalletBalalnce);
        } else {
          console.error("Unexpected deduction response format:", deductionResponse);
        }
    
        // Store booking details in session storage
        sessionStorage.setItem("BookingId", parseInt(bookingResponse.data.data.flightbook.BookingId, 10));
        sessionStorage.setItem("AirlinePnr", bookingResponse.data.data.flightbook.AirlinePnr);
    
        // Redirect to the flight ticket page
        window.location.assign("/agent/flight-ticket");
      } else {
        console.log("LCC ERROR FOUND IN BOOKING", bookingResponse);
      }
    } catch (error) {
      // Handle any errors that occurred during the API requests
      console.error("An error occurred:", error);
    }
    
  };
  // const handleTicketBookRound = () => {
  //   let isFirstAdult = true;

  //   const adultPassengers = formData.map((data, index) => {
  //     const isLeadPax = isFirstAdult;
  //     isFirstAdult = false;
  //     return {
  //       Title: data.title,
  //       FirstName: data.firstName,
  //       LastName: data.lastName,
  //       PaxType: 1,
  //       DateOfBirth: "1987-12-06T00:00:00",
  //       Gender: data.title.toLowerCase() === "mr" ? 1 : 2,

  //       AddressLine1: "123, Test",
  //       Fare: {
  //         Currency: flight2.FareBreakdown[0].Currency,
  //         BaseFare: flight2.FareBreakdown[0].BaseFare,
  //         Tax: flight2.FareBreakdown[0].Tax,
  //         TaxBreakup: flight2.FareBreakdown[0].TaxBreakup,
  //         YQTax: flight2.FareBreakdown[0].YQTax,
  //         AdditionalTxnFeeOfrd: flight2.FareBreakdown[0].AdditionalTxnFeeOfrd,
  //         AdditionalTxnFeePub: flight2.FareBreakdown[0].AdditionalTxnFeePub,
  //         PGCharge: flight2.FareBreakdown[0].PGCharge,
  //         OtherCharges: flight2.Fare.OtherCharges,
  //         ChargeBU: flight2.Fare.ChargeBU,
  //         Discount: flight2.Fare.Discount,
  //         PublishedFare: flight2.Fare.PublishedFare,
  //         CommissionEarned: flight2.Fare.CommissionEarned,
  //         PLBEarned: flight2.Fare.PLBEarned,
  //         IncentiveEarned: flight2.Fare.IncentiveEarned,
  //         OfferedFare: flight2.Fare.OfferedFare,
  //         TdsOnCommission: flight2.Fare.TdsOnCommission,
  //         TdsOnPLB: flight2.Fare.TdsOnPLB,
  //         TdsOnIncentive: flight2.Fare.TdsOnIncentive,
  //         ServiceFee: flight2.Fare.ServiceFee,
  //         TotalBaggageCharges: flight2.Fare.TotalBaggageCharges,
  //         TotalMealCharges: flight2.Fare.TotalMealCharges,
  //         TotalSeatCharges: flight2.Fare.TotalSeatCharges,
  //         TotalSpecialServiceCharges: flight2.Fare.TotalSpecialServiceCharges,
  //       },
  //       City: "Gurgaon",
  //       CountryCode: "IN",
  //       CountryName: "India",
  //       Nationality: "IN",
  //       ContactNo: "9879879877",
  //       Email: "harsh@tbtq.in",
  //       IsLeadPax: isLeadPax,

  //       GSTCompanyAddress: "",
  //       GSTCompanyContactNumber: "",
  //       GSTCompanyName: "",
  //       GSTNumber: "",
  //       GSTCompanyEmail: "",
  //     };
  //   });

  //   const childPassengers = childData.map((data) => ({
  //     Title: data.title,
  //     FirstName: data.firstName,
  //     LastName: data.lastName,
  //     PaxType: 2,
  //     DateOfBirth: "2017-12-06T00:00:00",
  //     Gender: data.title.toLowerCase() === "mr" ? 1 : 2,

  //     AddressLine1: "123, Test",
  //     Fare: {
  //       Currency: flight2.FareBreakdown[1].Currency,
  //       BaseFare: flight2.FareBreakdown[1].BaseFare,
  //       Tax: flight2.FareBreakdown[1].Tax,
  //       TaxBreakup: flight2.FareBreakdown[1].TaxBreakup,
  //       YQTax: flight2.FareBreakdown[1].YQTax,
  //       AdditionalTxnFeeOfrd: flight2.FareBreakdown[1].AdditionalTxnFeeOfrd,
  //       AdditionalTxnFeePub: flight2.FareBreakdown[1].AdditionalTxnFeePub,
  //       PGCharge: flight2.FareBreakdown[1].PGCharge,
  //       OtherCharges: flight2.Fare.OtherCharges,
  //       ChargeBU: flight2.Fare.ChargeBU,
  //       Discount: flight2.Fare.Discount,
  //       PublishedFare: flight2.Fare.PublishedFare,
  //       CommissionEarned: flight2.Fare.CommissionEarned,
  //       PLBEarned: flight2.Fare.PLBEarned,
  //       IncentiveEarned: flight2.Fare.IncentiveEarned,
  //       OfferedFare: flight2.Fare.OfferedFare,
  //       TdsOnCommission: flight2.Fare.TdsOnCommission,
  //       TdsOnPLB: flight2.Fare.TdsOnPLB,
  //       TdsOnIncentive: flight2.Fare.TdsOnIncentive,
  //       ServiceFee: flight2.Fare.ServiceFee,
  //       TotalBaggageCharges: flight2.Fare.TotalBaggageCharges,
  //       TotalMealCharges: flight2.Fare.TotalMealCharges,
  //       TotalSeatCharges: flight2.Fare.TotalSeatCharges,
  //       TotalSpecialServiceCharges: flight2.Fare.TotalSpecialServiceCharges,
  //     },
  //     City: "Gurgaon",
  //     CountryCode: "IN",
  //     CountryName: "India",
  //     Nationality: "IN",
  //     ContactNo: "9879879877",
  //     Email: "harsh@tbtq.in",
  //     IsLeadPax: false,

  //     GSTCompanyAddress: "",
  //     GSTCompanyContactNumber: "",
  //     GSTCompanyName: "",
  //     GSTNumber: "",
  //     GSTCompanyEmail: "",
  //   }));

  //   const infantPassengers = infant.map((data) => ({
  //     Title: data.title,
  //     FirstName: data.firstName,
  //     LastName: data.lastName,
  //     PaxType: 3,
  //     DateOfBirth: "2023-12-06T00:00:00",
  //     Gender: data.title.toLowerCase() === "mr" ? 1 : 2,

  //     AddressLine1: "123, Test",
  //     Fare: {
  //       Currency: flight2.FareBreakdown[2].Currency,
  //       BaseFare: flight2.FareBreakdown[2].BaseFare,
  //       Tax: flight2.FareBreakdown[2].Tax,
  //       TaxBreakup: flight2.FareBreakdown[2].TaxBreakup,
  //       YQTax: flight2.FareBreakdown[2].YQTax,
  //       AdditionalTxnFeeOfrd: flight2.FareBreakdown[2].AdditionalTxnFeeOfrd,
  //       AdditionalTxnFeePub: flight2.FareBreakdown[2].AdditionalTxnFeePub,
  //       PGCharge: flight2.FareBreakdown[2].PGCharge,
  //       OtherCharges: flight2.Fare.OtherCharges,
  //       ChargeBU: flight2.Fare.ChargeBU,
  //       Discount: flight2.Fare.Discount,
  //       PublishedFare: flight2.Fare.PublishedFare,
  //       CommissionEarned: flight2.Fare.CommissionEarned,
  //       PLBEarned: flight2.Fare.PLBEarned,
  //       IncentiveEarned: flight2.Fare.IncentiveEarned,
  //       OfferedFare: flight2.Fare.OfferedFare,
  //       TdsOnCommission: flight2.Fare.TdsOnCommission,
  //       TdsOnPLB: flight2.Fare.TdsOnPLB,
  //       TdsOnIncentive: flight2.Fare.TdsOnIncentive,
  //       ServiceFee: flight2.Fare.ServiceFee,
  //       TotalBaggageCharges: flight2.Fare.TotalBaggageCharges,
  //       TotalMealCharges: flight2.Fare.TotalMealCharges,
  //       TotalSeatCharges: flight2.Fare.TotalSeatCharges,
  //       TotalSpecialServiceCharges: flight2.Fare.TotalSpecialServiceCharges,
  //     },
  //     City: "Gurgaon",
  //     CountryCode: "IN",
  //     CountryName: "India",
  //     Nationality: "IN",
  //     ContactNo: "9879879877",
  //     Email: "harsh@tbtq.in",
  //     IsLeadPax: false,

  //     GSTCompanyAddress: "",
  //     GSTCompanyContactNumber: "",
  //     GSTCompanyName: "",
  //     GSTNumber: "",
  //     GSTCompanyEmail: "",
  //   }));

  //   const allPassengers = [
  //     ...adultPassengers,
  //     ...childPassengers,
  //     ...infantPassengers,
  //   ];

  //   const requestData = {
  //     PreferredCurrency: null,
  //     ResultIndex: decodedIndex2,
  //     Passengers: allPassengers,
  //     EndUserIp: "192.168.11.58",
  //     TokenId: token,
  //     TraceId: traceId,
  //     GSTCompanyAddress:
  //       "A2, Palam-Dabri Road, Mahavir Enclave, Dwarka, New Delhi, India",
  //     GSTCompanyContactNumber: "9015858565",
  //     GSTCompanyName: "eWeblink Technology LLP",
  //     GSTNumber: "07AAFFE6846H1Z9",
  //     GSTCompanyEmail: "info@eweblink.net",
  //   };
  //   console.log("requestData2", requestData);

  //   const apiUrl = flight2.IsLCC
  //     ? "https://admin.yatriservice.com/api/flight-ticket"
  //     : "https://admin.yatriservice.com/api/flight-book";

  //   fetch(apiUrl, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //     },
  //     body: JSON.stringify(requestData),
  //   })
  //     .then((response) => {
  //       if (!response.ok) {
  //         navigate("/agent/404");
  //         throw new Error("Network response was not ok");
  //       }
  //       return response.json();
  //     })
  //     .then((data2) => {

  //       console.log("LCC flight2 ticket booked successfully:", data2);
  //       if (!data2.success) {
  //         navigate("/agent/404");
  //       }
  //       sessionStorage.setItem(
  //         "BookingId2",
  //         JSON.stringify(data2.data.Response.BookingId)
  //       );
  //       sessionStorage.setItem("PNR2", JSON.stringify(data2.data.Response.PNR));
  //       sessionStorage.setItem(
  //         "FirstName2",
  //         data2.data.Response.FlightItinerary.Passenger[0].FirstName
  //       );
  //       sessionStorage.setItem(
  //         "LastName2",
  //         data2.data.Response.FlightItinerary.Passenger[0].LastName
  //       );

  //       if (!flight2.IsLCC && data2.success) {

  //         const { PNR, BookingId } = data2.data.Response;

  //         fetch("https://admin.yatriservice.com/api/flight-ticket", {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify({
  //             EndUserIp: "192.168.10.10",
  //             TokenId: token,
  //             TraceId: requestData.TraceId,
  //             BookingId,
  //             PNR,
  //           }),
  //         })
  //           .then((ticketResponse) => ticketResponse.json())
  //           .then((ticketData) => {

  //             console.log("LCC flight2 ticket data:", ticketData);
  //           })
  //           .catch((error) => {

  //             console.error(
  //               "Error while fetching NonLCC flight2 ticket:",
  //               error
  //             );
  //             navigate("/agent/404");
  //           });
  //       }

  //       navigate("/agent/flight-ticket");
  //     })
  //     .catch((error) => {

  //       console.error("Error while booking LCC flight2 ticket:", error);
  //       navigate("/agent/404");
  //     });
  // };
  const { walletData } = useSelector((state) => state.auth);
  const [walletDataa, setWalletDataa] = useState({
    Phone: walletData.Phone,
    Email: walletData.Email,
  });

  const handleDataUpdate = (updatedData) => {
    setWalletDataa((prevData) => ({
      ...prevData,
      ...updatedData,
    }));
  };

  const handleChangeFlight = () => {
    navigate(-1);
  };

  const validatePassengerData = () => {
    // Define the required fields for each passenger type
    const requiredFields = ["title", "firstName", "lastName"];
    // const passportFields = ["passport", "issuedate", "expirydate"];

    // Check if passport details are mandatory
    // const isPassportMandatory =
    //   flight.PricedItinerary.IsPassportIssueDateMandatory ||
    //   flight.PricedItinerary.IsPassportMandatory ||
    //   flight.PricedItinerary.IsPassportNameWithSpace;

    // Helper function to check if an object has all required fields filled
    const isValid = (data, index, type) => {
      for (const field of requiredFields) {
        if (!data[field] || data[field].trim() === "") {
          alert(`Please fill out the ${field} for ${type} ${index + 1}`);
          return false;
        }
      }
      // Check for passport fields if they are mandatory
      // if (isPassportMandatory) {
      //   for (const field of passportFields) {
      //     if (!data[field] || data[field].trim() === "") {
      //       alert(`Please fill out the ${field} for ${type} ${index + 1}`);
      //       return false;
      //     }
      //   }
      // }
      return true;
    };

    // Check if data is present for all passengers
    const adultCount = flight && flight.PexFareDetails[0].TotPax;
    const childCount = flight.PexFareDetails[1]
      ? flight.PexFareDetails[1].TotPax
      : 0;

    const infantCount = flight.PexFareDetails[2]
      ? flight.PexFareDetails[2].TotPax
      : 0;

    // Validate adult data
    if (formData.length !== adultCount) {
      alert(`Please fill out details for all ${adultCount} adults`);
      return false;
    }
    for (let i = 0; i < formData.length; i++) {
      if (!isValid(formData[i], i, "adult")) return false;
    }

    // Validate child data
    if (childData.length !== childCount) {
      alert(`Please fill out details for all ${childCount} children`);
      return false;
    }
    for (let i = 0; i < childData.length; i++) {
      if (!isValid(childData[i], i, "child")) return false;
    }

    // Validate infant data
    if (infant.length !== infantCount) {
      alert(`Please fill out details for all ${infantCount} infants`);
      return false;
    }
    for (let i = 0; i < infant.length; i++) {
      if (!isValid(infant[i], i, "infant")) return false;
    }

    return true;
  };
  console.log("flightttttttttttt", flight);
  console.log("flight1", flight);
  console.log("flight2", flight2);

  console.log("isInternational", isinternational);
  console.log("internationalMarkup", InternationalMarkup);
  return (
    <div className="roundtrippg">
      <div style={{ position: "relative" }}>
        <Container className="flightBookingMainMain">
          <Row>
            <Col md={9}>
              <div className="booking_title">
                <h3> Review Your Booking</h3>
                <Link
                  className="change_flight"
                  onClick={() => handleChangeFlight()}
                >
                  Change Flight
                </Link>
              </div>

              {flight ? (
                <DepatureDetail
                  fareRule={fareRule}
                  flight={flight}
                  type="Departure"
                  fareActive={fareActive}
                  setFareActive={setFareActive}
                />
              ) : (
                <FlightDetailSkeleton />
              )}
              {flight2 && <DepatureDetail flight={flight2} type="Return" />}
              <Row>
                <Col md={12} xs={12}>
                  <div
                    id="TRAVELLER_DETAIL"
                    className="oneCard-element"
                    style={{ marginTop: "20px" }}
                  >
                    <div className="componentContainer ">
                      <div className="paxd">
                        <TravellerInformation
                          flight={flight}
                          handleInputChange={handleInputChange}
                          handleInputChange1={handleInputChange1}
                          handleInputChange2={handleInputChange2}
                          handleExpiryDateChange={handleExpiryDateChange}
                          handleExpiryDateChange1={handleExpiryDateChange1}
                          handleExpiryDateChange2={handleExpiryDateChange2}
                          handleDOBChange={handleDOBChange}
                          handleDOBChange1={handleDOBChange1}
                          handleDOBChange2={handleDOBChange2}
                          isinternational={isinternational}
                        />
                      </div>
                    </div>
                  </div>

                  <div
                    className="componentContainer "
                    style={{ padding: "10px 10px", marginBottom: "20px" }}
                  >
                    <div className="myseatinfo">
                      <div className="booking_title">
                        <h3>Addon Services</h3>
                      </div>
                      <div
                        className="block-content-2 custom_block_content"
                        style={{ border: "none", boxShadow: "none" }}
                      >
                        {flight && (
                          <div
                            className="box-result custom_box_result"
                            style={{ display: "flex" }}
                          >
                            <div
                              className="ssrtabs ssrlists"
                              style={{ maxHeight: "400px" }}
                            >
                              <p className="fw-bold mb-2">Select Passenger</p>
                              <Nav variant="tabs" role="tablist">
                                {passengers.map((passenger, index) => (
                                  <Nav.Item key={index}>
                                    <Nav.Link
                                      id={`passenger-tab${index}`}
                                      eventKey={index}
                                      active={index === activeTab}
                                      onClick={() => handleTabChange(index)}
                                    >
                                      <div className="d-flex">
                                        <span className="asradio"></span>{" "}
                                        <label htmlFor={`rdbSSR_${index}`}>
                                          {passenger.type} {passenger.id}
                                        </label>
                                        <tt id={`adultName_${index}`}>
                                          {/* {passenger.name} */}
                                        </tt>
                                      </div>
                                      <ul
                                        className="ssrtabstabs_itinerary"
                                        style={{ padding: "0px" }}
                                      >
                                        <li style={{ marginRight: 5 }}>
                                          <span className="icon">
                                            <i
                                              className="fa fa-suitcase"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <tt id={`bagdetail_${index}`}>0</tt>{" "}
                                          kg
                                        </li>
                                        <li style={{ marginRight: 5 }}>
                                          <span className="icon">
                                            <i
                                              className="fa fa-cutlery"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <tt id={`mealdetail_${index}`}>0</tt>{" "}
                                          Platter
                                        </li>
                                        <li style={{ marginRight: 5 }}>
                                          <span className="icon">
                                            <i
                                              className="fa fa-chair"
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <tt id={`seatdetail_${index}`}>
                                            {" "}
                                            No Seat
                                          </tt>
                                          <tt
                                            id={`seatdetailinput_${index}`}
                                          ></tt>
                                        </li>
                                      </ul>
                                    </Nav.Link>
                                    {/* </a> */}
                                    {/* </li> */}
                                  </Nav.Item>
                                ))}
                                {/* </ul> */}
                              </Nav>
                            </div>
                            {/* {ssrResponse && ( */}
                            <div className="ssr_content tab-content">
                              {passengers.map((passenger, index) => (
                                <SSRContent
                                  // srdvIdx={srdvIdx}
                                  key={index}
                                  index={index}
                                  passenger={passenger}
                                  passengers={passengers}
                                  activeTab={activeTab}
                                  ssrResponse={ssrResponse}
                                  ssrResponse2={ssrResponse2}
                                  // handleChangeCurrency={handleChangeCurrency}
                                  passengerBaggagePreferences={
                                    passengerBaggagePreferences
                                  }
                                  setPassengerBaggagePreferences={
                                    setPassengerBaggagePreferences
                                  }
                                  passengerMealPreferences={
                                    passengerMealPreferences
                                  }
                                  setPassengerMealPreferences={
                                    setPassengerMealPreferences
                                  }
                                  passengerSeatPreferences={
                                    passengerSeatPreferences
                                  }
                                  setPassengerSeatPreferences={
                                    setPassengerSeatPreferences
                                  }
                                />
                              ))}
                            </div>
                            {/* )} */}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <BDSend
                    walletData={walletDataa}
                    onUpdate={handleDataUpdate}
                  />
                </Col>
              </Row>
            </Col>
            {!flight2 && (
              <ChargesOneWay
                flight={flight}
                setShowdetail={setShowdetail}
                showdetail={showdetail}
                // handleChangeCurrency={handleChangeCurrency}
                walletData={walletData}
                walletDatas={walletDatas}
                setOpenPayBtn={setOpenPayBtn}
                openPayBtn={openPayBtn}
                domesticMarkup={domesticMarkup}
                InternationalMarkup={InternationalMarkup}
                // emiBtn={emiBtn}
                // setEmiBtn={setEmiBtn}
                validatePassengerData={validatePassengerData}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
                isinternational={isinternational}
              />
            )}
            {flight2 && (
              <ChargesRound
                flight={flight}
                flight2={flight2}
                setShowdetail={setShowdetail}
                showdetail={showdetail}
                walletData={walletData}
                isinternational={isinternational}
                domesticMarkup={domesticMarkup}
                InternationalMarkup={InternationalMarkup}
                walletDatas={walletDatas}
                setOpenPayBtn={setOpenPayBtn}
                openPayBtn={openPayBtn}
                validatePassengerData={validatePassengerData}
                passengerSeatPreferences={passengerSeatPreferences}
                passengerMealPreferences={passengerMealPreferences}
                passengerBaggagePreferences={passengerBaggagePreferences}
              />
            )}
          </Row>
        </Container>
        {openPayBtn ? (
          <div
            style={{
              position: "absolute",
              width: "100%",
              top: "0px",
              height: "100%",
              zIndex: "10",
            }}
          >
            <FlightPayModal
              flight={flight}
              flight2={flight2}
              openPayBtn={openPayBtn}
              setOpenPayBtn={setOpenPayBtn}
              formData={formData}
              childData={childData}
              infant={infant}
              walletDatas={walletDatas}
              domesticMarkup={domesticMarkup}
              isinternational={isinternational}
              InternationalMarkup={InternationalMarkup}
              handleTicketBook={handleTicketBook}
              handleTicketBookRound={handleTicketBookRound}
              passengerSeatPreferences={passengerSeatPreferences}
              passengerMealPreferences={passengerMealPreferences}
              passengerBaggagePreferences={passengerBaggagePreferences}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default FlightDetail;
