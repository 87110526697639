import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import "./FlightList.css";
import { MdFlight, MdOutlineFlight } from "react-icons/md";
import leftArrow from "../../OneWay/images/leftArrow.png";
import rightArrow from "../../OneWay/images/rightArrow.png";
import { GiAirplaneDeparture } from "react-icons/gi";
import { ImPriceTags } from "react-icons/im";
import { GiAirplaneArrival } from "react-icons/gi";
import { GoStopwatch } from "react-icons/go";
import { FlightListInfo } from "../../OneWay/FlightListInfo";
import { BsArrowLeftRight } from "react-icons/bs";
import { Container, Row, Col, Card, Button } from "react-bootstrap";
import { FaFilter, FaRupeeSign } from "react-icons/fa";
import { flightSearch } from "../../../../redux/services/operations/flight";
import { useSelector, useDispatch } from "react-redux";
// import FlightListSkeleton from "./FlightListSkeleton";
import { DayPickerRangeController } from "react-dates";
import {
  IoRadioButtonOff,
  IoRadioButtonOffSharp,
  IoRadioButtonOnOutline,
  IoRadioButtonOnSharp,
} from "react-icons/io5";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import { cities, fares } from "../../OneWay/OneWay";
import moment from "moment";
// import Slider from "rc-slider";
import { FaArrowRightArrowLeft } from "react-icons/fa6";
import "rc-slider/assets/index.css";
import { RxCross2 } from "react-icons/rx";
import { FaChevronDown } from "react-icons/fa";
// import { cities2 } from "../BookingSearch/Cities";
// import { cities12 } from "../../../../Cities";
// import FlightListCard from "./FlightListCard";
// import FilterBar from "./FilterBar";
// import ReSearchForm from "./ReSearchForm";
import { cities12 } from "../../../../Cities";
import axios from "axios";
import FlightForm from "../../OneWay/FlightForm";
import OneWaySkeleton from "../../OneWay/OneWaySkeleton";
import FilterBar from "../../RoundTrip/FilterBar";
// import ReSearchForm from "../FlightList/ReSearchForm";
// import FilterBar from "../FlightList/FilterBar";
import InternationalFlightListCard from "./InternationalFlightListCard";
import { Markup } from "../../../../redux/services/operations/markup";
// import FlightListSkeleton from "../FlightList/FlightListSkeleton";

const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}, ${dateNum} ${month} ${year}`;
};
const sliderItems = [
  { date: "Oct 03", price: "7845" },
  { date: "Oct 04", price: "5954" },
  { date: "Oct 05", price: "4155" },
  { date: "Oct 06", price: "5953" },
  { date: "Oct 07", price: "5495" },
  { date: "Oct 08", price: "5953" },
  { date: "Oct 09", price: "4155" },
];

const CalenderSliderContent = styled.div`
  border: 1px solid rgb(228, 228, 228);
  border-right: none;
  border-block: none;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const FlightInternational = () => {

  const fares = {
    0: "Normal",
    1: "Special",
    3: "UserFare",
    4: "CorporateFare",
    5: "CouponFare",
    6: "SMEFare",
    7: "GDSFare",
    8: "Default",
    9: "GoSmart",
    10: "GoSpecial",
    11: "GoSmartCorporate",
    12: "GoMarine",
    13: "GoBusiness",
    14: "GoBusinessCorporat",
    15: "GoRound",
    16: "SpiceSaver",
    17: "SpiceCorp",
    18: "SpiceSME",
    19: "SpiceCoupon",
    20: "GoFlexi",
    21: "SpiceCorporate",
    22: "UKSpecial",
    23: "GoSmartCorporateSp",
    24: "CrpcFare",
    25: "GoSpecialV2",
    26: "AkasaNormal",
    27: "AkasaAV",
    28: "AkasaSpecial",
    29: "AkasaCorporate",
    30: "AkasaCorpSelect",
    31: "EchoPECO",
    32: "EchoPCLS",
    33: "IndgoFlexi",
    34: "IndigoTactial",
    35: "IndigoCoupon",
    36: "VistaFlex",
    37: "AISME",
    38: "STU",
    39: "IXSme",
    40: "VVIP",
    41: "SUP6E",
    42: "AIBUS",
  };
  const { walletData } = useSelector((state) => state.auth);
  const { errors } = useSelector((state) => state.flight);
  const { timer } = useSelector((state) => state.flight);
  console.log("TIMERERERERERERERERERERER", timer);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data: routeParams } = useParams();
  const dispatch = useDispatch();
  const searched = useSelector((state) => state.flight.search);
  const [search, setSearch] = useState([]);
  const restructureSegments = (search) => {
    return searched.map((item) => {
      const onwardSegments = item.AirSegments.filter(
        (segment) => segment.SegmentType === "Onword"
      );
      const returnSegments = item.AirSegments.filter(
        (segment) => segment.SegmentType === "Return"
      );
      return {
        ...item,
        AirSegments: [onwardSegments, returnSegments],
      };
    });
  };
  useEffect(() => {
    if (searched.length > 0) {
      const data = restructureSegments(searched);
      setSearch(data);
      console.log("SERCHED UFFERECT DATA", data);
    }
  }, [searched]);
  const [internationalMarkup, setInternationalMarkup] = useState(0);
  useEffect(() => {
    const storedFare = localStorage.getItem("add-int");

    if (storedFare) {
      let finalPrice =
        storedFare !== undefined && storedFare !== null ? storedFare : 0;
        setInternationalMarkup(parseInt(finalPrice));
    } else {
      setInternationalMarkup(0);
    }
    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
  }, [searched]);
  console.log("SERCHED UFFERECT DATAtertertre", search);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [sortedSearch, setSortedSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [rooms, setRooms] = useState([{ adults: 1, children: 0, infants: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [calVisible, setCalVisible] = useState(false);
  const [clickDestination, SetClickDestination] = useState(false);
  const [clickDestination2, SetClickDestination2] = useState(false);
  const [destinationCity, setDestinationCity] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [destinationCity2, setDestinationCity2] = useState("");
  const [selectedCityCode2, setSelectedCityCode2] = useState("");
  const [travellerActive, setTravellerActive] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [Visible, setVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tripsActive, setTripsActive] = useState(false);
  const [checkedStops, setCheckedStops] = useState([
    "non-stop",
    "1-stop",
    "2-stop",
  ]);
  const [deptimeRange, setdepTimeRange] = useState([0, 0]);
  const [arrtimeRange, setarrTimeRange] = useState([0, 0]);
  const [filteredData, setFilteredData] = useState(
    search.length > 0 ? search : []
  );
  const [airlines, setAirlines] = useState([]);
  const [minFare, setMinFare] = useState(0);
  const [maxFare, setMaxFare] = useState(0);
  const [selectedOption, setSelectedOption] = useState(0);

  // const handleOptionChange = (event) => {
  //   setSelectedOption(parseInt(event.target.value));
  // };

  const handleOptionChange = (val) => {
    setSelectedOption(val);
  };

  useEffect(() => {
    const searchData = parseSearchParams(routeParams);
    setDataSearch(searchData);
    updateRoomsData(searchData);
    updateDates(searchData);
    if (parseInt(searchData.JourneyType) === 0) handleSearchFlight();
    else if (parseInt(searchData.JourneyType) === 1) handleSearchFlightRound();
    else if (parseInt(searchData.JourneyType) === 3)
      handleSearchFlightMultiPle();
    setSearchInput(searchData.Segments[0].Origin);
    setSearchInput2(searchData.Segments[0].Destination);
    // console.log("cabin class flight", searchData.Segments[0].FlightCabinClass)
    setSelectedOption(parseInt(searchData.Segments[0].FlightCabinClass));
    const destCity = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Origin
    );
    const destCity2 = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Destination
    );
    setDestination1(destCity);
    setDestination2(destCity2);
  }, [routeParams]);

  useEffect(() => {
    if (dataSearch) {
      setIsLoading(true);
      const updatedSearchData = { ...dataSearch };
      updatedSearchData.ApiToken = token;
      // updatedSearchData.EndUserIp = "192.168.10.10";
      console.log("Search Data", updatedSearchData);
      const searchDataAgain = {
        // updatedSearchData.ApiToken : token;
        APIToken: updatedSearchData.ApiToken,
        Id: walletData && walletData.Email,
        AdultCount: updatedSearchData.AdultCount,
        ChildCount: updatedSearchData.ChildCount,
        InfantCount: updatedSearchData.InfantCount,
        Routs: [
          {
            Source: updatedSearchData.Segments[0].Origin,
            Destination: updatedSearchData.Segments[0].Destination,
            TravelDate: updatedSearchData.Segments[0].PreferredDepartureTime,
          },
          {
            Source: updatedSearchData.Segments[0].Destination,
            Destination: updatedSearchData.Segments[0].Origin,
            TravelDate: updatedSearchData.Segments[0].PreferredArrivalTime,
          },
        ],
        TripType: updatedSearchData.JourneyType,
        TypeOfClass: updatedSearchData.Segments[0].FlightCabinClass,
      };
      console.log("SearchDataAgainnnnn", searchDataAgain);
      dispatch(flightSearch(searchDataAgain, navigate))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [dataSearch, dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    setBreakpoints();
    return () => window.removeEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sortedSearch.length > 0) {
      setFilteredData(sortedSearch);
    }
  }, [sortedSearch]);

  useEffect(() => {
    if (search) {
      const airlineNames = search.map((flight) => ({
        name: flight.AirSegments[0].AirlineName,
        selected: true,
      }));
      const uniqueAirlines = Array.from(
        new Set(airlineNames.map((airline) => airline.name))
      );
      const uniqueAirlinesWithSelected = uniqueAirlines.map((airlineName) => ({
        name: airlineName,
        selected: true,
      }));
      setAirlines(uniqueAirlinesWithSelected);
      console.log("airlines", uniqueAirlines);
    }
    const sortedSearch =
      search &&
      [...search].sort(
        (a, b) =>
          a.AirlineFares[0].GrossFare +
          a.AirlineFares[1].GrossFare -
          (b.AirlineFares[0].GrossFare + b.AirlineFares[1].GrossFare)
      );
    setSortedSearch(sortedSearch);
    console.log("Sorted Data:", sortedSearch);
  }, [search]);

  useEffect(() => {
    if (search.length > 0) {
      const fares = search.map(
        (flight) =>
          flight.AirlineFares[0].NetFare + flight.AirlineFares[1].NetFare
      );

      const minFare = Math.min(...fares);
      const maxFare = Math.max(...fares);
      setMinFare(minFare);
      setMaxFare(maxFare);

      setSliderValue([minFare, maxFare]);
    }
  }, [search]);

  useEffect(() => {
    applyFilters();
  }, [search, sliderValue, deptimeRange, arrtimeRange, airlines, checkedStops]);

  const parseSearchParams = (data) => {
    const searchData = {
      Segments: [{}],
    };
    const params = decodeURIComponent(data).split("*");

    params.forEach((param) => {
      const [key, value] = param.split("_");
      switch (key) {
        case "dest":
          searchData.Segments[0].Destination = value;
          break;
        case "org":
          searchData.Segments[0].Origin = value;
          break;
        case "dep":
          searchData.Segments[0].PreferredDepartureTime = value;
          break;
        case "arr":
          searchData.Segments[0].PreferredArrivalTime = value;
          break;
        case "px":
          const [adultCount, childCount, infantCount] = value.split("-");
          searchData.AdultCount = adultCount;
          searchData.ChildCount = childCount;
          searchData.InfantCount = infantCount;
          break;
        case "jt":
          searchData.JourneyType = value;
          break;
        case "cbn":
          searchData.Segments[0].FlightCabinClass = value;
          break;
        default:
          break;
      }
    });

    return searchData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tripTypeMapping = {
      OneWay: 0,
      RoundTrip: 1,
      MultiCity: 2,
    };

    // const cabin = event.target.cabinClass.value;
    const tripType = tripTypeMapping[!active ? "RoundTrip" : "OneWay"];

    const SearchData = {
      EndUserIp: "192.168.10.10",
      AdultCount: rooms[0].adults,
      ChildCount: rooms[0].children,
      InfantCount: rooms[0].infants,
      JourneyType: tripType,
      Segments: [
        {
          Origin: event.target.from.value,
          Destination: event.target.to.value,
          FlightCabinClass: selectedOption,
          PreferredDepartureTime: startDate.startOf("day").format("YYYY-MM-DD"),
          PreferredArrivalTime: endDate
            ? endDate.startOf("day").format("YYYY-MM-DD")
            : startDate.startOf("day").format("YYYY-MM-DD"),
        },
      ],
    };
    if (active) {
      window.location.assign(
        `/agent/flightList/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
    }

    if (active2) {
      if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
        window.location.assign(
          `/agent/international-round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      } else {
        window.location.assign(
          `/agent/round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      }
    }
  };
  const [netFare, setNetFare] = useState(false);
  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992;

    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30);
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60);
    } else {
      setDateRangeConfig(2, 40, 50);
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize) => {
    setNumberOfMonths(numberOfMonths);
  };

  const isSameDay = (date1, date2) => date1.isSame(date2, "day");

  const renderDayContents = (day) => {
    const fare = fares.find((item) => isSameDay(day, item.date));
    const isStartDate = startDate && isSameDay(day, startDate);
    const isEndDate = endDate && isSameDay(day, endDate);
    const isInRange =
      startDate && endDate && day.isBetween(startDate, endDate, "day", "[]");

    let classNames = ["DayPicker-Day"];
    if (isStartDate) classNames.push("DayPicker-Day--start");
    if (isEndDate) classNames.push("DayPicker-Day--end");
    if (isInRange) classNames.push("DayPicker-Day--range");

    return (
      <div className={classNames.join(" ")}>
        <span style={{ fontWeight: "600", fontSize: "13px" }}>
          {day.format("D")}
        </span>
        <br />
        {/* {fare && (
          <span
            style={{ fontSize: "10px", color: "#2d3290", fontWeight: "600" }}
          >
            {fare.fare}
          </span>
        )} */}
      </div>
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFocusedInput(null);
      setCalVisible(false);
    } else if (startDate) {
      setFocusedInput("endDate");
    }
  };

  const toggleCalendar = (input) => {
    setCalVisible(!calVisible);
    if (!calVisible) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        setCalVisible(!calVisible);
        if (!calVisible) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const updateRoomsData = (searchData) => {
    const updatedRooms = [
      {
        adults: parseInt(searchData.AdultCount),
        children: parseInt(searchData.ChildCount),
        infants: parseInt(searchData.InfantCount),
      },
    ];
    setRooms(updatedRooms);
  };

  const updateDates = (searchData) => {
    const departureTime = moment(searchData.Segments[0].PreferredDepartureTime);
    const arrivalTime = moment(searchData.Segments[0].PreferredArrivalTime);
    setStartDate(departureTime);
    // active2 &&
    setEndDate(arrivalTime);
  };
  const storedBookingKey = String(sessionStorage.getItem("bookingId"));
  const storedToken = String(localStorage.getItem("token"));
  const [tokenss, setTokenss] = useState(storedToken);
  const [bookingKeyss, setBookingKeyss] = useState(storedBookingKey);
  const [tokensss, setTokensss] = useState();
  const [bookingKey, setBookingKey] = useState();
  useEffect(() => {
    const storedBookingKey = String(sessionStorage.getItem("bookingId"));
    const storedToken = String(localStorage.getItem("token"));

    if (storedBookingKey && storedToken) {
      setBookingKey(storedBookingKey);
      setTokensss(storedToken);
      // console.log("token", storedToken);
      // console.log("traceId", storedTraceId);
    } else {
      setBookingKey(undefined);
      setTokensss(undefined);
    }
  }, [search]);
  const [fareRules, setFareRules] = useState("");
  const handleClick = async (id) => {
    setActiveId(activeId === id ? null : id);
    const response1 = await axios.post(
      "https://admin.yatriservice.com/api/flightfarerule",
      {
        APIToken: tokensss || tokenss,
        BookingKey: bookingKey || bookingKeyss,
        ContractId: id,
      }
    );
    console.log("RESPONSE ! FARE RULE ", response1.data.data.FareRule);
    setFareRules(response1.data.data.FareRule);
  };

  const [isItemSelected, setIsItemSelected] = useState(false);
  const [isItemSelected2, setIsItemSelected2] = useState(false);
  const [destination1, setDestination1] = useState("");
  const [destination2, setDestination2] = useState("");
  const [searchInput, setSearchInput] = useState(destinationCity);
  const [searchInput2, setSearchInput2] = useState(destinationCity2);
  const [cities2, setCities2] = useState([]);
  const [cities22, setCities22] = useState([]);
  const fetchDatas = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        console.log("RESULTS", results);
        setCities2(results);
      });
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    fetchDatas(value);
    SetClickDestination(true);
  };
  const fetchDatass = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        // console.log("RESULTS",results)
        setCities22(results);
      });
  };
  const handleInputChange2 = (value) => {
    setSearchInput2(value);
    fetchDatass(value);
    SetClickDestination2(true);
  };

  const handleCitySelect = (city) => {
    setDestinationCity(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode(city["AIRPORTCODE"]);
    SetClickDestination(false); // Close the city suggestion div
    setSearchInput(`${city["CITYCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected(true);
    setDestination1(city);
  };

  const handleCitySelect2 = (city) => {
    setDestinationCity2(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode2(city["AIRPORTCODE"]);
    SetClickDestination2(false); // Close the city suggestion div
    setSearchInput2(`${city["CITYCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected2(true);
    setDestination2(city);
  };

  const handleMoreFare = (idx) => {
    setShowModal(showModal === idx ? null : idx);
  };
  const handlebookmodal = (idx) => {
    navigate(`/agent/flight-details/${encodeURIComponent(idx)}`);
    setShowModal(false);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setVisible(scrollTop > 300);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleCheckedstops = (stopType) => {
    if (checkedStops.includes(stopType)) {
      setCheckedStops(checkedStops.filter((stop) => stop !== stopType));
    } else {
      setCheckedStops([...checkedStops, stopType]);
    }
  };

  const handledepTimeFilter = (range) => {
    if (deptimeRange[0] === range[0] && deptimeRange[1] === range[1])
      setdepTimeRange([0, 0]);
    else setdepTimeRange(range);
  };

  const handlearrTimeFilter = (range) => {
    if (arrtimeRange[0] === range[0] && arrtimeRange[1] === range[1])
      setarrTimeRange([0, 0]);
    else setarrTimeRange(range);
  };

  const applyFilters = () => {
    if (search.length === 0 || isLoading) {
      return;
    }

    const selectedAirlines = airlines
      .filter((airline) => airline.selected)
      .map((airline) => airline.name);
    const newFilteredDatasss = sortedSearch.filter((entry) => {
      // Check if any AirSegment matches all criteria
      const segmentMatches = entry.AirSegments.some((segment) => {
        // const fareInRange =
        //   segment.AirlineFares.NetFare >= sliderValue[0] &&
        //   segment.AirlineFares.NetFare <= sliderValue[1];

        const depTimeInRange =
          (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
          (new Date(segment.DepartureDateTime).getHours() >= deptimeRange[0] &&
            new Date(segment.DepartureDateTime).getHours() <= deptimeRange[1]);

        const arrTimeInRange =
          (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
          (new Date(segment.ArrivalDateTime).getHours() >= arrtimeRange[0] &&
            new Date(segment.ArrivalDateTime).getHours() <= arrtimeRange[1]);

        const isAirlineSelected =
          selectedAirlines.length === 0 ||
          selectedAirlines.includes(segment.AirlineName);

        let stopCountMatch = false;
        const stopCount = entry.AirSegments.length - 1; // Total stops excluding the first segment

        if (checkedStops.length === 0) {
          stopCountMatch = true;
        } else {
          const stopCount = entry.AirSegments.length - 1;
          if (checkedStops.includes("non-stop") && stopCount === 0) {
            stopCountMatch = true;
          } else if (checkedStops.includes("1-stop") && stopCount === 1) {
            stopCountMatch = true;
          } else if (checkedStops.includes("2-stop") && stopCount >= 2) {
            stopCountMatch = true;
          }
        }

        // Return true if all conditions match for this segment
        return (
          depTimeInRange &&
          arrTimeInRange &&
          isAirlineSelected &&
          stopCountMatch
        );
      });

      // Return true if any segment matches
      return segmentMatches;
    });

    // Display the filtered data
    setFilteredData(newFilteredDatasss);
    console.log(
      "NEWVUVUWVEUFUQEGHHFEOHIUEFGUIQEIUBFGILQVYF",
      newFilteredDatasss
    );
    const newFilteredData = search.filter((e) => {
      const fareInRange =
        e.AirlineFares.NetFare >= sliderValue[0] &&
        e.AirlineFares.NetFare <= sliderValue[1];

      const depTimeInRange =
        (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
        (new Date(e.AirSegments[0].DepartureDateTime).getHours() >=
          deptimeRange[0] &&
          new Date(e.AirSegments[0].DepartureDateTime).getHours() <=
            deptimeRange[1]);

      const arrTimeInRange =
        (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
        (new Date(e.AirSegments[0].ArrivalDateTime).getHours() >=
          arrtimeRange[0] &&
          new Date(e.AirSegments[0].ArrivalDateTime).getHours() <=
            arrtimeRange[1]);

      const isAirlineSelected =
        selectedAirlines.length === 0 ||
        selectedAirlines.includes(e.AirSegments[0].AirlineName);

      let stopCountMatch = false;
      if (checkedStops.length === 0) {
        stopCountMatch = true;
      } else {
        const stopCount = e.AirSegments.length - 1;
        if (checkedStops.includes("non-stop") && stopCount === 0) {
          stopCountMatch = true;
        } else if (checkedStops.includes("1-stop") && stopCount === 1) {
          stopCountMatch = true;
        } else if (checkedStops.includes("2-stop") && stopCount >= 2) {
          stopCountMatch = true;
        }
        // } else if (checkedStops.includes("3-stop") && stopCount >= 3) {
        //   stopCountMatch = true;
        // }
      }
      return (
        fareInRange &&
        depTimeInRange &&
        arrTimeInRange &&
        isAirlineSelected &&
        stopCountMatch
      );
    });
    // setFilteredData(newFilteredData);
    console.log("Filtered Data:", newFilteredData);
  };

  const handleChecked = (airlineName) => {
    const updatedAirlines = airlines.map((airline) =>
      airline.name === airlineName
        ? { ...airline, selected: !airline.selected }
        : airline
    );
    setAirlines(updatedAirlines);
  };

  const handleShowAllairlinenames = (event) => {
    const isChecked = event.target.checked;
    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: isChecked,
    }));
    setAirlines(updatedAirlines);
  };

  const handleShowAllStops = (event) => {
    if (event.target.checked) {
      setCheckedStops(["non-stop", "1-stop", "2-stop"]);
    } else {
      setCheckedStops([]);
    }
  };

  const clearAllFilters = () => {
    setSliderValue([minFare, maxFare]);
    setdepTimeRange([0, 0]);
    setarrTimeRange([0, 0]);
    setCheckedStops(["non-stop", "1-stop", "2-stop"]);

    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: true,
    }));
    setAirlines(updatedAirlines);
  };

  const filteredCities = cities2.filter(
    (city) =>
      city["AIRPORTNAME"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["AIRPORTCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["CITYCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["COUNTRYNAME"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["COUNTRYCODE"].toLowerCase().includes(searchInput.toLowerCase()) ||
      city["CITYNAME"].toLowerCase().includes(searchInput.toLowerCase())
  );

  const filteredCities2 = cities2.filter(
    (city) =>
      city["AIRPORTNAME"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["AIRPORTCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["CITYCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["COUNTRYNAME"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["COUNTRYCODE"].toLowerCase().includes(searchInput2.toLowerCase()) ||
      city["CITYNAME"].toLowerCase().includes(searchInput2.toLowerCase())
  );
  const [currentDate, setCurrentDate] = useState(new Date());

  // Function to generate calendar items
  const generateCalendarItems = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = months[currentDate.getMonth()];

    const daysInCurrentMonth = daysInMonth(currentDate.getMonth(), currentYear);

    const calendarItems = [];
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      calendarItems.push(
        <div key={`${currentMonth}-${day}-${currentYear}`} className="fare-box">
          <div
            className="date"
            style={{
              fontWeight: "600",
              marginBottom: "5px",
              fontSize: "14px",
              color: "#000",
            }}
          >{`${day} ${currentMonth}`}</div>
          <h6 className="fare">₹250</h6>
        </div>
      );
    }

    return calendarItems;
  };

  const settings = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
    ],
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  return (
    <div className="flightListPage inRound">
      <div className="roundtrippg">
        <FlightForm
          dataSearch={dataSearch}
          handleSubmit={handleSubmit}
          tripsActive={tripsActive}
          active={active}
          active2={active2}
          active3={active3}
          handleSearchFlight={handleSearchFlight}
          handleSearchFlightRound={handleSearchFlightRound}
          handleSearchFlightMultiPle={handleSearchFlightMultiPle}
          setTripsActive={setTripsActive}
          searchInput={searchInput}
          searchInput2={searchInput2}
          SetClickDestination={SetClickDestination}
          SetClickDestination2={SetClickDestination2}
          isItemSelected={isItemSelected}
          isItemSelected2={isItemSelected2}
          handleInputChange={handleInputChange}
          handleInputChange2={handleInputChange2}
          filteredCities={cities2}
          filteredCities2={cities22}
          clickDestination={clickDestination}
          clickDestination2={clickDestination2}
          handleCitySelect={handleCitySelect}
          handleCitySelect2={handleCitySelect2}
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          toggleCalendar={toggleCalendar}
          rooms={rooms}
          setTravellerActive={setTravellerActive}
          travellerActive={travellerActive}
          updateRoom={updateRoom}
          selectedOption={selectedOption}
          handleOptionChange={handleOptionChange}
          calVisible={calVisible}
          setCalVisible={setCalVisible}
          handleDatesChange={handleDatesChange}
          focusedInput={focusedInput}
          setFocusedInput={setFocusedInput}
          renderDayContents={renderDayContents}
          numberOfMonths={numberOfMonths}
        />
      </div>

      <div className="flightsMainBody">
        {/* <Navbar /> */}
        {/* <Bookingmenu /> */}
        <Container className="bodyDiv">
          <Row>
            <FilterBar
              showFilter={showFilter}
              minFare={minFare}
              maxFare={maxFare}
              sliderValue={sliderValue}
              clearAllFilters={clearAllFilters}
              handleSliderChange={handleSliderChange}
              handledepTimeFilter={handledepTimeFilter}
              deptimeRange={deptimeRange}
              arrtimeRange={arrtimeRange}
              handlearrTimeFilter={handlearrTimeFilter}
              handleShowAllStops={handleShowAllStops}
              checkedStops={checkedStops}
              handleCheckedstops={handleCheckedstops}
              handleShowAllairlinenames={handleShowAllairlinenames}
              airlines={airlines}
              handleChecked={handleChecked}
              setShowFilter={setShowFilter}
              applyFilters={applyFilters}
            />
            <Col md={9} className="rightDiv" style={{ paddingRight: "0px" }}>
              <Col lg={12} className="ratio3_2">
                <a href="#" className="mobile-filter border-top-0">
                  <h5>latest filter</h5>
                  <img
                    src="https://rica-next-pixelstrapthemes.vercel.app/assets/images/icon/adjust.png"
                    className="img-fluid "
                    alt=""
                  />
                </a>
                <div className="top-bar-flight">
                  <div className="date-fare-slider">
                    <Slider {...settings}>{generateCalendarItems()}</Slider>
                  </div>
                  <div className="fare-calender">
                    <div>
                      <i className="far fa-calendar-alt" />
                      <h6 className="title">fare calender</h6>
                    </div>
                    <div className="calender-external " />
                  </div>
                </div>
              </Col>

              {filteredData.length !== 0 && !isLoading ? (
                <div>
                  {search &&
                    filteredData &&
                    filteredData.map((e, index) => {
                      return (
                        <InternationalFlightListCard
                          key={index}
                          e={e}
                          handleMoreFare={handleMoreFare}
                          handleClick={handleClick}
                          internationalMarkup={internationalMarkup}
                          fareRules={fareRules}
                          fares={fares}
                          activeId={activeId}
                          showModal={showModal}
                          setShowModal={setShowModal}
                          formatTime={formatTime}
                          handlebookmodal={handlebookmodal}
                        />
                      );
                    })}
                </div>
              ) : (
                <div className="d-flex flex-column gap-3">
                  <OneWaySkeleton />
                  <OneWaySkeleton />
                  <OneWaySkeleton />
                  <OneWaySkeleton />
                </div>
              )}
            </Col>
          </Row>
          <div
            className={`applyFilter ${Visible && "show"}`}
            onClick={() => setShowFilter(true)}
          >
            <FaFilter /> Filter
          </div>
        </Container>
      </div>
    </div>
  );
};

export default FlightInternational;
