import React, { useState, useEffect } from "react";
import "./OneWay.css";
import { useNavigate, useParams } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import FlightForm from "./FlightForm";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { cities2 } from "../../Home/HomeDash";
import { cities12 } from "../../../Cities";
import { RxCamera, RxCross1 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import styled from "styled-components";
import { DayPickerRangeController } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
// import moment from 'moment'

import moment from "moment";
import { flightSearch } from "../../../redux/services/operations/flight";
import { FlightListInfo } from "./FlightListInfo";
import OneWaySkeleton from "./OneWaySkeleton";
import FilterBar from "../RoundTrip/FilterBar";
import NotFound from "../NotFound";
import { FaFilter } from "react-icons/fa";
import { Markup } from "../../../redux/services/operations/markup";

const flightClassDefaultValue = (flightCabinClass) => {
  switch (parseInt(flightCabinClass)) {
    case 2:
      return "2"; // Economy
    case 3:
      return "3"; // PremiumEconomy
    case 4:
      return "4"; // Business
    case 6:
      return "6"; // First
    default:
      return ""; // Default to no selection
  }
};
export const fares = [
  { date: "2024-05-01", fare: "$200" },
  { date: "2024-05-02", fare: "$220" },
  { date: "2024-05-03", fare: "$240" },
  { date: "2024-05-04", fare: "$230" },
  { date: "2024-05-05", fare: "$250" },
  { date: "2024-05-06", fare: "$260" },
  { date: "2024-05-07", fare: "$270" },
  { date: "2024-05-08", fare: "$280" },
  { date: "2024-05-09", fare: "$290" },
  { date: "2024-05-10", fare: "$300" },
  { date: "2024-05-11", fare: "$310" },
  { date: "2024-05-12", fare: "$320" },
  { date: "2024-05-13", fare: "$330" },
  { date: "2024-05-14", fare: "$340" },
  { date: "2024-05-15", fare: "$350" },
  { date: "2024-05-16", fare: "$360" },
  { date: "2024-05-17", fare: "$370" },
  { date: "2024-05-18", fare: "$380" },
  { date: "2024-05-19", fare: "$390" },
  { date: "2024-05-20", fare: "$400" },
  { date: "2024-05-21", fare: "$410" },
  { date: "2024-05-22", fare: "$420" },
  { date: "2024-05-23", fare: "$430" },
  { date: "2024-05-24", fare: "$440" },
  { date: "2024-05-25", fare: "$450" },
  { date: "2024-05-26", fare: "$460" },
  { date: "2024-05-27", fare: "$470" },
  { date: "2024-05-28", fare: "$480" },
  { date: "2024-05-29", fare: "$490" },
  { date: "2024-05-30", fare: "$500" },
  { date: "2024-05-31", fare: "$510" },

  { date: "2024-04-01", fare: "$520" },
  { date: "2024-04-02", fare: "$530" },
  { date: "2024-04-03", fare: "$540" },
  { date: "2024-04-04", fare: "$550" },
  { date: "2024-04-05", fare: "$560" },
  { date: "2024-04-06", fare: "$570" },
  { date: "2024-04-07", fare: "$580" },
  { date: "2024-04-08", fare: "$590" },
  { date: "2024-04-09", fare: "$600" },
  { date: "2024-04-10", fare: "$610" },
  { date: "2024-04-11", fare: "$620" },
  { date: "2024-04-12", fare: "$630" },
  { date: "2024-04-13", fare: "$640" },
  { date: "2024-04-14", fare: "$650" },
  { date: "2024-04-15", fare: "$660" },
  { date: "2024-04-16", fare: "$670" },
  { date: "2024-04-17", fare: "$680" },
  { date: "2024-04-18", fare: "$690" },
  { date: "2024-04-19", fare: "$700" },
  { date: "2024-04-20", fare: "$710" },
  { date: "2024-04-21", fare: "$720" },
  { date: "2024-04-22", fare: "$730" },
  { date: "2024-04-23", fare: "$740" },
  { date: "2024-04-24", fare: "$750" },
  { date: "2024-04-25", fare: "$760" },
  { date: "2024-04-26", fare: "$770" },
  { date: "2024-04-27", fare: "$780" },
  { date: "2024-04-28", fare: "$790" },
  { date: "2024-04-29", fare: "$800" },
  { date: "2024-04-30", fare: "$810" },
];

const sliderItems = [
  { date: "Oct 03", price: "7845" },
  { date: "Oct 04", price: "5954" },
  { date: "Oct 05", price: "4155" },
  { date: "Oct 06", price: "5953" },
  { date: "Oct 07", price: "5495" },
  { date: "Oct 08", price: "5953" },
  { date: "Oct 09", price: "4155" },
];

const CalenderSliderContent = styled.div`
  border: 1px solid rgb(228, 228, 228);
  border-right: none;
  border-block: none;
  text-align: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const OneWay = () => {
  const fares = {
    0: "Normal",
    1: "Special",
    3: "UserFare",
    4: "CorporateFare",
    5: "CouponFare",
    6: "SMEFare",
    7: "GDSFare",
    8: "Default",
    9: "GoSmart",
    10: "GoSpecial",
    11: "GoSmartCorporate",
    12: "GoMarine",
    13: "GoBusiness",
    14: "GoBusinessCorporat",
    15: "GoRound",
    16: "SpiceSaver",
    17: "SpiceCorp",
    18: "SpiceSME",
    19: "SpiceCoupon",
    20: "GoFlexi",
    21: "SpiceCorporate",
    22: "UKSpecial",
    23: "GoSmartCorporateSp",
    24: "CrpcFare",
    25: "GoSpecialV2",
    26: "AkasaNormal",
    27: "AkasaAV",
    28: "AkasaSpecial",
    29: "AkasaCorporate",
    30: "AkasaCorpSelect",
    31: "EchoPECO",
    32: "EchoPCLS",
    33: "IndgoFlexi",
    34: "IndigoTactial",
    35: "IndigoCoupon",
    36: "VistaFlex",
    37: "AISME",
    38: "STU",
    39: "IXSme",
    40: "VVIP",
    41: "SUP6E",
    42: "AIBUS",
  };

  const { walletData } = useSelector((state) => state.auth);
  const { errors } = useSelector((state) => state.flight);
  const timer = useSelector((state) => state.flight.timer);
  console.log("TIMERERERERERERERERERERER", timer);
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const { data: routeParams } = useParams();
  const dispatch = useDispatch();
  const search = useSelector((state) => state.flight.search);
  const [sliderValue, setSliderValue] = useState([0, 0]);
  const [isLoading, setIsLoading] = useState(false);
  const [dataSearch, setDataSearch] = useState(null);
  const [activeId, setActiveId] = useState(null);
  const [rooms, setRooms] = useState([{ adults: 1, children: 0, infants: 0 }]);
  const [labelClicked, setLabelClicked] = useState(false);
  const [active, setActive] = useState(true);
  const [active2, setActive2] = useState(false);
  const [active3, setActive3] = useState(false);
  const [numberOfMonths, setNumberOfMonths] = useState(2);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [calVisible, setCalVisible] = useState(false);
  const [clickDestination, SetClickDestination] = useState(false);
  const [clickDestination2, SetClickDestination2] = useState(false);
  const [destinationCity, setDestinationCity] = useState("");
  const [selectedCityCode, setSelectedCityCode] = useState("");
  const [destinationCity2, setDestinationCity2] = useState("");
  const [selectedCityCode2, setSelectedCityCode2] = useState("");
  const [travellerActive, setTravellerActive] = useState(false);
  const [showModal, setShowModal] = useState(null);
  const [Visible, setVisible] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [tripsActive, setTripsActive] = useState(false);
  const [checkedStops, setCheckedStops] = useState([
    "non-stop",
    "1-stop",
    "2-stops",
  ]);
  const [deptimeRange, setdepTimeRange] = useState([0, 0]);
  const [arrtimeRange, setarrTimeRange] = useState([0, 0]);
  const [filteredData, setFilteredData] = useState(
    search.length > 0 ? search : []
  );
  const [airlines, setAirlines] = useState([]);
  const [minFare, setMinFare] = useState(0);
  const [maxFare, setMaxFare] = useState(0);
  const [selectedOption, setSelectedOption] = useState(0);
  const [sortedSearch, setSortedSearch] = useState([]);

  // const handleOptionChange = (event) => {
  //   setSelectedOption(parseInt(event.target.value));
  // };

  const handleOptionChange = (val) => {
    setSelectedOption(val);
  };
  const storedBookingKey = String(sessionStorage.getItem("bookingId"));
  const storedToken = String(localStorage.getItem("token"));
  const [tokenss, setTokenss] = useState(storedToken);
  const [bookingKeyss, setBookingKeyss] = useState(storedBookingKey);
  const [tokensss, setTokensss] = useState();
  const [bookingKey, setBookingKey] = useState();
  useEffect(() => {
    const storedBookingKey = String(sessionStorage.getItem("bookingId"));
    const storedToken = String(localStorage.getItem("token"));

    if (storedBookingKey && storedToken) {
      setBookingKey(storedBookingKey);
      setTokensss(storedToken);
    } else {
      setBookingKey(undefined);
      setTokensss(undefined);
    }
    const dataaaa = {
      AgentId: walletData.Id,
    };

    dispatch(Markup(dataaaa));
  }, [search]);
  useEffect(() => {
    const searchData = parseSearchParams(routeParams);
    setDataSearch(searchData);
    updateRoomsData(searchData);
    updateDates(searchData);
    if (parseInt(searchData.JourneyType) === 0) handleSearchFlight();
    else if (parseInt(searchData.JourneyType) === 1) handleSearchFlightRound();
    else if (parseInt(searchData.JourneyType) === 3)
      handleSearchFlightMultiPle();
    setSearchInput(searchData.Segments[0].Origin);
    setSearchInput2(searchData.Segments[0].Destination);
    // console.log("cabin class flight", searchData.Segments[0].FlightCabinClass)
    setSelectedOption(parseInt(searchData.Segments[0].FlightCabinClass));
    setSelectedOption(parseInt(searchData.Segments[0].FlightCabinClass));
    const destCity = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Origin
    );
    const destCity2 = cities12.find(
      (city) => city.AIRPORTCODE === searchData.Segments[0].Destination
    );
    setDestination1(destCity);
    setDestination2(destCity2);
  }, [routeParams]);

  useEffect(() => {
    if (dataSearch) {
      setIsLoading(true);
      const updatedSearchData = { ...dataSearch };
      updatedSearchData.ApiToken = token;
      // updatedSearchData.EndUserIp = "192.168.10.10";
      console.log("Search Data", updatedSearchData);
      const searchDataAgain = {
        // updatedSearchData.ApiToken : token;
        APIToken: updatedSearchData.ApiToken,
        Id: walletData && walletData.Email,
        AdultCount: updatedSearchData.AdultCount,
        ChildCount: updatedSearchData.ChildCount,
        InfantCount: updatedSearchData.InfantCount,
        Routs: [
          {
            Source: updatedSearchData.Segments[0].Origin,
            Destination: updatedSearchData.Segments[0].Destination,
            TravelDate: updatedSearchData.Segments[0].PreferredArrivalTime,
          },
        ],
        TripType: updatedSearchData.JourneyType,
        TypeOfClass: updatedSearchData.Segments[0].FlightCabinClass,
      };
      console.log("SearchDataAgainnnnn", searchDataAgain);
      dispatch(flightSearch(searchDataAgain, navigate))
        .then(() => {
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error:", error);
          setIsLoading(false);
        });
    }
  }, [dataSearch, dispatch, navigate]);

  useEffect(() => {
    const handleResize = () => {
      setBreakpoints();
    };
    window.addEventListener("resize", handleResize);
    setBreakpoints();
    return () => window.removeEventListener("resize", handleResize);
  });

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (sortedSearch.length > 0) {
      setFilteredData(sortedSearch);
    }
  }, [sortedSearch]);

  useEffect(() => {
    if (search) {
      const airlineNames = search.map((flight) => ({
        name: flight.AirSegments[0].AirlineName,
        selected: true,
      }));
      const uniqueAirlines = Array.from(
        new Set(airlineNames.map((airline) => airline.name))
      );
      const uniqueAirlinesWithSelected = uniqueAirlines.map((airlineName) => ({
        name: airlineName,
        selected: true,
      }));
      setAirlines(uniqueAirlinesWithSelected);
      console.log("airlines", uniqueAirlines);
      const sortedSearch = [...search].sort(
        (a, b) => a.AirlineFare.GrossFare - b.AirlineFare.GrossFare
      );
      setSortedSearch(sortedSearch);
      console.log("Sorted Data:", sortedSearch);
    }
  }, [search]);

  useEffect(() => {
    if (search.length > 0) {
      const fares = search.map((flight) => flight.AirlineFare.NetFare);

      const minFare = Math.min(...fares);
      const maxFare = Math.max(...fares);
      setMinFare(minFare);
      setMaxFare(maxFare);

      setSliderValue([minFare, maxFare]);
    }
  }, [search]);

  useEffect(() => {
    applyFilters();
  }, [search, sliderValue, deptimeRange, arrtimeRange, airlines, checkedStops]);

  const parseSearchParams = (data) => {
    const searchData = {
      Segments: [{}],
    };
    const params = decodeURIComponent(data).split("*");

    params.forEach((param) => {
      const [key, value] = param.split("_");
      switch (key) {
        case "dest":
          searchData.Segments[0].Destination = value;
          break;
        case "org":
          searchData.Segments[0].Origin = value;
          break;
        case "dep":
          searchData.Segments[0].PreferredDepartureTime = value;
          break;
        case "arr":
          searchData.Segments[0].PreferredArrivalTime = value;
          break;
        case "px":
          const [adultCount, childCount, infantCount] = value.split("-");
          searchData.AdultCount = adultCount;
          searchData.ChildCount = childCount;
          searchData.InfantCount = infantCount;
          break;
        case "jt":
          searchData.JourneyType = value;
          break;
        case "cbn":
          searchData.Segments[0].FlightCabinClass = value;
          break;
        default:
          break;
      }
    });

    return searchData;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const tripTypeMapping = {
      OneWay: 0,
      RoundTrip: 1,
      MultiCity: 2,
    };

    // const cabin = event.target.cabinClass.value;
    const tripType = tripTypeMapping[!active ? "RoundTrip" : "OneWay"];

    const SearchData = {
      EndUserIp: "192.168.10.10",
      AdultCount: rooms[0].adults,
      ChildCount: rooms[0].children,
      InfantCount: rooms[0].infants,
      JourneyType: tripType,
      Segments: [
        {
          Origin: event.target.from.value,
          Destination: event.target.to.value,
          FlightCabinClass: selectedOption,
          PreferredDepartureTime: startDate.startOf("day").format("YYYY-MM-DD"),
          PreferredArrivalTime: endDate
            ? endDate.startOf("day").format("YYYY-MM-DD")
            : startDate.startOf("day").format("YYYY-MM-DD"),
        },
      ],
    };
    if (active) {
      window.location.assign(
        `/agent/flightList/${encodeURIComponent(
          `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
        )}`
      );
    }

    if (active2) {
      if (destination1.COUNTRYCODE !== destination2.COUNTRYCODE) {
        window.location.assign(
          `/agent/international-round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      } else {
        window.location.assign(
          `/agent/round/${encodeURIComponent(
            `dest_${SearchData.Segments[0].Destination}*org_${SearchData.Segments[0].Origin}*dep_${SearchData.Segments[0].PreferredDepartureTime}*arr_${SearchData.Segments[0].PreferredArrivalTime}*px_${SearchData.AdultCount}-${SearchData.ChildCount}-${SearchData.InfantCount}*jt_${SearchData.JourneyType}*cbn_${SearchData.Segments[0].FlightCabinClass}`
          )}`
        );
      }
    }
  };
  const [netFare, setNetFare] = useState(false);
  const handleSearchFlight = () => {
    setActive(true);
    setActive2(false);
    setActive3(false);
    setEndDate(null);
  };
  const handleSearchFlightRound = () => {
    setActive2(true);
    setActive(false);
    setActive3(false);
  };
  const handleSearchFlightMultiPle = () => {
    setActive3(true);
    setActive(false);
    setActive2(false);
  };

  const setBreakpoints = () => {
    const isSmallScreen = window.innerWidth <= 768;
    const isMediumScreen = window.innerWidth > 768 && window.innerWidth <= 992;

    if (isSmallScreen) {
      setDateRangeConfig(1, 30, 30);
    } else if (isMediumScreen) {
      setDateRangeConfig(1, 40, 60);
    } else {
      setDateRangeConfig(2, 40, 50);
    }
  };

  const setDateRangeConfig = (numberOfMonths, daysize) => {
    setNumberOfMonths(numberOfMonths);
  };

  const isSameDay = (date1, date2) => date1.isSame(date2, "day");

  const renderDayContents = (day) => {
    // const fare = fares.find((item) => isSameDay(day, item.date));
    const isStartDate = startDate && isSameDay(day, startDate);
    const isEndDate = endDate && isSameDay(day, endDate);
    const isInRange =
      startDate && endDate && day.isBetween(startDate, endDate, "day", "[]");

    let classNames = ["DayPicker-Day"];
    if (isStartDate) classNames.push("DayPicker-Day--start");
    if (isEndDate) classNames.push("DayPicker-Day--end");
    if (isInRange) classNames.push("DayPicker-Day--range");

    return (
      <div className={classNames.join(" ")}>
        <span style={{ fontWeight: "600", fontSize: "13px" }}>
          {day.format("D")}
        </span>
        <br />
        {/* {fare && (
          <span
            style={{ fontSize: "10px", color: "#2d3290", fontWeight: "600" }}
          >
            {fare.fare}
          </span>
        )} */}
      </div>
    );
  };

  const handleDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
    if (startDate && endDate) {
      setFocusedInput(null);
      setCalVisible(false);
    } else if (startDate) {
      setFocusedInput("endDate");
    }
  };

  const toggleCalendar = (input) => {
    setCalVisible(!calVisible);
    if (!calVisible) {
      if (input === "startDate") {
        setFocusedInput("startDate");
      } else if (input === "endDate") {
        setFocusedInput("endDate");
      } else {
        setCalVisible(!calVisible);
        if (!calVisible) setFocusedInput("startDate");
        else setFocusedInput(null);
      }
    }
  };

  const updateRoom = (index, field, value) => {
    const updatedRooms = [...rooms];
    updatedRooms[index][field] = value;
    setRooms(updatedRooms);
  };

  const updateRoomsData = (searchData) => {
    const updatedRooms = [
      {
        adults: parseInt(searchData.AdultCount),
        children: parseInt(searchData.ChildCount),
        infants: parseInt(searchData.InfantCount),
      },
    ];
    setRooms(updatedRooms);
  };

  const updateDates = (searchData) => {
    const departureTime = moment(searchData.Segments[0].PreferredDepartureTime);
    const arrivalTime = moment(searchData.Segments[0].PreferredArrivalTime);
    setStartDate(departureTime);
    // active2 &&
    setEndDate(arrivalTime);
  };
  const [fareRules, setFareRules] = useState("");
  const handleClick = async (id) => {
    setActiveId(activeId === id ? null : id);
    const response1 = await axios.post(
      "https://admin.yatriservice.com/api/flightfarerule",
      {
        APIToken: tokensss || tokenss,
        BookingKey: bookingKey || bookingKeyss,
        ContractId: id,
      }
    );
    console.log("IDDDDDDDDD", id);
    setFareRules(response1.data.data.FareRule);
  };

  const [isItemSelected, setIsItemSelected] = useState(false);
  const [isItemSelected2, setIsItemSelected2] = useState(false);
  const [destination1, setDestination1] = useState("");
  const [destination2, setDestination2] = useState("");
  const [searchInput, setSearchInput] = useState(destinationCity);
  const [searchInput2, setSearchInput2] = useState(destinationCity2);
  const [cities2, setCities2] = useState([]);
  const [cities22, setCities22] = useState([]);
  const fetchDatas = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        console.log("RESULTS", results);
        setCities2(results);
      });
  };

  const handleInputChange = (value) => {
    setSearchInput(value);
    fetchDatas(value);
    SetClickDestination(true);
  };
  const fetchDatass = (value) => {
    fetch("https://admin.yatriservice.com/api/airport")
      .then((response) => response.json())
      .then((json) => {
        const results = json.data
          .filter((user) => {
            return (
              user &&
              ((user.AIRPORTNAME &&
                user.AIRPORTNAME.toLowerCase().includes(value)) ||
                (user.AIRPORTCODE &&
                  user.AIRPORTCODE.toLowerCase().includes(value)) ||
                (user.CITYCODE &&
                  user.CITYCODE.toLowerCase().includes(value)) ||
                (user.COUNTRYNAME &&
                  user.COUNTRYNAME.toLowerCase().includes(value)) ||
                (user.COUNTRYCODE &&
                  user.COUNTRYCODE.toLowerCase().includes(value)) ||
                (user.CITYNAME && user.CITYNAME.toLowerCase().includes(value)))
            );
          })
          .map((user) => {
            let priority = 3;

            if (user.CITYCODE && user.CITYCODE.toLowerCase().includes(value)) {
              priority = 1; // Highest priority for city code matches
            } else if (
              user.CITYNAME &&
              user.CITYNAME.toLowerCase().includes(value)
            ) {
              priority = 2; // Second priority for city name matches
            } else if (
              user.COUNTRYNAME &&
              user.COUNTRYNAME.toLowerCase().includes(value)
            ) {
              priority = 3; // Third priority for country name matches
            }

            return { ...user, priority };
          })
          .sort((a, b) => a.priority - b.priority);
        // console.log("RESULTS",results)
        setCities22(results);
      });
  };
  const handleInputChange2 = (value) => {
    setSearchInput2(value);
    fetchDatass(value);
    SetClickDestination2(true);
  };

  const handleCitySelect = (city) => {
    setDestinationCity(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode(city["AIRPORTCODE"]);
    SetClickDestination(false); // Close the city suggestion div
    setSearchInput(`${city["CITYCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected(true);
    setDestination1(city);
  };

  const handleCitySelect2 = (city) => {
    setDestinationCity2(`${city["CITYNAME"]} (${city["CITYCODE"]})`);
    setSelectedCityCode2(city["AIRPORTCODE"]);
    SetClickDestination2(false); // Close the city suggestion div
    setSearchInput2(`${city["CITYCODE"]}`); // Clear the search input after selecting a city
    setIsItemSelected2(true);
    setDestination2(city);
  };

  const handleMoreFare = (idx) => {
    setShowModal(showModal === idx ? null : idx);
  };
  const handlebookmodal = (idx) => {
    navigate(`/agent/flight-detail/${encodeURIComponent(idx)}`);
    setShowModal(false);
  };

  const handleScroll = () => {
    const scrollTop = window.pageYOffset;
    setVisible(scrollTop > 300);
  };

  const handleSliderChange = (value) => {
    setSliderValue(value);
  };

  const handleCheckedstops = (stopType) => {
    if (checkedStops.includes(stopType)) {
      setCheckedStops(checkedStops.filter((stop) => stop !== stopType));
    } else {
      setCheckedStops([...checkedStops, stopType]);
    }
  };

  const handledepTimeFilter = (range) => {
    if (deptimeRange[0] === range[0] && deptimeRange[1] === range[1])
      setdepTimeRange([0, 0]);
    else setdepTimeRange(range);
  };

  const handlearrTimeFilter = (range) => {
    if (arrtimeRange[0] === range[0] && arrtimeRange[1] === range[1])
      setarrTimeRange([0, 0]);
    else setarrTimeRange(range);
  };

  const applyFilters = () => {
    if (search.length === 0 || isLoading) {
      return;
    }

    const selectedAirlines = airlines
      .filter((airline) => airline.selected)
      .map((airline) => airline.name);

    const newFilteredData = sortedSearch.filter((e) => {
      const fareInRange =
        e.AirlineFare.NetFare >= sliderValue[0] &&
        e.AirlineFare.NetFare <= sliderValue[1];

      const depTimeInRange =
        (deptimeRange[0] === 0 && deptimeRange[1] === 0) ||
        (new Date(e.AirSegments[0].DepartureDateTime).getHours() >=
          deptimeRange[0] &&
          new Date(e.AirSegments[0].DepartureDateTime).getHours() <=
            deptimeRange[1]);

      const arrTimeInRange =
        (arrtimeRange[0] === 0 && arrtimeRange[1] === 0) ||
        (new Date(e.AirSegments[0].ArrivalDateTime).getHours() >=
          arrtimeRange[0] &&
          new Date(e.AirSegments[0].ArrivalDateTime).getHours() <=
            arrtimeRange[1]);

      const isAirlineSelected =
        selectedAirlines.length === 0 ||
        selectedAirlines.includes(e.AirSegments[0].AirlineName);

      let stopCountMatch = false;
      if (checkedStops.length === 0) {
        stopCountMatch = true;
      } else {
        const stopCount = e.AirSegments.length - 1;
        if (checkedStops.includes("non-stop") && stopCount === 0) {
          stopCountMatch = true;
        } else if (checkedStops.includes("1-stop") && stopCount === 1) {
          stopCountMatch = true;
        } else if (checkedStops.includes("2-stop") && stopCount >= 2) {
          stopCountMatch = true;
        }
        // } else if (checkedStops.includes("3-stop") && stopCount >= 3) {
        //   stopCountMatch = true;
        // }
      }
      return (
        fareInRange &&
        depTimeInRange &&
        arrTimeInRange &&
        isAirlineSelected &&
        stopCountMatch
      );
    });
    setFilteredData(newFilteredData);
    console.log("Filtered Data:", newFilteredData);
  };

  const handleChecked = (airlineName) => {
    const updatedAirlines = airlines.map((airline) =>
      airline.name === airlineName
        ? { ...airline, selected: !airline.selected }
        : airline
    );
    setAirlines(updatedAirlines);
  };

  const handleShowAllairlinenames = (event) => {
    const isChecked = event.target.checked;
    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: isChecked,
    }));
    setAirlines(updatedAirlines);
  };

  const handleShowAllStops = (event) => {
    if (event.target.checked) {
      setCheckedStops(["non-stop", "1-stop", "2-stops"]);
    } else {
      setCheckedStops([]);
    }
  };

  const clearAllFilters = () => {
    setSliderValue([minFare, maxFare]);
    setdepTimeRange([0, 0]);
    setarrTimeRange([0, 0]);
    setCheckedStops(["non-stop", "1-stop", "2-stops"]);

    const updatedAirlines = airlines.map((airline) => ({
      ...airline,
      selected: true,
    }));
    setAirlines(updatedAirlines);
  };

  const [currentDate, setCurrentDate] = useState(new Date());

  // Function to generate calendar items
  const generateCalendarItems = () => {
    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const daysInMonth = (month, year) => new Date(year, month + 1, 0).getDate();
    const currentYear = currentDate.getFullYear();
    const currentMonth = months[currentDate.getMonth()];

    const daysInCurrentMonth = daysInMonth(currentDate.getMonth(), currentYear);

    const calendarItems = [];
    for (let day = 1; day <= daysInCurrentMonth; day++) {
      calendarItems.push(
        <div key={`${currentMonth}-${day}-${currentYear}`} className="fare-box">
          <div
            className="date"
            style={{
              fontWeight: "600",
              marginBottom: "5px",
              fontSize: "14px",
              color: "#333333",
            }}
          >{`${day} ${currentMonth}`}</div>
          <h6 className="fare">₹250</h6>
        </div>
      );
    }

    return calendarItems;
  };

  const settings = {
    // dots: true,
    // infinite: true,
    // speed: 500,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 7,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
    // autoplay: true,
    // autoplaySpeed: 2000,
  };

  console.log("searchhhhhhhh", search);
  const { adminDm, adminIm, agentDm, agentIm } = useSelector(
    (state) => state.markUp
  );
  console.log("ADMIN LOGGGGGGGGGGGGGGGGGG", adminDm, adminIm, agentDm, agentIm);
  const [isinternational, setIsInternational] = useState(false);
  useEffect(() => {
    if (destination1 && destination2) {
      if (
        destination1 &&
        destination2 &&
        destination1.COUNTRYCODE !== destination2.COUNTRYCODE
      ) {
        setIsInternational(true);
      }
    }
  }, [destination1, destination2]);

  const markup = isinternational ? adminIm : adminDm;
  const agentmarkup = isinternational ? agentIm : agentDm;

  return (
    <div className="flightListPage roundtrippg">
      <FlightForm
        dataSearch={dataSearch}
        handleSubmit={handleSubmit}
        tripsActive={tripsActive}
        active={active}
        active2={active2}
        active3={active3}
        handleSearchFlight={handleSearchFlight}
        handleSearchFlightRound={handleSearchFlightRound}
        handleSearchFlightMultiPle={handleSearchFlightMultiPle}
        setTripsActive={setTripsActive}
        searchInput={searchInput}
        searchInput2={searchInput2}
        SetClickDestination={SetClickDestination}
        SetClickDestination2={SetClickDestination2}
        isItemSelected={isItemSelected}
        isItemSelected2={isItemSelected2}
        handleInputChange={handleInputChange}
        handleInputChange2={handleInputChange2}
        filteredCities={cities2}
        filteredCities2={cities22}
        clickDestination={clickDestination}
        clickDestination2={clickDestination2}
        handleCitySelect={handleCitySelect}
        handleCitySelect2={handleCitySelect2}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        toggleCalendar={toggleCalendar}
        rooms={rooms}
        setTravellerActive={setTravellerActive}
        travellerActive={travellerActive}
        updateRoom={updateRoom}
        selectedOption={selectedOption}
        handleOptionChange={handleOptionChange}
        calVisible={calVisible}
        setCalVisible={setCalVisible}
        handleDatesChange={handleDatesChange}
        focusedInput={focusedInput}
        setFocusedInput={setFocusedInput}
        renderDayContents={renderDayContents}
        numberOfMonths={numberOfMonths}
      />
      <section
        className="xs-section bg-inner flightsMainBody"
        style={{ background: "#f9f9f9" }}
      >
        <Container className="bodyDiv">
          <Row>
            {/* <div> */}
            <FilterBar
              showFilter={showFilter}
              type="OneWay"
              minFare={minFare}
              maxFare={maxFare}
              sliderValue={sliderValue}
              clearAllFilters={clearAllFilters}
              handleSliderChange={handleSliderChange}
              handledepTimeFilter={handledepTimeFilter}
              deptimeRange={deptimeRange}
              arrtimeRange={arrtimeRange}
              handlearrTimeFilter={handlearrTimeFilter}
              handleShowAllStops={handleShowAllStops}
              checkedStops={checkedStops}
              handleCheckedstops={handleCheckedstops}
              handleShowAllairlinenames={handleShowAllairlinenames}
              airlines={airlines}
              handleChecked={handleChecked}
              setShowFilter={setShowFilter}
              applyFilters={applyFilters}
            />

            <Col md={9} className="">
              <Col lg={12} className="ratio3_2">
                <a href="#" className="mobile-filter border-top-0">
                  <h5>latest filter</h5>
                  <img
                    src="https://rica-next-pixelstrapthemes.vercel.app/assets/images/icon/adjust.png"
                    className="img-fluid "
                    alt=""
                  />
                </a>
                <div className="top-bar-flight">
                  <div className="date-fare-slider">
                    {/* <div
                      className="slick-slider fare-6 slick-initialized"
                      dir="ltr"
                    > */}
                    {/* <RxCross1/> */}
                    {/* <button
                        type="button"
                        className="slick-arrow slick-prev"
                        style={{ display: "block" }}
                      >
                        {" "}
                        Previous
                      </button> */}
                    {/* <div className="slick-list">
                        <div
                          className="slick-track"
                          style={{
                            width: 4292,
                            opacity: 1,
                            transform: "translate3d(-812px, 0px, 0px)",
                          }}
                        > */}
                    <Slider {...settings}>
                      {generateCalendarItems()}
                      {/* <div>
                             
                                <a href="#">
                                  <div className="fare-box">
                                    <h5 className="date">
                                      {" "}
                                      {generateCalendarItems()}
                                    </h5>
                                    <h6 className="fare">₹250</h6>
                                  </div>
                                </a>
                             
                            </div> */}
                    </Slider>

                    {/* </div>
                      </div> */}
                    {/* <button
                        type="button"
                        data-role="none"
                        className="slick-arrow slick-next"
                        style={{ display: "block" }}
                      >
                        {" "}
                        Next
                      </button> */}
                    {/* </div> */}
                  </div>
                  <div className="fare-calender">
                    <div>
                      <i className="far fa-calendar-alt" />
                      <h6 className="title">fare calender</h6>
                    </div>
                    <div className="calender-external " />
                  </div>
                </div>
              </Col>
              <Col lg={12}>
                {/* <h1>
                  Timer:{" "}
                  {`${timer.minutes.toString().padStart(2, "0")}:${timer.seconds
                    .toString()
                    .padStart(2, "0")}`}
                </h1>
                {showModal && (
        <div className="modal">
          <div className="modal-content">
            <h2>Your time is over!</h2>
            <p>Please search again.</p>
            <button onClick={handleCloseModal}>Close</button>
          </div>
        </div>
      )} */}
                <div className="product-wrapper-grid special-section grid-box ">
                  <div
                    className="row content grid "
                    style={{ marginLeft: "0px" }}
                  >
                    <div className="flight-detail-sec">
                      <div
                        className="title-bar"
                        style={{ background: "#fffbfb" }}
                      >
                        <Row>
                          <Col xs={2}>
                            <p>Airline</p>
                          </Col>
                          <Col xs={5}>
                            <p>departure &amp; arrival</p>
                          </Col>
                          <Col xs={2}>
                            <p>price</p>
                          </Col>
                          <Col xs={3}>
                            <div
                              style={{
                                display: "flex",
                                gap: "4px",
                                justifyContent: "center",
                                alignItems: "center",
                              }}
                            >
                              <p style={{ fontSize: "12px" }}>NetFare : </p>
                              <div className="sfp-change-trip">
                                <div
                                  onClick={() => setNetFare(!netFare)}
                                  className={`sfp-trip ${
                                    netFare ? "active" : ""
                                  }`}
                                >
                                  Show
                                </div>
                                <div
                                  onClick={() => setNetFare(!netFare)}
                                  className={`sfp-trip ${
                                    netFare ? "" : "active"
                                  }`}
                                >
                                  Hide
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                      {filteredData.length !== 0 && !isLoading ? (
                        <div>
                          {filteredData.map((e) => (
                            <div
                              className="detail-bar ratio3_2"
                              key={e.ContractId}
                            >
                              <div className="detail-wrap wow fadeInUp">
                                <Row>
                                  <Col md={2}>
                                    <div className="logo-sec">
                                      <img
                                        src={`/Images/AirlineLogo/${e.AirSegments[0].AirlineCode}.gif`}
                                        className="img-fluid"
                                        alt=""
                                      />
                                      <span className="title">
                                        {e.AirSegments[0].AirlineName}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col md={5}>
                                    <div className="airport-part">
                                      <div className="airport-name">
                                        <h4>
                                          {" "}
                                          {new Date(
                                            e.AirSegments[0].DepartureDateTime
                                          ).toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: false,
                                          })}
                                        </h4>
                                        <h6>{e.AirSegments[0].Origen}</h6>
                                      </div>
                                      <div className="airport-progress">
                                        <div className="segment_duration_div">
                                          {e.AirSegments[0].Duration}
                                        </div>
                                        <i className="fas fa-plane-departure float-start" />
                                        <i className="fas fa-plane-arrival float-end" />
                                        <div className="stop">
                                          {" "}
                                          {e.AirSegments.length - 1 == 0
                                            ? "Non-Stop"
                                            : `${
                                                e.AirSegments.length - 1
                                              } Stops`}
                                        </div>
                                      </div>
                                      <div className="airport-name arrival">
                                        <h4>
                                          {" "}
                                          {new Date(
                                            e.AirSegments[0].ArrivalDateTime
                                          ).toLocaleTimeString([], {
                                            hour: "2-digit",
                                            minute: "2-digit",
                                            hour12: false,
                                          })}
                                        </h4>
                                        <h6>
                                          {" "}
                                          {
                                            e.AirSegments[
                                              e.AirSegments.length - 1
                                            ].Destination
                                          }
                                        </h6>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={3}>
                                    <div className="price">
                                      <div>
                                        <h4>
                                          <span
                                            style={{
                                              fontSize: "11px",
                                              color: "#000",
                                              fontWeight: "600",
                                            }}
                                          >
                                            {fares[e.FareType]}{" "}
                                          </span>{" "}
                                          &nbsp;
                                          <span style={{ fontSize: "16px" }}>
                                            ₹
                                            {e.AirlineFare.GrossFare +
                                              markup +
                                              agentmarkup}
                                          </span>
                                          <br />
                                          <span
                                            style={{
                                              marginTop: "5px",
                                              marginBottom: "3px",
                                              fontSize: "13px",
                                              color: "#000",
                                            }}
                                          >
                                            {netFare &&
                                              `₹${
                                                e.AirlineFare.NetFare +
                                                markup 
                                              }`}
                                          </span>
                                          {/* === true
                                            ? e.AirlineFare.NetFare
                                            : } */}
                                        </h4>
                                        {/* <h6
                                          style={{
                                            marginTop: "5px",
                                            marginBottom: "3px",
                                            fontSize: "13px",
                                          }}
                                        >
                                          {netFare &&
                                            `₹${
                                              e.AirlineFare.NetFare + markup
                                            }`}
                                        </h6> */}
                                        <span>
                                          {e.Refundable === true
                                            ? "Refundable"
                                            : "Non Refundable"}
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                  <Col md={2}>
                                    <div className="book-flight">
                                      <a href="">
                                        <button
                                          className="btn btn btn-solid color1 color1"
                                          type="button"
                                          onClick={() =>
                                            handlebookmodal(e.ContractId)
                                          }
                                        >
                                          book now
                                        </button>
                                      </a>
                                    </div>
                                  </Col>
                                  <Col lg={12}>
                                    <div className="flight_detail_detail">
                                      <div
                                        className="flight_detail_detail_sec"
                                        onClick={() => {
                                          handleClick(e.ContractId);
                                          // setCount(count + 1);
                                          // setActive(!active);
                                        }}
                                      >
                                        Flight Detail
                                      </div>
                                    </div>
                                    {activeId === e.ContractId && (
                                      <FlightListInfo
                                        idx={e.ContractId}
                                        markup={markup}
                                        agentmarkup={agentmarkup}
                                        flight={e}
                                        netFare={netFare}
                                        fareRules={fareRules}
                                      />
                                    )}
                                    {/* <div style={{
                                      paddingLeft: "35px",
                                      paddingY: "10px",
                                      marginTop: "5px",
                                      background: "#f9f5ff7d",
                                      cursor: "pointer",
                                    }}>
                                    <RxCross1/>
                                  </div> */}
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          ))}
                        </div>
                      ) : (
                        <div>
                          {errors ? (
                            <NotFound />
                          ) : (
                            <div>
                              <OneWaySkeleton />
                              <OneWaySkeleton />
                              <OneWaySkeleton />
                              <OneWaySkeleton />
                            </div>
                          )}
                        </div>
                      )}
                      {/* <div className="detail-bar">
                      <div className="detail-wrap wow fadeInUp">
                        <Row>
                          <Col md={2}>
                            <div className="logo-sec">
                              <img
                                src="https://rica-next-pixelstrapthemes.vercel.app/assets/images/flights/airlines/6.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span className="title">Cathay Pacific</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="airport-part">
                              <div className="airport-name">
                                <h4>08:00</h4>
                                <h6>DXB</h6>
                              </div>
                              <div className="airport-progress">
                                <i className="fas fa-plane-departure float-start" />
                                <i className="fas fa-plane-arrival float-end" />
                                <div className="stop">1 Stop</div>
                              </div>
                              <div className="airport-name arrival">
                                <h4>19:30</h4>
                                <h6>CDG</h6>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="price">
                              <div>
                                <h4>$3600.00</h4>
                                <span>non refundable</span>
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="book-flight">
                              <a href="">
                                <button
                                  className="btn btn btn-solid color1 color1"
                                  type="button"
                                >
                                  book now
                                </button>
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>{" "} */}
                      {/* <div className="detail-bar">
                      <div className="detail-wrap wow fadeInUp">
                        <Row>
                          <Col md={2}>
                            <div className="logo-sec">
                              <img
                                src="https://rica-next-pixelstrapthemes.vercel.app/assets/images/flights/airlines/6.png"
                                className="img-fluid"
                                alt=""
                              />
                              <span className="title">Cathay Pacific</span>
                            </div>
                          </Col>
                          <Col md={5}>
                            <div className="airport-part">
                              <div className="airport-name">
                                <h4>08:00</h4>
                                <h6>DXB</h6>
                              </div>
                              <div className="airport-progress">
                                <i className="fas fa-plane-departure float-start" />
                                <i className="fas fa-plane-arrival float-end" />
                                <div className="stop">1 Stop</div>
                              </div>
                              <div className="airport-name arrival">
                                <h4>19:30</h4>
                                <h6>CDG</h6>
                              </div>
                            </div>
                          </Col>
                          <Col md={2}>
                            <div className="price">
                              <div>
                                <h4>$3600.00</h4>
                                <span>non refundable</span>
                              </div>
                            </div>
                          </Col>
                          <Col md={3}>
                            <div className="book-flight">
                              <a href="">
                                <button
                                  className="btn btn btn-solid color1 color1"
                                  type="button"
                                >
                                  book now
                                </button>
                              </a>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div> */}
                    </div>
                  </div>
                </div>
              </Col>
            </Col>
          </Row>
          <div
            className={`applyFilter ${Visible && "show"}`}
            onClick={() => setShowFilter(true)}
          >
            <FaFilter /> Filter
          </div>
        </Container>
      </section>
    </div>
  );
};

export default OneWay;
