import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Breadcrumb,
  Col,
  Container,
  Row,
  Card,
  Button,
  Table,
  Accordion,
  Modal,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import FlightBookingChange from "./FlightBookingChange";
import { cities12 } from "../../Cities";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import ReactDOM from "react-dom";

const formatTime = (arrTime) => {
  const date = new Date(arrTime);
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const day = days[date.getDay()];
  const dateNum = String(date.getDate()).padStart(2, "0");
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  return `${day}, ${dateNum} ${month} ${year}`;
};

const FlightBookingDetail = () => {
  const { walletData } = useSelector((state) => state.auth);
  const convertToAmPm = (time) => {
    const [hours, minutes] = time.split(":").map(Number);
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12; // Convert 0 or 12 to 12
    return `${formattedHours}:${
      minutes < 10 ? `0${minutes}` : minutes
    } ${period}`;
  };
  const { id } = useParams(); // Get the id from URL parameters
  const [showCancelModal, setShow] = useState(false);
  const [showIssueModal, setShowIssueModal] = useState(false);
  const [showRuleModal, setShowRuleModal] = useState(false);
  const [flightBookingsData, setFlightBookingsData] = useState([]);
  const [filteredBooking, setFilteredBooking] = useState(null);
  const token = String(localStorage.getItem("token"));

  const handleCancelModalClose = () => setShow(false);
  const handleCancelModalShow = () => setShow(true);
  const handleRuleModalClose = () => {
    setShowRuleModal(false);
  };
  const handleIssueModalClose = () => setShowIssueModal(false);
  const handleIssueModalShow = () => setShowIssueModal(true);

  useEffect(() => {
    const fetchFlightBookingData = async () => {
      try {
        const response = await axios.get(
          "https://admin.yatriservice.com/api/details"
        );
        console.log("dghfytdftyfv", response);
        if (response.data.success) {
          console.log("bookings from db", response.data.data);
          setFlightBookingsData(response.data.data);
        } else {
          console.error(
            "Failed to fetch flight booking data:",
            response.data.message
          );
        }
      } catch (error) {
        console.error("Error fetching flight booking data:", error);
      }
    };

    fetchFlightBookingData();
  }, []);

  useEffect(() => {
    if (flightBookingsData.length > 0) {
      const booking = flightBookingsData.find(
        (booking) => booking.id === parseInt(id)
      );
      setFilteredBooking(booking);
    }
  }, [flightBookingsData, id]);

  const [fromCurrency, setFromCurrency] = useState("INR");
  const [toCurrency, setToCurrency] = useState("USD");
  const [exchangeRate, setExchangeRate] = useState(null);

  const [fromCurrency2, setFromCurrency2] = useState("IRR");
  const [toCurrency2, setToCurrency2] = useState("INR");
  const [exchangeRate2, setExchangeRate2] = useState(null);

  useEffect(() => {
    const URL = `https://heritage.travelsdata.com/api/currency_convert/${fromCurrency}/${toCurrency}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        // console.log("ratetetesafdfs", rate);
        setExchangeRate(rate);
      } catch (error) {
        console.error("Error fetching exchange rate:", error);
      }
    };
    if (fromCurrency !== toCurrency) {
      getExchangeRate(URL);
    } else {
      setExchangeRate(1);
    }
  }, []);

  useEffect(() => {
    const URL = `https://heritage.travelsdata.com/api/currency_convert/${fromCurrency2}/${toCurrency2}`;
    const getExchangeRate = async () => {
      try {
        const response = await axios.get(URL);
        const rate = response.data.data.Conversion_Rate;
        console.log("ratetetesafdfs", rate);
        setExchangeRate2(rate);
      } catch (error) {
        console.error("Error fetching exchange rate2:", error);
      }
    };
    if (fromCurrency2 !== toCurrency2) {
      getExchangeRate(URL);
    } else {
      setExchangeRate2(1);
    }
  }, []);

  const handleChangeCurrency = (amount) => {
    if (!isNaN(amount) && exchangeRate) {
      const convertedValue = amount * exchangeRate;
      return convertedValue.toFixed(2);
    }
  };

  const handleChangeCurrency2 = (amount) => {
    if (!isNaN(amount) && exchangeRate2) {
      const convertedValue = amount * exchangeRate2;
      return convertedValue.toFixed(2);
    }
  };
  const formatDateTime = (dateTimeString) => {
    const dateObject = new Date(dateTimeString);
    let hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12 || 12;
    const day = String(dateObject.getDate()).padStart(2, "0");
    const month = String(dateObject.getMonth() + 1).padStart(2, "0");
    const year = dateObject.getFullYear();
    const formattedTime = `${hours}:${String(minutes).padStart(
      2,
      "0"
    )} ${ampm}`;
    const formattedDate = `${day}-${month}-${year}`;
    return `${formattedTime} ${formattedDate}`;
  };

  const titleMapping = {
    0: "Mr",
    1: "Mrs",
    2: "Ms",
    3: "Miss",
    4: "Mstr",
  };

  const paxtypeMapping = {
    1: "Adult",
    2: "Child",
    3: "Infant",
  };

  const statusMapping = {
    1: "Success",
    // 11: "Pending",
    // 12: "WaitList",
    // 20: "Ticket In Process",
    // 2: "Ticketed",
    // 22: "Ticketed-Changed",
    // 23: "Ticketed-Schedule Changed",
    // 24: "Ticketed-Cancelled",
    // 25: "Ticketed-Void",
    0: "Failed",
    // 40: "Not Booked (Exception)",
    // 41: "Not Booked (Gateway)",
    // 42: "Duplicate",
  };

  const TypeOfCabinMap = {
    0: "Economy",
    3: "Premium Economy",
    2: "Business",

    1: "First",
  };

  const statusMappingTBO = {
    0: "Failed",
    1: "Successful",
    2: "Not Saved",
    3: "Not Created",
    4: "Not Allowed",
    5: "In Progress",
    6: "Ticket Already Created",
    8: "Price Changed",
    9: "Other Error",
  };

  const ticketStatusMapping = {
    1: "Ticket",
    2: "Refund",
    3: "Void",
    4: "Unknown",
  };

  const cabinMapping = {
    1: "Economy",
    2: "Premium Economy",
    3: "Business",
    4: "Premium Business",
    5: "First",
    6: "Premium First",
    100: "",
  };

  const navigate = useNavigate();

  // const changeReq = () => {
  //   navigate("/agent/bookings/flight/changebooking");
  // };

  const sessionId = localStorage.getItem("sessionId");

  console.log("bookingss", flightBookingsData);
  console.log("Selected booking", filteredBooking);

  const cancelFlightParto = async (uniqueId) => {
    if (filteredBooking.offer_type === "parto CRS") {
      try {
        const response = await axios.post(
          "https://heritage.travelsdata.com/api/air_cancel",
          {
            UniqueId: uniqueId,
            SessionId: sessionId,
          }
        );
        console.log("Cancellation Response:", response.data);
        if (response.data.success) {
          alert("Flight cancellation successful.");
        } else {
          alert("Flight cancellation failed: " + response.data.message);
        }
      } catch (error) {
        console.error("Error cancelling flight:", error);
        alert("Error cancelling flight: " + error.message);
      }
    }
  };

  const IssueFlightParto = async (uniqueId) => {
    if (filteredBooking.offer_type === "parto CRS") {
      try {
        const response = await axios.post(
          "https://heritage.travelsdata.com/api/air_order_ticket",
          {
            UniqueId: uniqueId,
            SessionId: sessionId,
          }
        );
        console.log("Issue Reservation Response:", response.data);
        if (response.data.success) {
          alert("Flight Issue Reservation successful.");
        } else {
          alert("Flight Issue Reservation failed: " + response.data.message);
        }
      } catch (error) {
        console.error("Error Issue Reservation flight:", error);
        alert("Error Issue Reservation flight: " + error.message);
      }
    }
  };

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    priority: "",
    subject: "",
    type: "",
    remarks: "",
  });

  //   {
  //     "booking_detail_id": filteredBooking.id,
  // "email": walletData.Phone,
  //     "phone": walletData.Email,

  //     "priority": "Urgent",
  //     "subject": "Change Request",
  //     "type": "Change/Request",
  // "remarks": "Lorem ipsum dolor sit, amet consectetur adipisicing elit. Saepe soluta asperiores ea consequuntur.",

  //
  // }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const finalData = {
      ...formData,
      booking_detail_id: filteredBooking.id,
      email: walletData.Email,
      phone: walletData.Phone,
    };
    // Handle form submission
    console.log("Form submitted", finalData);
    // Make API call or further processing with finalData
    try {
      const response = await axios.post(
        "https://heritage.travelsdata.com/api/change-request",
        finalData
      );
      console.log("Form submitted successfully", response.data);
      // Handle the successful response
      alert("Request submitted successfully!");
      handleCloseModal();
    } catch (error) {
      console.error("There was an error submitting the form!", error);
      // Handle the error response
      alert("Failed to submit the request. Please try again.");
    }
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const releasePNRTBO = async () => {
    console.log("release pnr called");
    try {
      const response = await axios.post(
        "https://heritage.travelsdata.com/api/flight-release-pnr",
        {
          EndUserIp: "192.168.11.58",
          TokenId: token,
          BookingId: filteredBooking.booking_id,
          Source:
            filteredBooking.booking_response.Response.FlightItinerary.Source,
        }
      );
      console.log("Cancellation Response:", response.data);
      if (response.data.success) {
        alert("Flight cancellation successful.");
      } else {
        alert("Flight cancellation failed: " + response.data.message);
      }
    } catch (error) {
      console.error("Error cancelling flight:", error);
      alert("Error cancelling flight: " + error.message);
    }
  };

  const IssueFlightTBO = async () => {
    if (filteredBooking.offer_type === "parto CRS") {
      try {
        const response = await axios.post(
          "https://heritage.travelsdata.com/api/flight-ticket",
          {
            EndUserIp: "192.168.10.10",
            PNR: filteredBooking.pnr,
            BookingId: filteredBooking.booking_id,
            TokenId: token,
            TraceId: filteredBooking.trace_id,
          }
        );
        console.log("Issue Reservation Response:", response.data);
        if (response.data.success) {
          alert("Flight Issue Reservation successful.");
        } else {
          alert("Flight Issue Reservation failed: " + response.data.message);
        }
      } catch (error) {
        console.error("Error Issue Reservation flight:", error);
        alert("Error Issue Reservation flight: " + error.message);
      }
    }
  };

  const [bookingDetailsTJ, setBookingDetailsTJ] = useState(null);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [bookingDetailsParto, setBookingDetailsParto] = useState(null);

  const downloadTicketParto = async (bookingId) => {
    console.log("booking id Parto while downloading ticket", bookingId);

    setBookingDetailsParto(filteredBooking.booking_response);
    console.log("booking details parto", filteredBooking.booking_response);
    generatePdf(filteredBooking, "P");
  };

  const generatePdf = (bookingDetails, API) => {
    const element = (
      <TicketTemplate bookingDetailss={bookingDetails} API={API} />
    );
    const container = document.createElement("div");
    document.body.appendChild(container);
    ReactDOM.render(element, container);

    html2canvas(container.querySelector(".ticketdata"), {
      scale: 2, // Increase the scale for better quality
      useCORS: true, // Use CORS if necessary
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the PDF page
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const aspectRatio = imgWidth / imgHeight;

      // Adjust the height to fit the content on a single page
      const scaledWidth = pdfWidth;
      const scaledHeight = pdfWidth / aspectRatio;

      // If the content is taller than the PDF page, scale it down
      let yOffset = 0;
      if (scaledHeight > pdfHeight) {
        const scale = pdfHeight / scaledHeight;
        yOffset = 0; // No offset needed since we're scaling to fit height
      } else {
        yOffset = (pdfHeight - scaledHeight) / 5; // Center vertically
      }

      // Center the content vertically and draw the image
      pdf.addImage(imgData, "PNG", 0, yOffset, scaledWidth, scaledHeight);

      // Save the PDF
      pdf.save("ticket.pdf");

      // Clean up
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    });
  };

  const TicketTemplate = ({ bookingDetailss, API }) => {
    console.log("booking detailsssss", bookingDetailss);

    return (
      <section className="container-fluid ticketdata">
        <div className="container" style={{ width: "100%" }}>
          <div className="maindiv">
            <div className="top-part">
              <div className="logo">
                <img
                  src="/Images/yatri-service.png"
                  alt="Logo"
                  width={250}
                  height={120}
                />
              </div>
              <div className="ticket-details">
                <div className="details">
                  <h4>
                    Ticket-
                    {
                      statusMapping[
                        bookingDetailss.booking_response.ResponseStatus
                      ]
                    }
                  </h4>
                  <p>
                    <span>Refrence ID:</span> {bookingDetailss.Refid}
                  </p>

                  <p>
                    <span>Airline PNR:</span>
                    {bookingDetailss.booking_response.AirlinePnr}
                  </p>
                </div>
              </div>
            </div>
            <div className="passenger-details">
              <h4>
                {" "}
                Passenger
                {/* - {passengerDetails.adults} Adult {passengerDetails.children} Child  */}
              </h4>
              <table className="table table-bordered text-center">
                <thead>
                  <tr>
                    <th scope="col">Passenger</th>
                    <th scope="col">Airline</th>
                    <th scope="col">Status</th>
                    <th scope="col">Sector</th>
                    <th scope="col">Airline PNR</th>
                    <th scope="col">Ticket Number</th>
                    <th scope="col">Seat No.</th>
                  </tr>
                </thead>
                <tbody>
                  {bookingDetailss &&
                    bookingDetailss.booking_response.Flightpassenger.map(
                      (passenger, index) => (
                        <tr key={index}>
                          <td>
                            {passenger.FirstName} {passenger.LastName}
                          </td>
                          <td>
                            {
                              bookingDetailss.booking_response.Contracts[0]
                                .AirSegments[0].AirlineCode
                            }
                          </td>
                          <td>
                            {
                              statusMapping[
                                bookingDetailss.booking_response.ResponseStatus
                              ]
                            }
                          </td>

                          <td>
                            {bookingDetailss.booking_response.Contracts.map(
                              (items) => (
                                <p>
                                  {items.AirSegments.map((data) => (
                                    <p>
                                      {data.Origen} - {data.Destination},
                                    </p>
                                  ))}
                                </p>
                              )
                            )}
                          </td>
                          <td>{bookingDetailss.booking_response.AirlinePnr}</td>
                          <td>{passenger.TicketNumber}</td>
                          <td>{passenger.SeatCode}</td>
                        </tr>
                      )
                    )}
                </tbody>
              </table>
            </div>
            <div className="flight-details">
              <h4> Flight Details </h4>
              <table className="table text-center table-bordered">
                <thead className="table-primary">
                  <tr>
                    <th scope="col" colSpan="2">
                      Airline Details
                    </th>
                    <th scope="col">Departure</th>
                    <th scope="col">Arrival</th>
                    <th scope="col">Duration</th>
                  </tr>
                </thead>
                {bookingDetailss &&
                  bookingDetailss.booking_response.Contracts.map((data) => (
                    <tbody>
                      {data.AirSegments.map((item) => (
                        <tr style={{ background: "#6e25c129" }}>
                          <td>
                            <i
                              className="fa-solid fa-plane-circle-check fa-fw"
                              style={{ color: "#8b3eea" }}
                            ></i>
                          </td>
                          <td>
                            <p>
                              <span style={{ fontWeight: 600 }}>
                                {/* {detail.fD.aI.name} */}
                              </span>
                              <br />
                              {item.AirlineCode} {item.FlightNumber} <br />
                              Cabin Class: (
                              {item.TypeOfClass === 0 && "Economy"}
                              {item.TypeOfClass === 1 && "First"}
                              {item.TypeOfClass === 2 && "Business"}
                              {item.TypeOfClass === 3 &&
                                "Premium_Economy"}) <br />
                              <span
                                style={{
                                  fontSize: "13px",
                                  color: "#8b3eea",
                                  fontWeight: "600",
                                }}
                              >
                                ({item.SegmentType})
                              </span>
                            </p>
                          </td>
                          <td>
                            <span>
                              {item.SourceAirport.city_name} ({item.Origen})
                            </span>
                            <br />
                            <p style={{ marginBottom: "0px" }}>
                              <span style={{ fontWeight: 600 }}>
                                {item.DepartureTime === null ||
                                item.DepartureTime === ""
                                  ? item.DepartureTime
                                  : convertToAmPm(item.DepartureTime)}
                                <br />
                                {item.DepartureDateTime}
                                {/* {formatTime(item.DepartureDateTime)} &nbsp;
                                {new Date(
                                  item.DepartureDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })} */}
                              </span>
                            </p>
                          </td>
                          <td>
                            <span>
                              {item.DestinationAirport.city_name} (
                              {item.Destination}){" "}
                            </span>
                            <br />
                            <p style={{ marginBottom: "0px" }}>
                              <span style={{ fontWeight: 600 }}>
                                {item.ArrivalTime === null ||
                                item.ArrivalTime === ""
                                  ? item.ArrivalTime
                                  : convertToAmPm(item.ArrivalTime)}{" "}
                                <br />
                                {item.ArrivalDateTime}
                                {/* {formatTime(item.ArrivalDateTime)} &nbsp;
                                {new Date(
                                  item.ArrivalDateTime
                                ).toLocaleTimeString([], {
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: false,
                                })} */}
                              </span>
                              {/* {detail.aa.name} */}
                            </p>
                          </td>
                          <td>{item.Duration}</td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
              </table>
            </div>

            <div className="baggage-flight">
              <h4> Baggage Info </h4>
              <table className="table text-center table-bordered">
                <thead className="table-primary">
                  <tr>
                    <th scope="col" rowSpan="2">
                      Airline
                    </th>
                    <th scope="col" rowSpan="2">
                      Sector
                    </th>
                    <th scope="col" colSpan="2">
                      Baggage
                    </th>
                  </tr>
                  <tr>
                    <th scope="col">Check-In</th>
                    <th scope="col">Cabin</th>
                  </tr>
                </thead>
                {bookingDetailss &&
                  bookingDetailss.booking_response.Contracts.map((data) => (
                    <tbody>
                      {data.AirSegments.map((item) => (
                        <tr>
                          <td>
                            {item.AirlineCode}
                            <span
                              style={{
                                fontSize: "12px",
                                marginLeft: "4px",
                                color: "#8b3eea",
                                fontWeight: "600",
                              }}
                            >
                              ({item.SegmentType})
                            </span>
                          </td>
                          <td>
                            ( {item.Origen}-{item.Destination})
                          </td>
                          <td>{item.BaggageAllowed.CheckInBaggage}</td>
                          <td>{item.BaggageAllowed.HandBaggage}</td>
                        </tr>
                      ))}
                    </tbody>
                  ))}
              </table>
            </div>

            {
              <div className="flight-details">
                <h4> Fare Info </h4>
                <table className="table text-center table-bordered">
                  <thead className=" table-primary">
                    <tr>
                      <th scope="col"> Base Fare</th>
                      <th scope="col">Taxes & Surcharges</th>
                      <th scope="col">Discount</th>
                      <th scope="col">Service Fee</th>
                      <th scope="col">Excess Baggage</th>
                      <th scope="col">Meal</th>
                      <th scope="col">Seat Charges</th>
                      {/* <th scope="col">Other Charges</th> */}
                      <th scope="col">Total Fare</th>
                    </tr>
                  </thead>
                  {/* <tbody>
                  {bookingDetailsParto && (
                    <tr>
                      <td>
                        {" "}
                        ${" "}
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            bookingDetailsParto.TravelItinerary.ItineraryInfo
                              .ItineraryPricing.BaseFare
                          )
                        )}
                      </td>
                      <td>
                        ${" "}
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            bookingDetailsParto.TravelItinerary.ItineraryInfo
                              .ItineraryPricing.TotalTax +
                              bookingDetailsParto.TravelItinerary
                                .ItineraryInfo.ItineraryPricing.ServiceTax
                          )
                        )}
                      </td>
                      <td>$ 0</td>
                      <td>$ 0</td>
                      <td>$ 0</td>
                      <td>$ 0</td>
                      <td>$ 0</td>
                      <td>$ 0</td>
                      <td>
                        ${" "}
                        {handleChangeCurrency(
                          handleChangeCurrency2(
                            bookingDetailsParto.TravelItinerary.ItineraryInfo
                              .ItineraryPricing.TotalFare
                          )
                        )}
                      </td>
                    </tr>
                  )}
                </tbody> */}
                  <tbody>
                    {bookingDetailss && (
                      <tr>
                        <td>
                          {" "}
                          ₹{/* {overallBaseFare}  */}
                          {!bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.BaseFare}
                          {bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.BaseFare +
                              bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.BaseFare}
                          {/* {
                                bookingDetails.TravelItinerary
                                  .ItineraryInfo.ItineraryPricing.BaseFare
                              } */}
                        </td>
                        <td>
                          ₹{" "}
                          {!bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.TaxFare}
                          {bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.TaxFare +
                              +(+bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.TaxFare)}
                          {/* {
                                bookingDetails.TravelItinerary
                                  .ItineraryInfo.ItineraryPricing.TotalTax +
                                  bookingDetails.TravelItinerary
                                    .ItineraryInfo.ItineraryPricing.ServiceTax
                              } */}
                        </td>
                        <td>
                          ₹{" "}
                          {!bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.Discount}
                          {bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.Discount +
                              bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.Discount}
                        </td>
                        <td>
                          ₹{" "}
                          {!bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.ServiceCharge}
                          {bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.ServiceCharge +
                              bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.ServiceCharge}
                        </td>
                        <td>₹ 0</td>
                        <td>₹ 0</td>
                        <td>₹ 0</td>
                        {/* <td>
                            ₹ {!bookingDetails.Contracts[1] &&  + (money && parseInt(money))}
                            {bookingDetails.Contracts[1] &&
                               +  + (money && parseInt(money))}{" "}
                          </td> */}
                        <td>
                          ₹{" "}
                          {!bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.GrossFare +
                              bookingDetailss.booking_response.Contracts[0]
                                .AirlineFare.ServiceCharge}
                          {bookingDetailss.booking_response.Contracts[1] &&
                            bookingDetailss.booking_response.Contracts[0]
                              .AirlineFare.GrossFare +
                              bookingDetailss.booking_response.Contracts[0]
                                .AirlineFare.ServiceCharge +
                              bookingDetailss +
                              bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.GrossFare +
                              bookingDetailss.booking_response.Contracts[1]
                                .AirlineFare.ServiceCharge}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            }

            <div className="personal-details">
              <h4> Company Contact Details </h4>
              <table className="table table-bordered">
                <tbody>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Name</td>
                    <td>Abasin Tourist & Travel Agency</td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Address</td>
                    <td>
                      Shah-re-naw Ansari square, kolola pushta road , Kabul,
                      Afghanistan, AF-
                    </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Contact</td>
                    <td>93779897682 | 0093785336735 </td>
                  </tr>
                  <tr>
                    <td style={{ fontWeight: 700 }}>Email</td>
                    <td>support@abasinexpress.com</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div className="information-details">
              <h5>Passport/Visa/Health</h5>
              <ul
                style={{
                  listStyleType: "disc",
                  paddingInlineStart: "20px",
                }}
              >
                <li>
                  {" "}
                  Please ensure that you have all the required travel documents
                  for your entire journey i.e., valid passport & necessary
                  visas, and that you have had the recommended inoculations for
                  your destination(s).
                </li>
                <li>
                  {" "}
                  All passengers, including children and infants, have to
                  present their valid ID proof at the time of check-in.
                </li>
                <li>
                  {" "}
                  We recommend you check-in at least 3 hours prior to departure
                  of your domestic flight and 4 hours prior to your
                  international flight
                </li>
                <li>
                  Carriage and other facilities provided by the carrier are
                  subject to their Terms and Condition. We are not liable for
                  missing any facility of the carrier.
                </li>
                <li>
                  Recheck your baggage with your respective airline before
                  traveling for a hassle-free travel experience.
                </li>
                <li>
                  Group Booking Rules will be applicable if passengers are 9 or
                  more in numbers.
                </li>
                <li>
                  Company is not responsible for any delay or cancellation of
                  flights from airline's end.
                </li>
                <li>
                  Unaccompanied Child: Children below the age of 12 will not be
                  accepted for carriage unless they are accompanied by a person
                  of at least 18 years of age. Such child/children must be
                  seated next to the accompanying adult. The accompanying adult
                  is solely responsible for the well-being of the child/children
                  traveling together with him/her. This also includes ensuring
                  that seats are booked to ensure child/children and an
                  accompanying adult are seated together.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  };

  const downloadTicketInvoice = (filteredBooking) => {
    // const selected_booking = filteredData.filter(
    //   (bookingg) => bookingg.id === booking
    // );
    // console.log("bookingdvvdjgdfgyvbvdv", booking);
    generatePdfs(filteredBooking);
  };

  const generatePdfs = (filteredData) => {
    const element = <InvoiceTemplate item={filteredData} />;
    const container = document.createElement("div");
    document.body.appendChild(container);
    ReactDOM.render(element, container);

    html2canvas(container.querySelector(".invoice-ticket"), {
      scale: 2, // Increase the scale for better quality
      useCORS: true, // Use CORS if necessary
    }).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");

      // Adjust PDF size to A5 (148 x 210 mm)
      const pdf = new jsPDF("p", "mm", [165, 250]);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();

      // Calculate the dimensions to fit the PDF page
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const aspectRatio = imgWidth / imgHeight;

      // Adjust the height to fit the content on a single page
      const scaledWidth = pdfWidth;
      const scaledHeight = pdfWidth / aspectRatio;

      // Set yOffset to 0 to start content at the top
      let yOffset = 0;

      // Center the content vertically and draw the image
      pdf.addImage(imgData, "PNG", 0, yOffset, scaledWidth, scaledHeight);

      // Save the PDF
      pdf.save("invoice.pdf");

      // Clean up
      ReactDOM.unmountComponentAtNode(container);
      document.body.removeChild(container);
    });
  };

  const InvoiceTemplate = ({ item, API }) => {
    return (
      <>
        <div
          className="invoice-ticket"
          style={{
            maxWidth: 1000,
            margin: "15px auto",
            width: "100%",
            padding: 25,
            fontSize: 14,
            background: "#fff",
          }}
        >
          <table
            border={0}
            cellPadding={0}
            cellSpacing={0}
            style={{
              width: "100%",
              borderSpacing: 0,
              borderCollapse: "colapse",
            }}
          >
            <tbody>
              <tr>
                <td style={{ border: "0px none", paddingBottom: 15 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>From</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 16 }}>
                          <b> YatriServices </b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Address: </strong>
                          Delhi, India
                          <br />
                          +91-9721647550
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email: </strong> Support@yatriservice.com
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>phone:</strong> +91-9721647550
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
                <td
                  style={{
                    textAlign: "right",
                    border: "0px none",
                    paddingBottom: 15,
                  }}
                >
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                    }}
                  >
                    <tbody>
                      <tr>
                        <td>
                          <b>To</b>
                        </td>
                      </tr>
                      <tr>
                        <td style={{ fontSize: 16 }}>
                          <b> {walletData && walletData.CompanyName}</b>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>phone:</strong>{" "}
                          {walletData && walletData.Phone}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <strong>Email: </strong>{" "}
                          {walletData && walletData.Email}
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <strong>Airline OB PNR :</strong>{" "}
                          {item.booking_response.AirlinePnr}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              {item.booking_response.Contracts[0] && (
                <>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: "7px 5px",
                        background: "#903ff3ee",
                        borderLeft: "3px solid #e3111a",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      Out Bound Detail
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ paddingBottom: 15, paddingTop: 5 }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderSpacing: 0,
                          borderCollapse: "collapse",
                          border: "1px solid #000",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Passenger
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Sector
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Travel on
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Airline PNR
                            </th>
                          </tr>
                        </thead>
                        {item.booking_response.Contracts[0] && (
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {item.booking_response.Contracts[0] &&
                                  item.booking_response.Flightpassenger.map(
                                    (item) => (
                                      <p>
                                        {item.FirstName} {item.LastName}
                                      </p>
                                    )
                                  )}
                              </td>
                              {item.booking_response.Contracts[0] &&
                                item.booking_response.Contracts[0].AirSegments.map(
                                  (items) => (
                                    <td
                                      style={{
                                        borderLeft: "1px solid #000",
                                        padding: "7px 12px",
                                        borderRight: "1px solid #000",
                                      }}
                                    >
                                      {items.Origen} - {items.Destination}
                                    </td>
                                  )
                                )}
                              {item.booking_response.Contracts[0].AirSegments.map(
                                (items) => (
                                  <td
                                    style={{
                                      borderLeft: "1px solid #000",
                                      padding: "7px 12px",
                                      borderRight: "1px solid #000",
                                    }}
                                  >
                                    {" "}
                                    {formatDateTime(items.DepartureDateTime)}
                                  </td>
                                )
                              )}
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {" "}
                                {item.booking_response.AirlinePnr}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </td>
                  </tr>
                </>
              )}
              {item.booking_response.Contracts[1] && (
                <>
                  <tr>
                    <td
                      colSpan={2}
                      style={{
                        padding: "7px 5px",
                        background: "#903ff3",
                        borderLeft: "3px solid #e3111a",
                        color: "#fff",
                        fontSize: "18px",
                        fontWeight: "600",
                      }}
                    >
                      In Bound Detail
                    </td>
                  </tr>
                  <tr>
                    <td
                      colSpan={2}
                      style={{ paddingBottom: 15, paddingTop: 5 }}
                    >
                      <table
                        style={{
                          width: "100%",
                          borderSpacing: 0,
                          borderCollapse: "collapse",
                          border: "1px solid #000",
                        }}
                      >
                        <thead>
                          <tr>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Passenger
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Sector
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Travel on
                            </th>
                            <th
                              style={{
                                borderLeft: "1px solid #000",
                                padding: "7px 12px",
                                backgroundColor: "#f3f3f3",
                                borderBottom: "1px solid #000",
                                borderRight: "1px solid #000",
                              }}
                            >
                              Airline PNR
                            </th>
                          </tr>
                        </thead>
                        {item.booking_response.Contracts[1] && (
                          <tbody>
                            <tr>
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {item.booking_response.Contracts[1] &&
                                  item.booking_response.Flightpassenger.map(
                                    (item) => (
                                      <p>
                                        {item.FirstName} {item.LastName}
                                      </p>
                                    )
                                  )}
                              </td>
                              {item.booking_response.Contracts[1] &&
                                item.booking_response.Contracts[1].AirSegments.map(
                                  (items) => (
                                    <td
                                      style={{
                                        borderLeft: "1px solid #000",
                                        padding: "7px 12px",
                                        borderRight: "1px solid #000",
                                      }}
                                    >
                                      {items.Origen} - {items.Destination}
                                    </td>
                                  )
                                )}
                              {item.booking_response.Contracts[1].AirSegments.map(
                                (items) => (
                                  <td
                                    style={{
                                      borderLeft: "1px solid #000",
                                      padding: "7px 12px",
                                      borderRight: "1px solid #000",
                                    }}
                                  >
                                    {" "}
                                    {formatDateTime(items.DepartureDateTime)}
                                  </td>
                                )
                              )}
                              <td
                                style={{
                                  borderLeft: "1px solid #000",
                                  padding: "7px 12px",
                                  borderRight: "1px solid #000",
                                }}
                              >
                                {" "}
                                {item.booking_response.AirlinePnr}
                              </td>
                            </tr>
                          </tbody>
                        )}
                      </table>
                    </td>
                  </tr>
                </>
              )}
              <tr>
                <td
                  colSpan={2}
                  style={{
                    padding: "7px 5px",
                    background: "#903ff3",
                    color: "#fff",
                    borderLeft: "3px solid #e3111a",
                    fontSize: "18px",
                    fontWeight: "600",
                  }}
                >
                  Fare Details
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 15, paddingTop: 5 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          YQ
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Taxes
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Discount
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Service Fee{" "}
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Basefare
                        </th>
                        {/* <th style="border-left:1px solid #000; padding: 7px 12px;background-color: #f3f3f3;border-bottom: 1px solid #000;border-right: 1px solid #000;">Netfare</th> */}
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          GrossFare
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Markup
                        </th>
                        <th
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#f3f3f3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Total Fare
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {item && (
                        <tr>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            {" "}
                            ₹
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .YQTx}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .YQTx +
                                item.booking_response.Contracts[1].AirlineFare
                                  .YQTx}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .TaxFare}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .TaxFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .TaxFare}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .Discount}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .Discount +
                                item.booking_response.Contracts[1].AirlineFare
                                  .Discount}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                                item.booking_response.Contracts[1].AirlineFare
                                  .ServiceCharge}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            {" "}
                            ₹
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .BaseFare}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .BaseFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .BaseFare}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge +
                                item.booking_response.Contracts[1].AirlineFare
                                  .GrossFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .ServiceCharge}
                          </td>
                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹ 0
                          </td>

                          <td
                            style={{
                              borderLeft: "1px solid #000",
                              padding: "7px 12px",
                              borderRight: "1px solid #000",
                            }}
                          >
                            ₹{" "}
                            {!item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge}
                            {item.booking_response.Contracts[1] &&
                              item.booking_response.Contracts[0].AirlineFare
                                .GrossFare +
                                item.booking_response.Contracts[0].AirlineFare
                                  .ServiceCharge +
                                item.booking_response.Contracts[1].AirlineFare
                                  .GrossFare +
                                item.booking_response.Contracts[1].AirlineFare
                                  .ServiceCharge}
                          </td>
                        </tr>
                      )}
                      {/* <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 0
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹ 651
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹ 0
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          10
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 2957
                        </td>
  
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 3608
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 100.00
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹ 3718
                        </td>
                      </tr> */}
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 15 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            backgroundColor: "#903ff3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                            color: "#fff",
                            textAlign: "center",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          (Reimbursement of air ticket issued by airlines)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Tax And Additional Charge
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .ServiceCharge +
                              item.booking_response.Contracts[0].AirlineFare
                                .TaxFare}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .TaxFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .TaxFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Gross Total{" "}
                        </td>
                        <td
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                          }}
                        >
                          {" "}
                          ₹{" "}
                          {!item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge}
                          {item.booking_response.Contracts[1] &&
                            item.booking_response.Contracts[0].AirlineFare
                              .GrossFare +
                              item.booking_response.Contracts[0].AirlineFare
                                .ServiceCharge +
                              item.booking_response.Contracts[1].AirlineFare
                                .GrossFare +
                              item.booking_response.Contracts[1].AirlineFare
                                .ServiceCharge}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td colSpan={2} style={{ paddingBottom: 15 }}>
                  <table
                    style={{
                      width: "100%",
                      borderSpacing: 0,
                      borderCollapse: "collapse",
                      border: "1px solid #000",
                    }}
                  >
                    <thead>
                      <tr>
                        <th
                          colSpan={2}
                          style={{
                            borderLeft: "1px solid #000",
                            padding: "7px 12px",
                            color: "#fff",
                            backgroundColor: "#903ff3",
                            borderBottom: "1px solid #000",
                            borderRight: "1px solid #000",
                            textAlign: "left",
                            fontSize: "18px",
                            fontWeight: "600",
                          }}
                        >
                          Terms
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Without original invoice no refund is permissible.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Interest @ 24% will be charged on delayed payment.
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Cheque to be drawn in favour of "company name".
                        </td>
                      </tr>
                      <tr>
                        <td
                          style={{
                            padding: "3px 3px",
                            borderRight: "1px solid #000",
                          }}
                        >
                          Kindly check all details carefully to avoid
                          un-necessary complications.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </td>
              </tr>
              <tr>
                <td style={{ padding: "3px 3px", fontSize: 13 }}>
                  Receiver's Signature
                </td>
                <td
                  style={{
                    padding: "3px 3px",
                    fontSize: 13,
                    textAlign: "right",
                  }}
                >
                  Authorized Signatory
                </td>
              </tr>
              <tr>
                <td
                  colSpan={2}
                  style={{
                    textAlign: "center",
                    fontSize: 13,
                    borderTop: "1px solid #000",
                    paddingTop: 2,
                  }}
                >
                  This is a Computer generated document and does not require any
                  signature.
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div style={{ textAlign: "center" }}>
          <div className="" style={{ paddingTop: 10, paddingBottom: 15 }}>
            <div className="btn-group">
              <button onclick="window.close()" className="btn btn-danger">
                <span className="glyphicon glyphicon-remove" /> Close{" "}
              </button>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div
      className="content-wrapper content-wrapperrs"
      // style={{ minHeight: "531px", margin: "15px" }}
    >
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Booking detail</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item
                    linkAs={Link}
                    linkProps={{ to: "/agent/bookings" }}
                  >
                    <span style={{ color: "#fff" }}>Home</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Booking detail</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>

        {filteredBooking && (
          <>
            <section className="content">
              <Container fluid>
                <Row>
                  <Col md={8}>
                    <div
                      className="card-tools card_tools mb-3"
                      style={{ textAlign: "right" }}
                    >
                      <Button
                        className="btn btn-theme btn-rounded btn-sm"
                        style={{ marginInline: "5px" }}
                        onClick={() => downloadTicketParto(filteredBooking)}
                      >
                        Download Ticket
                      </Button>
                      <Button
                        className="btn btn-theme btn-rounded btn-sm"
                        style={{ marginInline: "5px" }}
                        onClick={() => downloadTicketInvoice(filteredBooking)}
                      >
                        Invoice
                      </Button>
                      <Button
                        className="btn btn-theme btn-rounded btn-sm"
                        style={{ marginInline: "5px" }}
                        onClick={() => setShowRuleModal(true)}
                      >
                        Fare Rule
                      </Button>

                      {/* <Button
                        className="btn btn-theme btn-rounded btn-sm"
                        style={{
                          marginInline: "5px",
                          opacity:
                            filteredBooking.booking_response.Status !== 10 &&
                            filteredBooking.booking_response.Status !== 11 &&
                            filteredBooking.booking_response.Status !== 12 &&
                            filteredBooking.booking_response.Status !== 20
                              ? 0.1
                              : 1,
                        }}
                        disabled={
                          filteredBooking.booking_response.Status !== 10 &&
                          filteredBooking.booking_response.Status !== 11 &&
                          filteredBooking.booking_response.Status !== 12 &&
                          filteredBooking.booking_response.Status !== 20
                        }
                        onClick={() => handleOpenModal()}
                      >
                        Change Request
                      </Button> */}

                      {/* <Button
                        variant="theme"
                        size="sm"
                        // href="#"
                        // target="_blank"
                        data-toggle="modal"
                        data-target="#myonwardfareModal"
                        className="btn btn-theme btn-rounded btn-sm"
                        style={{
                          marginInline: "5px",
                          opacity:
                            filteredBooking.booking_response.Status !== 10 &&
                            filteredBooking.booking_response.Status !== 11 &&
                            filteredBooking.booking_response.Status !== 12 &&
                            filteredBooking.booking_response.Status !== 20
                              ? 0.1
                              : 1,
                        }}
                        onClick={() => handleCancelModalShow()}
                        disabled={
                          filteredBooking.booking_response.Status !== 10 &&
                          filteredBooking.booking_response.Status !== 11 &&
                          filteredBooking.booking_response.Status !== 12 &&
                          filteredBooking.booking_response.Status !== 20
                        }
                      >
                        Cancel Flight
                      </Button> */}

                      {/* <Button
                        variant="theme"
                        size="sm"
                        // href="#"
                        // target="_blank"
                        data-toggle="modal"
                        data-target="#myonwardfareModal"
                        className="btn btn-theme btn-rounded btn-sm"
                        style={{
                          marginInline: "5px",
                          opacity:
                            filteredBooking.booking_response.Status !== 10 &&
                            filteredBooking.booking_response.Status !== 11 &&
                            filteredBooking.booking_response.Status !== 12 &&
                            filteredBooking.booking_response.Status !== 20
                              ? 0.1
                              : 1,
                        }}
                        onClick={() => handleIssueModalShow()}
                        disabled={
                          filteredBooking.booking_response.Status !== 10 &&
                          filteredBooking.booking_response.Status !== 11 &&
                          filteredBooking.booking_response.Status !== 12 &&
                          filteredBooking.booking_response.Status !== 20
                        }
                      >
                        Issue Reservation
                      </Button> */}
                    </div>

                    {/* <div className="card card_toggle">
                      <div
                     
                      >
                        <Accordion style={{ marginBottom: 0 }}>
                          <Accordion.Item
                           
                            eventKey="0"
                           
                          >
                            <Accordion.Header
                           
                            >
                              <a className="toggleplus">
                                Amendment Logs
                             
                              </a>
                            </Accordion.Header>
                          </Accordion.Item>
                          <Accordion.Body eventKey="0">
                            <Card.Body>
                              <div className="timeline timeline-inverse followuphistory"></div>
                            </Card.Body>
                          </Accordion.Body>
                        </Accordion>
                      </div>
                    </div> */}

                    {filteredBooking.booking_response.Contracts[0] && (
                      <Card className="card-primary mb-3">
                        <Card.Header className="card-header">
                          <Card.Title as="h3" className="card-title">
                            {filteredBooking.booking_response.Contracts[0].AirSegments.map(
                              (segment) =>
                                `${segment.Origen}-${segment.Destination}`
                            ).join(", ")}
                            {/* {
                           filteredBooking.booking_response.[0]DepartureAirportLocationCode
                         }
                         -
                         {
                           filteredBooking.booking_response.TravelItinerary
                             .ItineraryInfo.ReservationItems[
                             filteredBooking.booking_response.TravelItinerary
                               .ItineraryInfo.ReservationItems.length - 1
                           ].ArrivalAirportLocationCode
                         }{" "} */}
                            &nbsp; Flight Details
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className="card-body">
                          <div className="table-responsive">
                            <Table
                              bordered
                              className="table table-bordered text-nowrap"
                            >
                              <thead>
                                <tr>
                                  <th>Flight</th>
                                  <th>Departure</th>
                                  <th>Arrival</th>
                                  <th>Other</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredBooking.booking_response
                                  .Contracts[0] &&
                                  filteredBooking.booking_response.Contracts[0].AirSegments.map(
                                    (segment, index) => (
                                      <tr>
                                        <td>
                                          <img
                                            src={`/Images/AirlineLogo/${segment.AirlineCode}.gif`}
                                            alt="Airline"
                                          />
                                          <br />
                                          <p>
                                            {segment.AirlineCode}-
                                            {segment.FlightNumber}
                                          </p>
                                        </td>
                                        <td>
                                          {segment.Origen}
                                          <br />
                                          {segment.DepartureTime === null ||
                                          segment.DepartureTime === ""
                                            ? segment.DepartureTime
                                            : convertToAmPm(
                                                segment.DepartureTime
                                              )}
                                          <br />
                                          {segment.DepartureDateTime}
                                          &nbsp;
                                          <br />
                                          Terminal{" "}
                                          {segment.OriginAirportTerminal}
                                          {/* , {segment.DepartureAirportLocationCode} */}
                                        </td>
                                        <td>
                                          {segment.Destination} <br />
                                          {segment.ArrivalTime === null ||
                                          segment.ArrivalTime === ""
                                            ? segment.ArrivalTime
                                            : convertToAmPm(
                                                segment.ArrivalTime
                                              )}
                                          <br />
                                          {segment.ArrivalDateTime}
                                          <br />
                                          Terminal{" "}
                                          {segment.DestinationAirportTerminal}
                                          {/* , {segment.ArrivalAirportLocationCode} */}
                                        </td>
                                        <td>
                                          <b className="text_color">
                                            Duration:{" "}
                                          </b>
                                          {segment.Duration}
                                          <br />
                                          <b className="text_color">Class: </b>
                                          {TypeOfCabinMap[segment.TypeOfClass]}
                                          <br />
                                          <b className="text_color">
                                            Baggage:{" "}
                                          </b>
                                          {segment.BaggageAllowed &&
                                            segment.BaggageAllowed.HandBaggage}
                                          <br />
                                          <b className="text_color">
                                            Cabin Baggage:{" "}
                                          </b>
                                          {segment.BaggageAllowed &&
                                            segment.BaggageAllowed
                                              .CheckInBaggage}
                                        </td>
                                        <td>Excellent</td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </Table>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    {filteredBooking.booking_response.Contracts[1] && (
                      <Card className="card-primary mb-3">
                        <Card.Header className="card-header">
                          <Card.Title as="h3" className="card-title">
                            {filteredBooking.booking_response.Contracts[1].AirSegments.map(
                              (segment) =>
                                `${segment.Origen}-${segment.Destination}`
                            ).join(", ")}
                            &nbsp; Flight Details
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className="card-body">
                          <div className="table-responsive">
                            <Table
                              bordered
                              className="table table-bordered text-nowrap"
                            >
                              <thead>
                                <tr>
                                  <th>Flight</th>
                                  <th>Departure</th>
                                  <th>Arrival</th>
                                  <th>Other</th>
                                  <th>Remarks</th>
                                </tr>
                              </thead>
                              <tbody>
                                {filteredBooking.booking_response
                                  .Contracts[1] &&
                                  filteredBooking.booking_response.Contracts[1].AirSegments.map(
                                    (segment, index) => (
                                      <tr>
                                        <td>
                                          <img
                                            src={`/Images/AirlineLogo/${segment.AirlineCode}.gif`}
                                            alt="Airline"
                                          />
                                          <br />
                                          <p>
                                            {segment.AirlineCode}-
                                            {segment.FlightNumber}
                                          </p>
                                        </td>
                                        <td>
                                          {segment.Origen}
                                          <br />
                                          {segment.DepartureTime === null ||
                                          segment.DepartureTime === ""
                                            ? segment.DepartureTime
                                            : convertToAmPm(
                                                segment.DepartureTime
                                              )}
                                          <br />
                                          {segment.DepartureDateTime}
                                          <br />
                                          Terminal{" "}
                                          {segment.OriginAirportTerminal}
                                          {/* , {segment.DepartureAirportLocationCode} */}
                                        </td>
                                        <td>
                                          {segment.Destination}
                                          <br />
                                          {segment.ArrivalTime === null ||
                                          segment.ArrivalTime === ""
                                            ? segment.ArrivalTime
                                            : convertToAmPm(
                                                segment.ArrivalTime
                                              )}
                                          <br />
                                          {segment.ArrivalDateTime}
                                          <br />
                                          Terminal{" "}
                                          {segment.DestinationAirportTerminal}
                                          {/* , {segment.ArrivalAirportLocationCode} */}
                                        </td>
                                        <td>
                                          <b className="text_color">
                                            Duration:{" "}
                                          </b>
                                          {segment.Duration}
                                          <br />
                                          <b className="text_color">Class: </b>
                                          {TypeOfCabinMap[segment.TypeOfClass]}
                                          <br />
                                          <b className="text_color">
                                            Baggage:{" "}
                                          </b>
                                          {segment.BaggageAllowed &&
                                            segment.BaggageAllowed.HandBaggage}
                                          <br />
                                          <b className="text_color">
                                            Cabin Baggage:{" "}
                                          </b>
                                          {segment.BaggageAllowed &&
                                            segment.BaggageAllowed
                                              .CheckInBaggage}
                                        </td>
                                        <td>Excellent</td>
                                      </tr>
                                    )
                                  )}
                              </tbody>
                            </Table>
                          </div>
                        </Card.Body>
                      </Card>
                    )}

                    <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Passenger Details
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <div className="table-responsive">
                          <Table
                            bordered
                            className="table table-bordered text-nowrap"
                          >
                            <thead>
                              <tr>
                                <th>Pax</th>
                                <th>Other</th>
                              </tr>
                            </thead>
                            {filteredBooking.booking_response.Flightpassenger &&
                              filteredBooking.booking_response.Flightpassenger.map(
                                (item) => (
                                  <tbody>
                                    <tr>
                                      <td>
                                        <b className="text_color">Title: </b>
                                        {item.Title}
                                        <br />
                                        <b className="text_color">F. Name: </b>
                                        {item.FirstName}
                                        <br />
                                        <b className="text_color">L. Name: </b>
                                        {item.LastName}
                                        <br />
                                        <b className="text_color">Gender: </b>
                                        {item.Gender ? "Female" : "Male"}
                                        <br />
                                        <b className="text_color">Type: </b>
                                        {paxtypeMapping[item.PaxType]}
                                      </td>
                                      <td>
                                        <b className="text_color">DOB: </b>
                                        {item.DateOfBirth}
                                        <br />
                                        <b className="text_color">PP No.: </b>
                                        {item.PassportNo}
                                        <br />
                                        <b className="text_color">PP Exp.: </b>
                                        {item.PassportExpiry}
                                      </td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                            <tfoot>
                              <tr>
                                <th colSpan="2">Contact</th>
                              </tr>
                              <tr>
                                <td colSpan="2">
                                  <b className="text_color">Mobile: </b>
                                  {walletData.Phone}
                                  <br />
                                  <b className="text_color">Email: </b>
                                  {walletData.Email}
                                </td>
                              </tr>
                            </tfoot>
                          </Table>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Ticket Details
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <div className="table-responsive">
                          <Table
                            bordered
                            className="table table-bordered text-nowrap"
                          >
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>Type</th>
                                <th>Gender</th>

                                <th>Ticket Number</th>
                                <th>Extra Baggage</th>
                                <th>Meal</th>
                              </tr>
                            </thead>
                            {filteredBooking.booking_response.Flightpassenger &&
                              filteredBooking.booking_response.Flightpassenger.map(
                                (item) => (
                                  <tbody>
                                    <tr>
                                      <td>
                                        {item.FirstName} {item.LastName}
                                      </td>
                                      <td>{paxtypeMapping[item.PaxType]}</td>
                                      <td>
                                        {" "}
                                        {item.Gender ? "Female" : "Male"}
                                      </td>
                                      <td>{item.TicketNumber}</td>
                                      <td>{item.BaggageCode}</td>
                                      <td>{item.SeatCode}</td>
                                    </tr>
                                  </tbody>
                                )
                              )}
                          </Table>
                        </div>
                      </Card.Body>
                    </Card>

                    <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Booked By
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <div className="table-responsive">
                          <Table
                            bordered
                            className="table table-bordered text-nowrap"
                          >
                            <thead>
                              <tr>
                                <th>Agent ID</th>
                                <th>Name</th>
                                <th>Mobile</th>
                                <th>Email</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>{walletData.Username}</td>
                                <td>{walletData.FirstName}</td>
                                <td>{walletData.Phone}</td>
                                <td>{walletData.Email}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </Card.Body>
                    </Card>

                    {/* <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Remark
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <p>Good</p>
                      </Card.Body>
                    </Card> */}

                    {/* <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Error Message
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <p>
                       
                        </p>
                      </Card.Body>
                    </Card> */}
                    {/* 
                    <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Onward Airline Remark
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <p></p>
                      </Card.Body>
                    </Card> */}
                  </Col>
                  <Col md={4} className="sidebar_detail">
                    <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Basic Details
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <Table bordered className="table table-bordered">
                          <tbody>
                            <tr>
                              <th>ID</th>
                              <td>{filteredBooking.id}</td>
                            </tr>
                            <tr>
                              <th>Booking Type</th>
                              <td>ONEWAY</td>
                            </tr>
                            <tr>
                              <th>PNR</th>
                              <td>{filteredBooking.pnr}</td>
                            </tr>
                            <tr>
                              <th>Booking Date</th>
                              <td>
                                {formatDateTime(filteredBooking.updated_at)}
                              </td>
                            </tr>
                            <tr>
                              <th>Update Date</th>
                              <td>
                                {formatDateTime(filteredBooking.updated_at)}
                              </td>
                            </tr>

                            <tr>
                              <th>Ticket Status</th>
                              <td>
                                {
                                  statusMapping[
                                    filteredBooking.booking_response
                                      .ResponseStatus
                                  ]
                                }
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Card.Body>
                    </Card>

                    <Card className="card-primary mb-3">
                      <Card.Header className="card-header">
                        <Card.Title as="h3" className="card-title">
                          Onward Fare Details
                        </Card.Title>
                      </Card.Header>
                      <Card.Body className="card-body">
                        <div className="fare_details">
                          {filteredBooking && (
                            <ul className="list-unstyled mb-0">
                              <li>
                                Basefare{" "}
                                <span>
                                  ₹
                                  {!filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.BaseFare}
                                  {filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.BaseFare +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.BaseFare}
                                </span>
                              </li>
                              <li>
                                TaxFare{" "}
                                <span>
                                  ₹{" "}
                                  {!filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.TaxFare}
                                  {filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.TaxFare +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.TaxFare}
                                </span>
                              </li>
                              <li>
                                Meal Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Baggage Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Seat Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Service Tax{" "}
                                <span>
                                  ₹{" "}
                                  {!filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.ServiceCharge}
                                  {filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.ServiceCharge +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.ServiceCharge}
                                </span>
                              </li>
                              <li>
                                NetFare{" "}
                                <span>
                                  ₹{" "}
                                  {!filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.NetFare}
                                  {filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.NetFare +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.NetFare}
                                </span>
                              </li>
                              <li>
                                Gross Fare{" "}
                                <span>
                                  {" "}
                                  ₹{" "}
                                  {!filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.BaseFare}
                                  {filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.BaseFare +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.BaseFare}
                                </span>
                              </li>
                              <li>
                                Admin Fare{" "}
                                <span>
                                  ₹{" "}
                                  {!filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.GrossFare +
                                      filteredBooking.booking_response
                                        .Contracts[0].AirlineFare.ServiceCharge}
                                  {filteredBooking.booking_response
                                    .Contracts[1] &&
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.GrossFare +
                                      filteredBooking.booking_response
                                        .Contracts[0].AirlineFare
                                        .ServiceCharge +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.GrossFare +
                                      filteredBooking.booking_response
                                        .Contracts[1].AirlineFare.ServiceCharge}
                                </span>
                              </li>
                            </ul>
                          )}
                          {/* {filteredBooking.booking_response.Contracts[1] && (
                            <ul className="list-unstyled mb-0">
                              <li>
                                Basefare{" "}
                                <span>
                                  {" "}
                                  ₹{" "}
                                  {filteredBooking.booking_response.Contracts[0]
                                    .AirlineFare.BaseFare +
                                    filteredBooking.booking_response
                                      .Contracts[1].AirlineFare.BaseFare}
                                </span>
                              </li>
                              <li>
                                TaxFare{" "}
                                <span>
                                  ₹{" "}
                                  {filteredBooking.booking_response.Contracts[0]
                                    .AirlineFare.TaxFare +
                                    filteredBooking.booking_response
                                      .Contracts[1].AirlineFare.TaxFare +
                                    filteredBooking.booking_response
                                      .Contracts[1].AirlineFare.ServiceCharge +
                                    filteredBooking.booking_response
                                      .Contracts[0].AirlineFare.ServiceCharge}
                                </span>
                              </li>
                              <li>
                                Meal Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Baggage Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Seat Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Customer Fare{" "}
                                <span>
                                  ₹{" "}
                                  {filteredBooking.booking_response.Contracts[0]
                                    .AirlineFare.GrossFare +
                                    filteredBooking.booking_response
                                      .Contracts[1].AirlineFare.GrossFare}
                                </span>
                              </li>
                              <li>
                                Agent Fare{" "}
                                <span>
                                  ₹{" "}
                                  {filteredBooking.booking_response.Contracts[0]
                                    .AirlineFare.GrossFare +
                                    filteredBooking.booking_response
                                      .Contracts[1].AirlineFare.GrossFare}
                                </span>
                              </li>
                              <li>
                                Agent Commission <span>₹ 0</span>
                              </li>
                              <li>
                                Total Fare{" "}
                                <span>
                                  ₹{" "}
                                  {filteredBooking.booking_response.Contracts[0]
                                    .AirlineFare.GrossFare +
                                    filteredBooking.booking_response
                                      .Contracts[1].AirlineFare.GrossFare}
                                </span>
                              </li>
                            </ul>
                          )} */}
                        </div>
                      </Card.Body>
                    </Card>

                    {/* <Card className="card-primary mb-3">
                        <Card.Header className="card-header">
                          <Card.Title as="h3" className="card-title">
                            Fare Details
                          </Card.Title>
                        </Card.Header>
                        <Card.Body className="card-body">
                          <div className="fare_details">
                            <ul className="list-unstyled mb-0">
                              <li>
                                Basefare <span>₹ 2219</span>
                              </li>
                              <li>
                                Tax <span>₹ 1157</span>
                              </li>
                              <li>
                                Meal Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Baggage Charges <span>₹ 0</span>
                              </li>
                              <li>
                                Seat Charges <span>₹ 400</span>
                              </li>
                              <li>
                                Customer Fare <span>₹ 3776</span>
                              </li>
                              <li>
                                Agent Fare <span>₹ 3735</span>
                              </li>
                              <li>
                                Agent Commission <span>₹ 41</span>
                              </li>
                              <li>
                                Admin Fare <span>₹ 3725</span>
                              </li>
                            </ul>
                          </div>
                        </Card.Body>
                      </Card> */}
                  </Col>
                </Row>

                <Modal
                  show={showCancelModal}
                  onHide={handleCancelModalClose}
                  // className="fare-rules-modal show"
                  id="cancel"
                  style={{ paddingRight: "12px", display: "block" }}
                  // size="lg"

                  centered={false}
                >
                  <Modal.Dialog className="modal-lg cancelbookingmodal">
                    <div className="modal-content">
                      <Modal.Header>
                        <Modal.Title>Cancel Flight</Modal.Title>
                        <Button
                          variant="close"
                          onClick={handleCancelModalClose}
                          data-dismiss="modal"
                        />
                        {/* </Button> */}
                      </Modal.Header>

                      <Modal.Body>
                        <p>Are you sure you want to cancel this flight?</p>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button
                          className=""
                          variant="info"
                          onClick={() =>
                            cancelFlightParto(
                              filteredBooking.booking_response.UniqueId
                            )
                          }
                        >
                          Cancel Booking
                        </Button>
                        <Button
                          variant="danger"
                          onClick={handleCancelModalClose}
                          data-dismiss="modal"
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </div>
                  </Modal.Dialog>
                </Modal>

                <Modal
                  show={showIssueModal}
                  onHide={handleIssueModalClose}
                  // className="fare-rules-modal show"
                  id="cancel"
                  style={{ paddingRight: "12px", display: "block" }}
                  // size="lg"

                  centered={false}
                >
                  <Modal.Dialog className="modal-lg cancelbookingmodal">
                    <div className="modal-content">
                      <Modal.Header>
                        <Modal.Title>Issue Reserved Flight</Modal.Title>
                        <Button
                          variant="close"
                          onClick={handleIssueModalClose}
                          data-dismiss="modal"
                        />
                        {/* </Button> */}
                      </Modal.Header>

                      <Modal.Body>
                        <p>Are you sure you want to Issue this Reservation?</p>
                      </Modal.Body>

                      <Modal.Footer>
                        <Button
                          className=""
                          variant="info"
                          onClick={() =>
                            IssueFlightParto(
                              filteredBooking.booking_response.UniqueId
                            )
                          }
                        >
                          Issue Reservation
                        </Button>
                        <Button
                          variant="danger"
                          onClick={handleIssueModalClose}
                          data-dismiss="modal"
                        >
                          Close
                        </Button>
                      </Modal.Footer>
                    </div>
                  </Modal.Dialog>
                </Modal>

                <Modal
                  show={showRuleModal}
                  onHide={handleRuleModalClose}
                  backdrop="static"
                  size="lg"
                >
                  <Modal.Header closeButton>
                    <Modal.Title>Fare Rule</Modal.Title>
                  </Modal.Header>

                  <Modal.Body>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: filteredBooking.farerule,
                      }}
                    ></div>
                  </Modal.Body>
                  {/* <Modal.Footer>
                <Link
                  // to="#"
                  className="btn btn-danger"
                  onClick={handleRuleModalClose}
                >
                  <span
                    className="fa fa-times-circle"
                    aria-hidden="true"
                  ></span>{" "}
                  Cancel
                </Link>
              </Modal.Footer> */}
                </Modal>

                <FlightBookingChange
                  show={showModal}
                  handleClose={handleCloseModal}
                  handleSubmit={handleSubmit}
                  formData={formData}
                  handleChange={handleChange}
                />
              </Container>
            </section>
          </>
        )}
      </Container>
    </div>
  );
};

export default FlightBookingDetail;
