import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Table,
  Breadcrumb,
  Dropdown,
  Form,
} from "react-bootstrap";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const CardCustomMenu = () => {
  return (
    <div className="full-container">
      <div>
        <ul className="custom-menu-container">
          <li className="active">
            <Link to="/agent/mark-up">Flight</Link>
          </li>
        </ul>
      </div>
      <div>
        <Button
          variant="theme"
          size="md"
          className="filter_btn"
          as={Link}
          to="/agent/add-markup"
        >
          <FaPlus /> New Markup
        </Button>
      </div>
    </div>
  );
};

const MarkupTable = () => {
  const [selectedCheckboxes, setSelectedCheckboxes] = useState({});
  const [data, setData] = useState([]);
  const [amount, setAmount] = useState("");
  const [serviceType, setServiceType] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://admin.yatriservice.com/api/agent-markup/all"
        );
        if (response) {
          setData(response.data.data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleServiceTypeChange = (e) => {
    setServiceType(e.target.value);
  };

  const toggleCheckbox = (index) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const toggleSelectAll = () => {
    const allSelected = Object.values(selectedCheckboxes).every((value) => value);
    const newCheckboxState = {};
    data.forEach((_, index) => {
      newCheckboxState[index] = !allSelected;
    });
    setSelectedCheckboxes(newCheckboxState);
  };

  const handleEdit = (id) => {
    navigate(`/agent/edit-markup/${id}`);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(
        `https://admin.yatriservice.com/api/agent-markup/delete/${id}`
      );
      alert("Markup deleted successfully");
      setData(data.filter((item) => item.id !== id));
    } catch (error) {
      console.error("Error deleting markup:", error);
      alert("Failed to delete markup");
    }
  };

  const handleUpdateSelected = async (e) => {
    e.preventDefault();

    const selectedIds = Object.keys(selectedCheckboxes)
      .filter((index) => selectedCheckboxes[index])
      .map((index) => data[index].id);
      
    try {
      await Promise.all(
        selectedIds.map((id) =>
          axios.post(
            `https://admin.yatriservice.com/api/agent-markup/update/${id}`,
            { service_fee: amount } // Update only the amount
          )
        )
      );
      alert("Selected markups updated successfully");
      // Refresh data after update
      const response = await axios.get(
        "https://admin.yatriservice.com/api/agent-markup/all"
      );
      setData(response.data.data);
    } catch (error) {
      console.error("Error updating selected markups:", error);
      alert("Failed to update selected markups");
    }
  };

  return (
    <div className="content-wrapper">
      <Container fluid>
        <div className="content-header">
          <Container fluid>
            <Row>
              <Col sm={6} md={6} className="sm-center">
                <h1 className="m-0 text-dark">Manage Markup</h1>
              </Col>
              <Col sm={6} md={6} className="sm-center">
                <Breadcrumb className="float-sm-right">
                  <Breadcrumb.Item linkAs={Link} linkProps={{ to: "/" }}>
                    <span style={{ color: "#fff" }}>Home</span>
                  </Breadcrumb.Item>
                  <Breadcrumb.Item active>Manage Markup</Breadcrumb.Item>
                </Breadcrumb>
              </Col>
            </Row>
          </Container>
        </div>
        <section className="content">
          <Container fluid>
            <Row>
              <Col md={12}>
                <Card>
                  <Card.Header>
                    <div className="card_custom_menu">
                      <CardCustomMenu />
                    </div>
                  </Card.Header>
                  <Card.Body>
                    {/* <Col md={12}>
                      <div className="commission_amount filter_panel">
                        <Form onSubmit={handleUpdateSelected}>
                          <Row>
                            <Col xs={12} sm={6} md={4}>
                              <Form.Group>
                                <Form.Control
                                  className="mb-md-0 mb-1"
                                  type="text"
                                  placeholder="Amount (Rs.)"
                                  value={amount}
                                  onChange={handleAmountChange}
                                />
                              </Form.Group>
                            </Col>
                            <Col
                              xs={12}
                              sm={12}
                              md={4}
                              className="d-flex justify-content-md-start justify-content-center"
                            >
                              <Button
                                type="submit"
                                variant="theme"
                                className="update_btn"
                              >
                                Update Selected
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </div>
                    </Col> */}
                    <Col md={12}>
                      <div className="table-responsive">
                        <Table bordered hover className="no-footer">
                          <thead>
                            <tr>
                              <th className="no-sort">
                                {/* <input
                                  type="checkbox"
                                  id="domrcheckedAll"
                                  onChange={toggleSelectAll}
                                  checked={
                                    Object.values(selectedCheckboxes).every(
                                      (value) => value
                                    ) && data.length > 0
                                  }
                                />{" "} */}
                                S No.
                                {/* Select All */}
                              </th>
                              <th>Flight Type</th>
                              <th>Amount</th>
                              <th>Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody className="tdata booking_data">
                            {data.map((booking, index) => (
                              <tr
                                key={index}
                                role="row"
                                id={`id_${booking.id}`}
                                className={index % 2 === 0 ? "odd" : "even"}
                              >
                                <td>
                                  {/* <input
                                    className="checkSingle domesticids"
                                    type="checkbox"
                                    name="allcheckbox[]"
                                    value={booking.id}
                                    onChange={() => toggleCheckbox(index)}
                                    checked={selectedCheckboxes[index] || false}
                                  /> */}
                                  {index + 1}
                                </td>
                                <td>{booking.flight_type}</td>
                                <td>{`₹ ${booking.service_fee}`}</td>
                                <td>{booking.created_at}</td>
                                <td>
                                  <div className="nav-item dropdown action_dropdown cus_action_btn">
                                    <Dropdown>
                                      <Dropdown.Toggle
                                        variant="primary"
                                        size="sm"
                                        className="action_btn btn btn-primary btn-rounded btn-xs"
                                        id={`dropdown_${booking.id}`}
                                      >
                                        Action <span className="caret"></span>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu
                                        x-placement="bottom-start"
                                        style={{
                                          position: "absolute",
                                          willChange: "transform",
                                          top: "0px",
                                          left: "0px",
                                          transform:
                                            "translate3d(0px, 24px, 0px)",
                                        }}
                                      >
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleEdit(booking.id)
                                          }
                                        >
                                          <FaEdit className="action-icon" />{" "}
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          onClick={() =>
                                            handleDelete(booking.id)
                                          }
                                        >
                                          <FaTrash className="action-icon" />{" "}
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                  </div>
                                </td>
                              </tr>
                            ))}
                            {data.length === 0 && (
                              <tr>
                                <td className="text-center" colSpan="6">
                                  No Record Found
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </Container>
    </div>
  );
};

export default MarkupTable;
