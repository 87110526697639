import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { GiSchoolBag } from "react-icons/gi";
import {
  FaBars,
  FaUserCircle,
  FaSuitcaseRolling,
  FaStar,
  FaBed,
  FaPowerOff,
  FaUser,
  FaEnvelope,
  FaAngleDown,
  FaBinoculars,
  FaRegCircle,
  FaUmbrellaBeach,
  FaCreditCard,
  FaFileAlt,
  FaCog,
  FaSignOutAlt,
  FaWallet,
} from "react-icons/fa";
import { FaChartBar } from "react-icons/fa6";
import { ImUserPlus } from "react-icons/im";
import { MdHeadset, MdDirectionsCar, MdWhatsapp } from "react-icons/md";
import { IoMdGlobe } from "react-icons/io";
import { MdFlight } from "react-icons/md";

import "./DashboardNavbar.css";
import { useSelector } from "react-redux";
import Yatri from "../../Components/Home/Navbar/yatri-service.png";
import { RiCustomerService2Fill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
// import Sidebar from "./Sidebar";

function DashboardNavbar() {
  const navigate=useNavigate()
  const [showSidebar, setShowSidebar] = useState(false);
  const [mainHeaderHeight, setMainHeaderHeight] = useState(0);
  const mainHeaderRef = useRef(null);

  useEffect(() => {
    const handleResize = () => {
      if (mainHeaderRef.current) {
        setMainHeaderHeight(mainHeaderRef.current.offsetHeight);
      }
    };

    // Initial calculation
    handleResize();

    // Event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const closeSidebar = (e) => {
      if (
        showSidebar &&
        !e.target.closest(".main-sidebar") &&
        !e.target.closest(".nav_toggle")
      ) {
        setShowSidebar(false);
      }
    };

    document.body.addEventListener("click", closeSidebar);

    return () => {
      document.body.removeEventListener("click", closeSidebar);
    };
  }, [showSidebar]);

  const toggleSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  const [showHotelSubMenu, setShowHotelSubMenu] = useState(false);
  const [showSightseeingSubMenu, setShowSightseeingSubMenu] = useState(false);
  const [showTourPackagesSubMenu, setShowTourPackagesSubMenu] = useState(false);
  const [showTransfersSubMenu, setShowTransfersSubMenu] = useState(false);
  const [showWalletSubMenu, setShowWalletSubMenu] = useState(false);
  const [showBookingReportsSubMenu, setShowBookingReportsSubMenu] =
    useState(false);
  const [showSettingsSubMenu, setShowSettingsSubMenu] = useState(false);
  const [showFlightSubMenu, setShowFlightSubMenu] = useState(false);

  const toggleHotelSubMenu = () => {
    setShowHotelSubMenu(!showHotelSubMenu);
  };
  const toggleSightseeingSubMenu = () => {
    setShowSightseeingSubMenu(!showSightseeingSubMenu);
  };
  const toggleTourPackagesSubMenu = () => {
    setShowTourPackagesSubMenu(!showTourPackagesSubMenu);
  };
  const toggleTransfersSubMenu = () => {
    setShowTransfersSubMenu(!showTransfersSubMenu);
  };
  const toggleWalletSubMenu = () => {
    setShowWalletSubMenu(!showWalletSubMenu);
  };
  const toggleBookingReportsSubMenu = () => {
    setShowBookingReportsSubMenu(!showBookingReportsSubMenu);
  };
  const toggleSettingsSubMenu = () => {
    setShowSettingsSubMenu(!showSettingsSubMenu);
  };
  const toggleFlightSubMenu = () => {
    setShowFlightSubMenu(!showFlightSubMenu);
  };
  const [walletDataas, setwalletDataas] = useState(null);
  useEffect(() => {
    const data = localStorage.getItem("walletBalance");
    if (data) {
      setwalletDataas(data);
    }
  }, [walletDataas]);
  const { walletData} = useSelector((state) => state.auth);
  // console.log("waletDataas",walletDataas)
  const handleLogout = () => {
    localStorage.clear();
    sessionStorage.clear();
    navigate('/')
    window.location.reload();
  };
  return (
    <>
      <Navbar expand="md" className="main-header" ref={mainHeaderRef}>
        <Nav className="navbar-nav nav_toggle">
          <Nav.Link onClick={toggleSidebar}>
            <FaBars className="icon menu-icon" />
          </Nav.Link>
        </Nav>
        <div
          className="agent_mob_logo"
          style={{ display: "flex", alignItems: "center", gap: "20px" }}
        >
          <a href="/agent/dashboard" style={{ marginInline: "auto" }}>
            <img src={Yatri} alt="Logo" className="brand-image" />
          </a>
        </div>

        <Nav>
          <div style={{ display: "flex" }}>
            <NavDropdown
              title={
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <RiCustomerService2Fill className="icon" />
                  <span className="d-none d-lg-block">Support</span>{" "}
                  <span className="caret" />
                </div>
              }
              id="hotel-dropdown"
              className="quick_link agentnav_item support_link"
            >
              <NavDropdown.Item>
                <div className="cus_icon">
                  <FaUserCircle className="icon" />
                </div>
                <div className="cus_txt">
                  <span className="title">Account Manager</span>
                  <span className="sub_txt">
                    +91-9721647550 <br /> Support@yatriservice.com
                  </span>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <div className="cus_icon">
                  <IoMdGlobe className="icon" />
                </div>
                <div className="cus_txt">
                  <span className="title">Customer Support</span>
                  <span className="sub_txt">
                    Support@yatriservice.com <br /> +91-9721647550
                  </span>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <div className="cus_icon whatsapp_icon">
                  <MdWhatsapp className="icon" />
                </div>
                <div className="cus_txt">
                  <span className="title" style={{ marginTop: "5px" }}>
                    +91-9721647550
                  </span>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <div className="cus_icon">
                  <FaEnvelope className="icon" />
                </div>
                <div className="cus_txt">
                  <span className="title">For Booking</span>
                  <span className="sub_txt">
                    <a
                      className="mail_link"
                      href="mailto:Support@yatriservice.com"
                    >
                      Support@yatriservice.com
                    </a>
                  </span>
                </div>
              </NavDropdown.Item>
              <NavDropdown.Item>
                <div className="cus_icon">
                  <FaEnvelope className="icon" />
                </div>
                <div className="cus_txt">
                  <span className="title">For Refund</span>
                  <span className="sub_txt">
                    <a
                      className="mail_link"
                      href="mailto: Support@yatriservice.com"
                    >
                      Support@yatriservice.com
                    </a>
                  </span>
                </div>
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown
              title={
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <FaWallet className="icon" />
                  {walletDataas && <span> ₹{walletDataas}</span>}{" "}
                  <span className="caret" />
                </div>
              }
              id="hotel-dropdown"
              className="nav-item agentnav_item quick_link d-none d-lg-flex"
            >
              <NavDropdown.Item href=" /agent/transaction_log">
                Balance <span> ₹ {walletDataas && walletDataas}</span>
              </NavDropdown.Item>
              <NavDropdown.Item href=" /agent/credit_limit_log">
                Credit <span> ₹ {walletData.CreditLimit}</span>
              </NavDropdown.Item>
              <NavDropdown.Item href=" /agent/wallet?type=instant_recharge">
                Top-up Online
              </NavDropdown.Item>
              <NavDropdown.Item href=" /agent/wallet">
                Top-up request
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title={
                <div
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <FaUser className="icon" /> My Account{" "}
                  <span className="caret" />
                </div>
              }
              id="profile-dropdown"
              className="nav-item agentnav_item quick_link prf_link d-none d-lg-flex"
            >
              <NavDropdown.Item href=" /agent/dashboard">
                <FaUser className="iconn" />
                {walletData.Email}
              </NavDropdown.Item>
              <NavDropdown.Item href=" /agent/mark-up">
                <ImUserPlus className="iconn" />Flight MarkUp
              </NavDropdown.Item>
              <NavDropdown.Item href=" /agent/bookings/flight">
                <FaFileAlt className="iconn" /> Booking Reports
              </NavDropdown.Item>
              {/* <NavDropdown.Item href=" /agent/bookings/transaction-log">
                <FaFileAlt className="iconn" /> Transaction Log
              </NavDropdown.Item> */}
              <NavDropdown.Item href="/agent/bookings/ledger-report">
                <FaFileAlt className="iconn" /> Ledger Report
              </NavDropdown.Item>
              <NavDropdown.Item href=" /agent/wallet">
                <FaCreditCard className="iconn" /> Wallet &amp; Deposits
              </NavDropdown.Item>

              <NavDropdown.Item href=" /agent/profile">
                <FaCog className="iconn" /> Settings
              </NavDropdown.Item>
              <NavDropdown.Item onClick={handleLogout}>
                <FaPowerOff className="iconn" /> Logout
              </NavDropdown.Item>
              <form
                method="POST"
                action=" /agent/logout"
                acceptCharset="UTF-8"
                name="admin_login"
                id="logout-form"
              >
                <input
                  name="_token"
                  type="hidden"
                  defaultValue="Y4xDCP1NYMqxl0e6iPGBGfTCpkYOPV74jESoljqp"
                />
              </form>
            </NavDropdown>
          </div>
        </Nav>
      </Navbar>

      {/* <Sidebar/>
       */}

      <div
        className={`main-sidebar ${showSidebar ? "active" : ""}`}
        style={{ top: `${mainHeaderHeight}px` }}
      >
        <Navbar expand="lg" className="sidebar">
          <Container fluid>
            <Nav className="flex-column">
              {/* Dashboard */}
              <Nav.Link href="/agent/dashboard">
                <FaChartBar className="icon sideicon" /> Dashboard
              </Nav.Link>

              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu "
                onClick={toggleHotelSubMenu}
              >
                <FaBed className="icon sideicon" /> Hotel{" "}
                <FaAngleDown
                  className="submenu-icon"
                  style={{ marginRight: "60px" }}
                />
              </Nav.Link>
              {/* {showHotelSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link href="/agent/hotels">
                    <FaRegCircle className="icon" /> Hotel Search
                  </Nav.Link>
                  <Nav.Link href="/agent/bookings/hotel">
                    <FaRegCircle className="icon" /> Hotel Booking List
                  </Nav.Link>
                  <Nav.Link href="/agent/bookings/hotel/cancel/request/list">
                    <FaRegCircle className="icon" /> Cancellation List
                  </Nav.Link>
                  <Nav.Link href="/agent/hotelmarkup">
                    <FaRegCircle className="icon" /> Hotel Markup
                  </Nav.Link>
                </Nav>
              )} */}

              {/* <Nav.Link
           
                className="nav-link-with-submenu "
                onClick={toggleSightseeingSubMenu}
              >
                <MdFlight className="icon sideicon" /> Flight{" "}
                <FaAngleDown
                  className="submenu-icon"
                  style={{ marginRight: "60px" }}
                />
              </Nav.Link> */}
              {/* {showSightseeingSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link href="/agent/hotels">
                    <FaRegCircle className="icon" /> Flight Search
                  </Nav.Link>
                  <Nav.Link href="/agent/bookings/hotel">
                    <FaRegCircle className="icon" /> Flight Booking List
                  </Nav.Link>
                  <Nav.Link href="/agent/bookings/hotel">
                    <FaRegCircle className="icon" /> Group Booking List
                  </Nav.Link>
                  <Nav.Link href="/agent/bookings/hotel/cancel/request/list">
                    <FaRegCircle className="icon" /> Cancellation List
                  </Nav.Link>
                  <Nav.Link href="/agent/hotelmarkup">
                    <FaRegCircle className="icon" /> Flight Markup
                  </Nav.Link>
                </Nav>
              )} */}

              {/* <Nav.Link
              
                className="nav-link-with-submenu "
                onClick={toggleFlightSubMenu}
              >
                <GiSchoolBag className="icon sideicon" /> Tour Package{" "}
                <FaAngleDown
                  className="submenu-icon"
                  style={{ marginRight: "50px" }}
                />
              </Nav.Link>
              {showFlightSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link href="/agent/tour">
                    <FaRegCircle className="icon" /> Tour Package
                  </Nav.Link>
                  <Nav.Link href="/agent/tour/detailpage">
                    <FaRegCircle className="icon" /> Tour Package List
                  </Nav.Link>
                </Nav>
              )} */}

              <Nav.Link
           
                className="nav-link-with-submenu "
                onClick={toggleTourPackagesSubMenu}
              >
                <MdHeadset className="icon sideicon" /> Support{" "}
                <FaAngleDown
                  className="submenu-icon"
                  style={{ marginRight: "60px" }}
                />
              </Nav.Link>
              {showTourPackagesSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link >
                    <FaRegCircle className="icon" />
                   
                    <span className="title">
                      Account Manager
                      <br />
                    </span>
                    <span className="sub_txt">
                      +93779897682 <br /> support@Yatri Service.com
                    </span>
                    {/* </div> */}
                  </Nav.Link>
                  <Nav.Link >
                    <FaRegCircle className="icon" />{" "}
                    {/* <div className="cus_txt"> */}
                    <span className="title">
                      Customer Support
                      <br />
                    </span>
                    <span className="sub_txt">
                      support@Yatri Service.com <br /> +93779897682
                    </span>
                    {/* </div> */}
                  </Nav.Link>
                  <Nav.Link >
                    <FaRegCircle className="icon" />{" "}
                    {/* <div className="cus_txt"> */}
                    <span className="title">+93779897682</span>
                    {/* </div> */}
                  </Nav.Link>
                  <Nav.Link>
                    <FaRegCircle className="icon" />{" "}
                    {/* <div className="cus_txt"> */}
                    <span className="title">
                      For Booking
                      <br />
                    </span>
                    <span className="sub_txt">
                      <a
                        className="mail_link"
                        href="mailto:support@Yatri Service.com"
                      >
                        support@Yatri Service.com
                      </a>
                    </span>
                    {/* </div> */}
                  </Nav.Link>
                </Nav>
              )}

              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu "
                onClick={toggleSettingsSubMenu}
              >
                <FaWallet className="icon sideicon" />  {walletDataas && <span> ₹{walletDataas}</span>}
                <FaAngleDown
                  className="submenu-icon"
                  style={{ marginRight: "60px" }}
                />
              </Nav.Link>
              {showSettingsSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link href="/agent/transaction_log">
                    <FaRegCircle className="icon" /> Balance <span>{walletDataas && walletDataas}</span>
                  </Nav.Link>
                  <Nav.Link href="/agent/credit_limit_log">
                    <FaRegCircle className="icon" /> Credit <span>$ {walletData.CreditLimit}</span>
                  </Nav.Link>
                  <Nav.Link href="/agent/wallet?type=instant_recharge">
                    <FaRegCircle className="icon" /> Top-up Online
                  </Nav.Link>
                  <Nav.Link href="/agent/wallet">
                    <FaRegCircle className="icon" /> Top-up Request
                  </Nav.Link>
                </Nav>
              )}

              <Nav.Link
                // href="javascript:;"
                className="nav-link-with-submenu "
                onClick={toggleWalletSubMenu}
              >
                <FaUser className="icon sideicon" /> My Account{" "}
                <FaAngleDown
                  className="submenu-icon"
                  style={{ marginRight: "60px" }}
                />
              </Nav.Link>
              {showWalletSubMenu && (
                <Nav className="flex-column submenu">
                  <Nav.Link href="/agent/dashboard">
                    <FaRegCircle className="icon" /> {walletDataas.Email}
                  </Nav.Link>
                  <Nav.Link href="/agent/mark-up">
                    <FaRegCircle className="icon" /> Flight MarkUp
                  </Nav.Link>
                  <Nav.Link href=" /agent/bookings/flight">
                    <FaRegCircle className="icon" /> Booking Reports
                  </Nav.Link>
                  {/* <Nav.Link href=" /agent/bookings/transaction-log">
                    <FaRegCircle className="icon" /> Transaction Log
                  </Nav.Link> */}
                  <Nav.Link href="/agent/bookings/ledger-report">
                    <FaRegCircle className="icon" /> Ledger Report
                  </Nav.Link>
                  <Nav.Link href=" /agent/wallet">
                    <FaRegCircle className="icon" /> Wallet &amp; Deposits
                  </Nav.Link>
                  <Nav.Link href=" /agent/profile">
                    <FaRegCircle className="icon" />Settings
                  </Nav.Link>
                  <Nav.Link onClick={handleLogout}>
                    <FaRegCircle className="icon" /> Logout
                  </Nav.Link>
                </Nav>
              )}
            </Nav>
            {/* </Navbar.Collapse> */}
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default DashboardNavbar;
