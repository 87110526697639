import { apiConnector } from "../apiconnector";
import {
  setToken,
  setLoading,
  setWalletData,
  setCity,
} from "../../slices/auth";

const URL = "https://admin.yatriservice.com/api/flightauth1";
const URL1 = "https://admin.yatriservice.com/api/wallet-balance";
const URL2 = "https://admin.yatriservice.com/api/airport";

export function login(Id, Pw, APIKey, navigate) {
  return async (dispatch) => {
    // dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", URL, { Id, Pw, APIKey });

      console.log("LOGIN API RESPONSE............", response);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }
      navigate("/agent/dashboard");
      dispatch(setToken(response.data.data.ApiToken));

      localStorage.setItem("token", response.data.data.ApiToken);
    } catch (error) {
      // navigate("/");
      console.log("LOGIN API ERROR............", error);
    }
    // dispatch(setLoading(false));
  };
}

export function Wallentbalance(Id, Pw) {
  return async (dispatch) => {
    dispatch(setLoading(true));
    try {
      const response = await apiConnector("POST", URL1, { Id, Pw });

      console.log("WALLET API RESPONSE............", response.data.data);

      if (!response.data.success) {
        throw new Error(response.data.message);
      }

      dispatch(setWalletData(response.data.data));

      localStorage.setItem("walletData", JSON.stringify(response.data.data));
      localStorage.setItem("walletBalance", response.data.data.Wallet);
      // navigate("/agent/dashboard");
    } catch (error) {
      console.log("WALLET API ERROR............", error);
    }
    dispatch(setLoading(false));
  };
}
export const getCityData = async () => {
  let result = [];
  try {
    const response = await apiConnector("GET", URL2);
    if (!response?.data?.success) {
      throw new Error("Could Not Fetch city data");
    }
    result = response?.data?.data;
  } catch (error) {
    console.log("CITY API ERROR............", error);
  }
  return result;
};
